import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/styles';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect } from 'react';

import { AddTagToIngredientFormContext } from '../../../app/pages/hairFeature/AddHairFeatureToIngredientsPage';
import { getIngredientsByHairFeature } from '../../../setup/api/ingredient';
import { removeTagByIngredientId, saveIngredientTag, SaveIngredientTagPayload } from '../../../setup/api/ingredientTag';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { ProductIngredient } from '../../../setup/models';
import { comparColors } from '../../helpers/colors';
import { uploading } from '../../helpers/toasts';
import { ComparLightChip } from '../TagChips/ComparLightChip';

type AddedIngredientsTableProps = {
    queries: SaveIngredientTagPayload | undefined;
    setCurrentIngredients: Dispatch<SetStateAction<ProductIngredient[] | undefined>>
    currentIngredients: ProductIngredient[] | undefined;
    shouldRefresh: boolean
}

export const TagTable: FC<AddedIngredientsTableProps> = ({ queries, setCurrentIngredients, currentIngredients, shouldRefresh }) => {
    const { request, isLoading } = useFetch();

    const { request: requestWithAlert } = useFetchWithAlert(alertPayload(`Ingredient tag removed`));

    useEffect(() => {
        if(queries) {
            const { feature, value, tagName } = queries;
            const constructedQuery = `?hairFeatureName=` + feature + `&hairFeatureType=` + value + `&tagName=` + tagName;

            request(getIngredientsByHairFeature, constructedQuery).then((results: [ProductIngredient[], number]) => {
                if(results) {
                    const [ingredients] = results;

                    setCurrentIngredients(ingredients);
                }
            });
        }
    }, [queries, shouldRefresh]);

    const handleDelete = (ingredientId: string) => {
        requestWithAlert(removeTagByIngredientId, ingredientId, queries).then(() => {
            setCurrentIngredients(currentIngredients?.filter(item => item.id !== ingredientId));
        });
    };

    return <>
        {currentIngredients && <TableGrid ingredients={currentIngredients} onDelete={handleDelete}/>}
    </>;
};

type Props = {
    data: Array<any>
    setObjectSelected?: Dispatch<SetStateAction<any[]>>
    objectsSelected?: any[]
    resetIngredients: () => void;
}

export const SelectedTagTable: FC<Props> = ({
    data,
    setObjectSelected,
    objectsSelected,
    resetIngredients
}) => {
    const { request, isLoading } = useFetch();

    const context = useContext(AddTagToIngredientFormContext);
    const handleObjectSelected = useCallback(
        (id: any) => {
            if (setObjectSelected && objectsSelected) {
                const filteredList = objectsSelected.filter(selectedItem => selectedItem.id !== id);

                console.log(filteredList, `here`);
                setObjectSelected(filteredList);
            }
        },
        [objectsSelected, setObjectSelected]
    );

    const addTagToIngredient = (values: Partial<SaveIngredientTagPayload>) => {
        const ingredientsWithValues = data.map((ingredient: {id: string}) => {
            const payload = {
                ingredientId: ingredient.id,
                ...values
            };

            return request(saveIngredientTag, payload).then(() => resetIngredients());
        });

        const alert = alertPayload(`Adding Hair Features to ${data.length} ingredients`);

        Promise.all(ingredientsWithValues).then(() => {
            uploading(alert.message, alert.options);
        })
            .catch(() => {
                uploading(`Error adding Hair Features to ${data.length} ingredients`, alert.options);
            });
    };

    return <Grid container>
        <TableGrid ingredients={data} onDelete={handleObjectSelected} lightColor title="Selected Ingredients" />
        <Grid item sx={{
            minWidth: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            marginTop: `-1.1em`,
        }}>
            <LoadingButton
                type="button"
                loading={isLoading}
                disabled={context.formValues === undefined}
                variant="contained"
                color="primary"
                children="Add Ingredients"
                onClick={() => {
                    if(context.formValues) {
                        addTagToIngredient(context.formValues);
                    }
                }}
                sx={{
                    height: `3em`,
                    width: `10em`,
                    marginRight: `2.5em`
                }}
            />
        </Grid>
    </Grid>;
};

type TableProps = {
    ingredients: ProductIngredient[];
    onDelete?: (ingredientId: string) => void;
    lightColor?: boolean;
    title?: string
};

const TableGrid: FC<TableProps> = ({ ingredients, onDelete, lightColor, title }) => (
    <GridContainer xs={20}
        style={lightColor
            ? gridLightStyle
            : {}}
        container
        spacing={2}
        marginTop={3}
        marginLeft={0}>
        {title && <SelectedTitle children={title}/>}
        {ingredients.map(item => (
            <Grid item key={item.id} sx={{ minWidth: 0 }}>
                <ComparLightChip
                    style={lightColor ? chipLightStyle : {}}
                    label={item.name}
                    onDelete={onDelete ? () => onDelete(item.id) : undefined}
                    deleteIcon={onDelete
                        ? <HighlightOffIcon/>
                        : undefined}
                />
            </Grid>
        ))}
    </GridContainer>
);

const chipLightStyle = {
    backgroundColor: comparColors.primary.light,
};

const gridLightStyle = {
    backgroundColor: comparColors.white,
    borderRadius: ` 20px`,
    border: `2px solid #F7F5F3`,
    paddingTop: `0px`
};

const SelectedTitle = styled(`span`)({
    minWidth: 0,
    backgroundColor: `#fff`,
    position: `absolute`,
    marginTop: `-0.8em`,
    fontFamily: `ProximaNova-Semibold`
});

const GridContainer = styled(Grid)({
    backgroundColor: comparColors.primary.light,
    borderRadius: ` 20px 20px 20px 20px`,
    padding: `1em`,
    paddingBottom: `2.5em`,
});