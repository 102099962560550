import { Button, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Article } from '../../../global';
import { createArticle, getArticleList, updateArticle } from '../../../setup/api/articles';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const ArticlesPage: FC = () => {
    const [articleCount, setArticleCount] = useState(0);
    const [articleList, setArticleList] = useState<Article[]>([]);

    const { request, isLoading, abortController } = useFetch();
    const update = useFetchWithAlert(alertPayload(`Article Updated `));

    const queriesFromRouter = useLocation().search;
    const [queryParams, setQueryParams] = useState(queriesFromRouter);

    useEffect(() => {
        request(getArticleList, queryParams, abortController.signal).then(articles => {
            if (articles) {
                console.log(articles.results);
                setArticleList(articles.results);
                setArticleCount(articles.totalPages * 10);
            }
        })
            .catch(error => {
                console.error(`Failed to fetch articles:`, error);
            });
    }, [queryParams]);

    const handleTrendingChange = async (articleId: string, currentStatus: boolean) => {
        const payload = { trending: !currentStatus };

        update.request(updateArticle, articleId, payload, abortController.signal)
            .then(res => {
                console.log(res);
                // Assuming `res` contains the updated article or relevant data
                setArticleList((prevArticles: Article[] | undefined) => {
                    if (!prevArticles) {
                        return [];
                    }

                    return prevArticles.map(article => {
                        if (article.id === articleId) {
                            return { ...article, trending: !currentStatus };
                        }
                        return article;
                    });
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    interface SortConfig {
        key: string | null;
        direction: `ASC` | `DESC`;
    }

    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: `ASC` });

    const handleSort = (key: string) => {
        let direction: `ASC` | `DESC` = `ASC`;

        if (sortConfig.key === key && sortConfig.direction === `ASC`) {
            direction = `DESC`;
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(queriesFromRouter);

        if (sortConfig.key) {
            searchParams.set(`sortKey`, sortConfig.key);
            searchParams.set(`sortDirection`, sortConfig.direction);
        }

        setQueryParams(`?${searchParams.toString()}`);
    }, [queriesFromRouter, sortConfig]);

    return <Grid>
        <Grid container xs={12} marginTop={3}>
            <Grid xs={10} marginTop={3}>
                <FilterComponent
                    count={articleCount}
                    entity='article'
                    showSearchFilter
                    showPagination
                />
            </Grid>
            <Grid xs={2} marginTop={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                <RenderCreateArticleButton />
            </Grid>
        </Grid>
        {articleList && (
            <Datagrid<Article>
                data={articleList}
                headings={articleHeadings}
                link="article"
                loading={isLoading}
                renderRow={(headings: Heading[], item: ArticlePartial) => renderRows(headings, item, handleTrendingChange)}
                handleSort={handleSort}
                sortConfig={sortConfig}
            />
        )}
    </Grid>;
};

const RenderCreateArticleButton: FC = () => <Link to='/article' style={{ textAlign: `center` }}>
    <Button
        color="primary"
        variant="contained"
        children="Create New Article" />
</Link>;

export const articleHeadings: Array<[string, string]> = [
    [`subject`, `Subject`],
    [`hairFeatures`, `Hair Features`],
    [`category`, `Category`],
    [`hasPicture`, `Has a picture`],
    [`createdAt`, `Created`],
    [`updatedAt`, `Updated`],
    [`summary`, `Has summary`],
    [`trending`, `Trending`]
];

type Heading = [string, string];

interface ArticlePartial {
    id: string;
    [key: string]: unknown;
}

function renderRows(
    headings: Heading[],
    item: ArticlePartial,
    handleTrendingChange: (articleId: string, currentStatus: boolean) => void
) {
    const hairFeatures = item[`hairFeatures`] as Array<{ feature: string, value: string }> || [];

    const hasPicture = item[`hasPicture`] as boolean || false;

    let hasPictureString = `No`;

    if (hasPicture) {
        hasPictureString = `Yes`;
    }

    // Extract the "feature" and "value" pairs and join them with commas
    const featuresString = hairFeatures.map(featureObj => `${featureObj.feature}: ${featureObj.value}`).join(`, `);

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: `numeric`,
            month: `long`,
            day: `numeric`,
            hour: `numeric`,
            minute: `numeric`,
        };

        return new Date(dateString).toLocaleString(undefined, options);
    };

    const summary = item[`summary`] as string || ``;

    const summaryValue = summary.length > 0 ? `Yes` : `No`;

    return headings.map(([key]) => {
        if (key === `hairFeatures`) {
            return <td key={key}>{featuresString}</td>;
        }
        if (key === `createdAt` || key === `updatedAt`) {
            return <td key={key}>{formatDate(item[key] as string)}</td>;
        }
        if (key === `hasPicture`) {
            return <td key={key}>{hasPictureString}</td>;
        }
        if (key === `summary`) {
            return <td key={key}>{summaryValue}</td>;
        }
        if (key === `trending`) {
            return (
                <td key={key}>
                    <Checkbox
                        disabled={featuresString !== `` || summary.length === 0}
                        checked={item[key] as boolean}
                        onChange={() => handleTrendingChange(item.id as string, item[key] as boolean)}
                        color="primary"
                        sx={{
                            color: featuresString !== `` ? `grey.400` : `primary.main`,
                            '&.Mui-disabled': {
                                color: `grey.400`,
                            }
                        }}
                    />
                </td>
            );
        }
        return <td key={key}>{(item[key] as string)}</td>;
    });
}