import config from 'react-global-configuration';

export const getPendingProductList = (query: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product-pending${query}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getPendingProductById = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product-pending/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const updatePendingProductById = (id: string, payload: any): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product-pending/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deletePendingProductById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product-pending/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});