/* eslint-disable quotes */
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import * as auth from './auth/AuthRedux';

export const rootReducer = combineReducers({
    auth: auth.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

// Thanks to that you will have ability to use useSelector hook with state value
declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState { }
}

export function* rootSaga(): Generator {
    yield all([auth.saga()]);
}
