import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/styles';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { deleteIngredientCategoryFromIngredient, deleteIngredientSubCategoryFromIngredient } from '../../../setup/api/ingredientCategory';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { IngredientCategory, IngredientSubCategory } from '../../../setup/models';
import { comparColors } from '../../helpers/colors';
import { ComparLightChip } from '../TagChips/ComparLightChip';

type Props = {
    data: Array<any> | undefined
    ingredientId: string
    setObjectSelected?: Dispatch<SetStateAction<any[]>>
    setObjectSubSelected?: Dispatch<SetStateAction<any[]>>
    objectsSelected?: any[],
    objectsSubSelected?: any[],
    titleText: string
    subCategory: boolean
}

export const SelectedIngredientCategoryTable: FC<Props> = ({
    data,
    ingredientId,
    setObjectSelected,
    setObjectSubSelected,
    objectsSelected,
    objectsSubSelected,
    titleText,
    subCategory,
}) => {
    const deleted = useFetchWithAlert(alertPayload(`Ingredient category removed`));

    const handleDelete = (ingredientCategoryId: string) => {
        if (!subCategory) {
            const text = `Are you sure?\nThis will remove the category from the ingredient and all related sub categories.`;

            const proceed = (objectsSubSelected && objectsSubSelected.length > 0) ? confirm(text) : true;

            if (proceed) {
                if (setObjectSelected && objectsSelected) {
                    const filteredList = objectsSelected.filter(selectedItem => selectedItem.id !== ingredientCategoryId);

                    setObjectSelected(filteredList);

                    if (setObjectSubSelected && objectsSubSelected) {
                        const filteredList = objectsSubSelected.filter(selectedItem => selectedItem.parent.id !== ingredientCategoryId);

                        setObjectSubSelected(filteredList);
                    }

                    deleted.request(deleteIngredientCategoryFromIngredient, ingredientId, ingredientCategoryId).then(deleteCategory => {
                        console.log(deleteCategory);
                    });
                }
            }
        } else if (setObjectSelected && objectsSelected) {
            const filteredList = objectsSelected.filter(selectedItem => selectedItem.id !== ingredientCategoryId);

            setObjectSelected(filteredList);
            console.log(ingredientCategoryId);

            deleted.request(deleteIngredientSubCategoryFromIngredient, ingredientId, ingredientCategoryId).then(deleteCategory => {
                console.log(deleteCategory);
            });
        }
    };

    return <Grid container>
        {data &&
            <Grid>
                <TableGrid ingredientCategories={data} onDelete={handleDelete} lightColor title={titleText} />
                <Grid item sx={{
                    minWidth: `100%`,
                    display: `flex`,
                    justifyContent: `flex-end`,
                    marginTop: `-1.1em`,
                }}>
                </Grid>
            </Grid>
        }
    </Grid>;
};

type IngredientCategoryOrSubCategory = IngredientCategory | IngredientSubCategory;

type TableProps = {
    ingredientCategories: IngredientCategoryOrSubCategory[];
    onDelete?: (ingredientCategoryId: string) => void;
    lightColor?: boolean;
    title?: string
};

const TableGrid: FC<TableProps> = ({ ingredientCategories, onDelete, lightColor, title }) => (
    <GridContainer xs={20}
        style={lightColor
            ? gridLightStyle
            : {}}
        container
        spacing={2}
        marginTop={3}
        marginLeft={0}>
        {title && <SelectedTitle children={title} />}
        {ingredientCategories.map(item => {
            const labelName = `parent` in item ? `${item.parent.name} - ${item.name}` : item.name;

            return (
                <Grid item key={item.id} sx={{ minWidth: 0 }}>
                    <ComparLightChip
                        style={lightColor ? chipLightStyle : {}}
                        label={labelName}
                        onDelete={onDelete ? () => onDelete(item.id) : undefined}
                        deleteIcon={onDelete
                            ? <HighlightOffIcon />
                            : undefined}
                    />
                </Grid>
            );
        })}
    </GridContainer>
);

const chipLightStyle = {
    backgroundColor: comparColors.primary.light,
};

const gridLightStyle = {
    backgroundColor: comparColors.white,
    borderRadius: ` 20px`,
    border: `2px solid #F7F5F3`,
    paddingTop: `0px`
};

const SelectedTitle = styled(`span`)({
    minWidth: 0,
    position: `absolute`,
    marginTop: `-0.8em`,
    fontFamily: `ProximaNova-Semibold`
});

const GridContainer = styled(Grid)({
    backgroundColor: comparColors.primary.light,
    borderRadius: ` 20px 20px 20px 20px`,
    padding: `1em`,
    paddingBottom: `2.5em`,
});