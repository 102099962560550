import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { styled } from '@mui/styles';
import { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>> | undefined
  title?: string
}
/**
 * Isolated modal component that can be fulfilled with props.children
 * is generally use to load forms
 * @param props.open current state of the modal
 * @param props.onClose callback that should update the open variable
 * @param props.title title of the modal
 */
export const ModalCompar: FC<Props> = ({ children, open, onClose }) => <Dialog
    fullWidth={true}
    sx={{ height: `100%`,
        overflow: `hidden`,
    }}
    style={{
        paddingRight: `0px !important`,
        paddingLeft: `0px !important`,
    }}
    maxWidth={false}
    open={open}
    onClose={() => {
        if(onClose) {
            onClose(false);
        }
    }}
>
    <ModalHeader>
        <ModalTitle/>
        {onClose && <ModalTitle aria-label="close" onClick={() => onClose(false)}>
            close
        </ModalTitle>}
    </ModalHeader>
    <ModalContent>
        <Grid marginX={0} marginBottom={3} marginTop={3} >{children}</Grid>
    </ModalContent>
</Dialog>;

const ModalContent = styled(DialogContent)(() => ({
    position: `relative`,
    flex: `1 1 auto`,
    padding: `0px 0px 0px 0px`,
}));

const ModalHeader = styled(DialogTitle)(() => ({
    display: `flex`,
    flexShrink: 0,
    maxHeight: `10em`,
    alignItems: `center`,
    fontFamily: `ProximaNova-Regular`,
    justifyContent: `space-between`,
    padding: `10px 10px 0px 0px`,
    borderTopLeftRadius: `0.475rem`,
    borderTopRightRadius: `0.475rem`
}));

const ModalTitle = styled(`h3`)(() => ({
    lineHeight: `1.2`,
    fontSize: `0.9em`,
    paddingRight: `1em`,
    paddingTop: `0.5em`,
    cursor: `pointer`,
    color: `#000`,
    fontFamily: `ProximaNova-Bold`,
}));