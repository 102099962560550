const errorMessages = [
    {
        pattern: /^Custom auth.*/i,
        message: `Incorrect username or password.`
    },
    {
        pattern: /^CUSTOM_AUTH.*/i,
        message: `Custom Authentication is not enabled for the client. Please check that email and password are correct.`
    },
    {
        pattern: /^2 validation.*/i,
        message: `Please use a valid email address.`
    },
    {
        pattern: /^Not.*/i,
        message: `Invalid email or password. Please try again.`
    },
    {
        pattern: /^Password.*/i,
        message: `Your password must contain at least one uppercase and lowercase character, one symbol, and a minimum length of 8 characters.`
    },
    {
        pattern: /^Code.*/i,
        message: `The verification code you entered is invalid. Please try again.`
    },
    {
        pattern: /^Expired.*/i,
        message: `The verification code you entered has expired. Please request a new one.`
    },
    {
        pattern: /^Too.*/i,
        message: `Too many requests have been made. Please try again later.`
    },
    {
        pattern: /^Invalid verification.*/i,
        message: `Invalid verification code.`
    },
    {
        pattern: /^Invalid code provided.*/i,
        message: `Invalid verification code.`
    },
    {
        pattern: /^Invalid username.*/i,
        message: `Incorrect username or password.`
    },
    {
        pattern: /^Incorrect username.*/i,
        message: `Incorrect username or password.`
    },
    {
        pattern: /^Limit.*/i,
        message: `The maximum number of attempts has been reached. Please try again later.`
    },
    {
        pattern: /.*/,
        message: `An error occurred. Please try again later.`
    },
];

export const getErrorMessage = (message: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { message: errorMessage } = errorMessages.find(({ pattern }) => pattern.test(message))!;

    return errorMessage;
};