/**
 * Returns the provided query string if it exists, or an empty string if it does not.
 *
 * @param query - The query string to return.
 * @returns The provided query string if it exists, or an empty string if it does not.
 */

export const getQueryOrEmptyString = (query: string | undefined):string => {
    if (query) {
        return query;
    }
    return ``;
};

export const convertStringQueryToTextFieldReadableDate = (stringQuery?: string):string => {
    if (stringQuery && stringQuery?.length > 1) {
        return stringQuery.split(` `)[0];
    }
    return ``;
};

/**
 *
 * @param {string} numOfHours amount of hours to sum on the Date
 * @param {Date} date date that is going to be changed
 * @returns return the same date + the amount of hours increased
 */
function addHours(numOfHours: number, date: Date) {
    const dateCopy = new Date(date.getTime());

    dateCopy.setTime(dateCopy.getTime() + numOfHours * 60 * 60 * 1000);

    return dateCopy;
}

/**
 * The following function converts the Date from the Redux filter to a valid input to the date filter component
 * @param dateFromRedux the date from the redux filter state
 * @returns valid string that can be used on the date filter
 */
export const convertDateToMuiDNS = (dateFromRedux?: string): string => {
    if (dateFromRedux) {
        const stringToDate = new Date(dateFromRedux);
        const currentDateWithoutHours = addHours(2, stringToDate).toISOString()
            .split(`T`)[0];

        return currentDateWithoutHours;
    }
    const defaultDate = new Date().toISOString()
        .split(`T`)[0];

    return defaultDate;
};