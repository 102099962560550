/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_compar/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
/*
 * Axios
 * import axios from 'axios'
 */
import './_compar/fonts.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
/*
 * Redux
 * https://github.com/rt2zz/redux-persist
 */
import { PersistGate } from 'redux-persist/integration/react';

import { MetronicI18nProvider } from './_compar/i18n/Metronici18n';
// Apps
import { App } from './app/App';
// Import * as _redux from './setup'
import store, { persistor } from './setup/redux/Store';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const PUBLIC_URL = process.env.PUBLIC_URL as string;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store)

ReactDOM.render(
    <MetronicI18nProvider>
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
                <React.StrictMode>
                    <App basename={PUBLIC_URL} />
                </React.StrictMode>
            </PersistGate>
        </Provider>
    </MetronicI18nProvider>,
    document.getElementById(`root`)
);