import config from 'react-global-configuration';

export const getUsersForStatistics = (): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/user/statics/all`, {
    mode: `cors`,
    credentials: `include`,
});

export const sendNotificationHandler = (queries: string, abort: AbortSignal, payload: any): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/scheduler/send-notification-ad-hoc${queries ? queries : `?doSend=false`}`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
    signal: abort,
});