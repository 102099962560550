import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, styled } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ArticleCategory, HairFeatures, hairFeaturesOptions, HairFeaturesValueType } from "../../../constants";
import { Article, ProductCategory } from '../../../global';
import { createArticle, deleteArticleById, getArticleById, uploadArticlePhoto } from '../../../setup/api/articles';
import { getHairFeatureByValues } from '../../../setup/api/hairFeature';
import { getProductCategoriesDistinctOrdered, getProductCategoryByName } from '../../../setup/api/productCategory';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { HairFeature } from '../../../setup/models';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { SelectedHairFeatureTable } from '../../../ui/components/HairFeatureTable/HairFeatureTable';
import { SelectedProductCategoryTable } from '../../../ui/components/ProductCategoryTable/ProductCategoryTable';
import { TextEditor } from '../../../ui/components/TextEditor/TextEditor';
import { ComparTextField } from '../../../ui/components/TextField/ComparTextField';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { parseCapitalStringToReadable, parseObjectKeyToReadableString, parseProductCategoryBack, parseReadableStringToObjectKeyName } from "../../../ui/helpers/stringModifiers";

interface CategoryItem {
    compar_name: string;
}

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const CreateArticlePage: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { request, abortController } = useFetch();
    const [loading, setLoading] = useState<boolean>(true);
    const [uploadMessage, setUploadMessage] = useState<string>(``);

    const [hairFeatureSelected, setHairFeatureSelected] = useState<HairFeatures | ``>(``);
    const [hairFeatureValueSelected, setHairFeatureValueSelected] = useState<HairFeaturesValueType | ``>(``);
    const [productCategory, setProductCategory] = useState<Partial<ProductCategory> | ``>();

    const [selectedHairFeatureValues, setSelectedHairFeatureValues] = useState<HairFeaturesValueType[]>([]);

    const update = useFetchWithAlert(alertPayload(`Article Updated `));
    const create = useFetchWithAlert(alertPayload(`Article Created `));
    const deleted = useFetchWithAlert(alertPayload(`Article Deleted `));
    const [article, setArticle] = useState<Partial<Article>>({});

    const [selectedHairFeatures, setSelectedHairFeatures] = useState<HairFeature[]>([]);
    const [selectedProductCategories, setSelectedProductCategories] = useState<ProductCategory[]>([]);

    const [productCategories, setProductCategories] = useState<string[]>([]);

    const [requestError, setRequestError] = useState<string>();

    const [articleContent, setArticleContent] = useState<string>(``);
    const [articlePhotoName, setArticlePhotoName] = useState<any>();
    const [articlePhotoUrl, setArticlePhotoUrl] = useState<any>();
    const [articleCategory, setArticleCategory] = useState<string>(``);
    const [updatedPicture, setUpdatedPicture] = useState<boolean>(false);

    const history = useHistory();

    const getArticle = () => {
        request(getArticleById, id, abortController.signal).then(article => {
            setArticle(article);
            setSelectedHairFeatures(article.hairFeatures);
            setSelectedProductCategories(article.productCategory);
            setArticleContent(article.content);
            setArticleCategory(article.category);
            setUpdatedPicture(article.hasPicture);

            if (article.hasPicture) {
                setArticlePhotoUrl(`https://compar-article-images.s3.eu-north-1.amazonaws.com/${article.id}`);
                console.log(`https://compar-article-images.s3.eu-north-1.amazonaws.com/${article.id}`);
            }
        });
    };

    const uploadPhotoToArticle = (event: any) => {
        setLoading(true);
        setUploadMessage(``);
        const file = event.target.files[0];

        if (file) {
            setArticlePhotoName(file.name);

            const reader = new FileReader();

            reader.onloadend = () => {
                const previewUrl = reader.result;

                setArticlePhotoUrl(previewUrl);

                request(uploadArticlePhoto, previewUrl, article.id)
                    .then(res => {
                        if (res.imageId) {
                            setUploadMessage(`Picture uploaded succesfully`);
                            setUpdatedPicture(true);
                            setLoading(false);

                            setTimeout(() => {
                                setUploadMessage(``);
                            }, 2500);
                        }
                    });
            };
            reader.readAsDataURL(file);
        }
    };

    const appendSelectedHairFeature = () => {
        setLoading(true);
        const isFeatureAlreadySelected = selectedHairFeatures.some(
            existingHairFeature => existingHairFeature.feature === hairFeatureSelected && existingHairFeature.value === hairFeatureValueSelected
        );

        if (!isFeatureAlreadySelected) {
            request(getHairFeatureByValues, hairFeatureSelected, hairFeatureValueSelected, abortController.signal)
                .then(hairFeature => {
                    if (hairFeature.id) {
                        setSelectedHairFeatures(prevArray => [...prevArray, hairFeature]);
                        setHairFeatureValueSelected(``);
                        setLoading(false);
                    } else {
                        console.log(`Hair Feature not found`);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching hairFeature:`, error);
                });
        } else {
            console.log(`Already added to article`);
        }
    };

    const appendSelectedProductCategory = () => {
        setLoading(true);
        const isCategoryAlreadySelected = selectedProductCategories.some(
            existingProductCategory => existingProductCategory.compar_name === productCategory
        );

        if (!isCategoryAlreadySelected) {
            request(getProductCategoryByName, productCategory, abortController.signal)
                .then(productCategory => {
                    if (productCategory.id) {
                        setSelectedProductCategories(prevArray => [...prevArray, productCategory]);
                        setProductCategory(``);
                        setLoading(false);
                    } else {
                        console.log(`Product Category not found`);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching product category:`, error);
                });
        } else {
            console.log(`Already added to article`);
        }
    };

    const createUpdateArticle = (payload: Partial<Article>) => {
        delete payload.hairFeatures;
        delete payload.content;
        delete payload.category;
        delete payload.productCategory;
        delete payload.hasPicture;
        payload.hairFeatures = selectedHairFeatures;
        payload.content = articleContent;
        payload.category = articleCategory;
        payload.productCategory = selectedProductCategories;
        payload.hasPicture = updatedPicture;

        if (payload.id) {
            update.request(createArticle, payload, abortController.signal).then(res => {
                console.log(res);
            });
        } else {
            create.request(createArticle, payload, abortController.signal).then(res => {
                history.push(`/article/${res.id}`);
            });
        }
    };

    const deleteArticleHandler = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteArticleById, id, abortController.signal).then(deleteArticle => {
                    if (deleteArticle.status === 200) {
                        history.push(`/articles`);
                    } else {
                        setRequestError(`Error message: ${deleteArticle.message}, status code: ${deleteArticle.status}`);
                    }
                });
            }
        }
    };

    useEffect(() => {
        try {
            request(getProductCategoriesDistinctOrdered, abortController.signal)
                .then((categories: CategoryItem[]) => {
                    setProductCategories(categories.map(item => item.compar_name));
                    console.log(categories);
                })
                .catch(error => {
                    console.error(`Error fetching categories:`, error);
                });
        } catch (error) {
            console.error(`Failed to fetch product categories`, error);
        }
        if (id) {
            getArticle();
        }
        setLoading(false);
    }, [id]);

    console.log(hairFeatureSelected.length);

    return <Fragment>
        <Formik
            enableReinitialize
            onSubmit={values => {
                if (id) {
                    return createUpdateArticle(values);
                }
                return createUpdateArticle(values);
            }}
            initialValues={article}>
            {(props: FormikProps<Partial<Article>>) => (
                <Form>
                    <Grid container justifyContent={`space-between`}>
                        <Grid xs={5} marginTop={3} >
                            <ComparTextField
                                type='string'
                                placeholder='Subject'
                                label='Subject'
                                InputLabelProps={{ shrink: true }}
                                {...props.getFieldProps(`subject`)}
                            />
                            {props.values.hairFeatures && props.values.hairFeatures.length === 0 &&
                                <Grid>
                                    <ComparTextField
                                        type='string'
                                        required={props.values.trending === true}
                                        placeholder='Summary'
                                        label='Summary'
                                        inputProps={{
                                            maxLength: 90
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        {...props.getFieldProps(`summary`)}
                                    />
                                    <TitleHeading children={props.values.summary ? props.values.summary.length + `/90` : null} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                    <div style={{
                                        display: `flex`,
                                        flexDirection: `row`,
                                        justifyContent: `flex-end`,
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={props.values.summary?.length === 0}
                                                    {...props.getFieldProps(`trending`)}
                                                    checked={props.values.trending || false}
                                                    onChange={props.handleChange}
                                                    value={props.values.trending || false}
                                                    color="primary"
                                                />
                                            }
                                            label="Trending"
                                        />
                                    </div>
                                </Grid>
                            }
                            <SelectFieldFromArray<string>
                                name='category'
                                label='Category'
                                value={articleCategory}
                                sx={{
                                    'fieldset': {
                                        border: `none`
                                    }
                                }}
                                keyName='category'
                                onChange={(e: { target: { value: ArticleCategory } }) => {
                                    const joinedValue = e.target.value as ArticleCategory;

                                    setArticleCategory(joinedValue);
                                }}
                                options={Object.keys(ArticleCategory).map((key: string) => key)}
                            />
                            <TextEditor data={articleContent} setContentProp={setArticleContent} />
                            <div style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                                marginTop: `1.2em`
                            }}>
                                <LoadingButton
                                    loading={update.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {id ? `Update Article` : `Create Article`}
                                </LoadingButton>
                                {id &&
                                    <Button
                                        component="label"
                                        children="Delete article"
                                        onClick={deleteArticleHandler}
                                    />
                                }
                            </div>
                            {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                        </Grid>
                        <Grid xs={6} marginTop={2}>
                            <HairFeatureFormGrid >
                                <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                    <TitleHeading children='Add Hair Feature' style={{
                                        fontSize: `1.5em`,
                                    }} />
                                </Grid>
                                <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                    width: `100%`
                                }} >
                                    <Grid xs={5.7}>
                                        <SelectFieldFromArray<string>
                                            name='feature'
                                            label='Hair Feature'
                                            keyName='feature'
                                            required={false}
                                            sx={{
                                                marginRight: `1em`,
                                                backgroundColor: `#000`,
                                                borderRadius: `20px`,
                                                'fieldset': {
                                                    border: `none`
                                                }

                                            }}
                                            inputLabelStyle={{
                                                backgroundColor: `transparent`,
                                            }}

                                            style={{
                                                backgroundColor: `#fff`,
                                            }}
                                            onChange={(e: { target: { value: HairFeatures } }) => {
                                                const joinedValue = parseReadableStringToObjectKeyName(e.target.value) as HairFeatures;

                                                setSelectedHairFeatureValues(hairFeaturesOptions[joinedValue]);

                                                setHairFeatureSelected(joinedValue);

                                                setHairFeatureValueSelected(``);
                                            }}
                                            options={Object.keys(hairFeaturesOptions).map((key: string) => parseObjectKeyToReadableString(key))}
                                        />
                                    </Grid>
                                    <Grid xs={5.7}>
                                        <SelectFieldFromArray<string>
                                            name='value'
                                            label='Hair Feature Value'
                                            required={false}
                                            value={hairFeatureValueSelected}
                                            sx={{
                                                marginRight: `1em`,
                                                backgroundColor: `#fff`,
                                                borderRadius: `20px`,
                                                'fieldset': {
                                                    border: `none`
                                                }
                                            }}
                                            inputLabelStyle={{
                                                backgroundColor: `transparent`,
                                            }}
                                            style={{
                                                backgroundColor: `#fff`,
                                            }}
                                            options={selectedHairFeatureValues}
                                            onChange={(e: { target: { value: string } }) => {
                                                const value = e.target.value as HairFeaturesValueType;

                                                setHairFeatureValueSelected(value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Button type="button"
                                    disabled={!hairFeatureValueSelected || !hairFeatureSelected}
                                    variant="contained"
                                    color="primary"
                                    children="Add Feature"
                                    onClick={appendSelectedHairFeature}
                                    sx={{
                                        height: `3em`,
                                        width: `10em`,
                                        marginLeft: `1em`,
                                    }} />
                                <Grid>
                                    <SelectedHairFeatureTable
                                        data={selectedHairFeatures}
                                        objectsSelected={selectedHairFeatures}
                                        setObjectSelected={setSelectedHairFeatures}
                                        titleText='Selected Hair Features'
                                    />
                                </Grid>
                                <Grid xs={12} display={`flex`} justifyContent={`flex-end`}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            {...props.getFieldProps(`hair_feature_combination`)}
                                            checked={props.values.hair_feature_combination || false}
                                            onChange={props.handleChange}
                                            value={props.values.hair_feature_combination || false}
                                            color="primary"
                                        />}
                                        label="Only for hair feature combination"
                                    />
                                </Grid>
                                <Grid xs={12} display={`flex`} marginTop={2} justifyContent={`center`}>
                                    <TitleHeading children='Add Product Category' style={{
                                        fontSize: `1.5em`,
                                    }} />
                                </Grid>
                                <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                    width: `100%`
                                }} >
                                    <Grid xs={5.7}>
                                        <SelectFieldFromArray<string>
                                            name='productCategory'
                                            label='Product Category'
                                            keyName='productCategory'
                                            required={false}
                                            sx={{
                                                marginRight: `1em`,
                                                backgroundColor: `#000`,
                                                borderRadius: `20px`,
                                                'fieldset': {
                                                    border: `none`
                                                }

                                            }}
                                            inputLabelStyle={{
                                                backgroundColor: `transparent`,
                                            }}

                                            style={{
                                                backgroundColor: `#fff`,
                                            }}
                                            onChange={(e: { target: { value: ProductCategory } }) => {
                                                let string = e.target.value as any;

                                                string = parseProductCategoryBack(string);

                                                const joinedValue = string as ProductCategory;

                                                setProductCategory(joinedValue);
                                            }}
                                            options={productCategories.map(parseCapitalStringToReadable)}
                                        />
                                    </Grid>
                                </Grid>
                                <Button type="button"
                                    disabled={!productCategory}
                                    variant="contained"
                                    color="primary"
                                    children="Add Category"
                                    onClick={appendSelectedProductCategory}
                                    sx={{
                                        height: `3em`,
                                        width: `10em`,
                                        marginLeft: `1em`,
                                    }} />
                                <Grid>
                                    <SelectedProductCategoryTable
                                        data={selectedProductCategories}
                                        objectsSelected={selectedProductCategories}
                                        setObjectSelected={setSelectedProductCategories}
                                        titleText='Selected Product Categories'
                                    />
                                </Grid>
                            </HairFeatureFormGrid>
                            {article.id &&
                                <Grid marginTop={3}>
                                    <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                        <TitleHeading children='Upload photo' style={{
                                            fontSize: `1.5em`,
                                        }} />
                                    </Grid>
                                    <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                        <TitleHeading children={uploadMessage} style={{
                                            fontSize: `0.75em`,
                                            color: `green`
                                        }} />
                                    </Grid>
                                    <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                        width: `100%`
                                    }} >
                                        <Grid xs={9}>
                                            <ComparTextField
                                                type='string'
                                                placeholder='Upload photo...'
                                                InputLabelProps={{ shrink: true }}
                                                value={articlePhotoName}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload Photo
                                                <input type="file" accept="image/jpeg, image/png" hidden onChange={uploadPhotoToArticle} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {articlePhotoUrl && <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                        <TitleHeading children='Preview:' style={{
                                            fontSize: `1em`,
                                        }} />
                                    </Grid>}
                                    {articlePhotoUrl && <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                        <Grid xs={12} display={`flex`} justifyContent={`center`}>
                                            <img src={articlePhotoUrl} style={{ width: `25%`, height: `auto`, borderRadius: `5px` }} />
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Form>)}
        </Formik>
    </Fragment >;
};

const HairFeatureFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));