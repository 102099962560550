import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Form, Formik, FormikProps } from 'formik';
import { FC, useContext, useEffect, useState } from 'react';

import { HairFeatures, hairFeaturesOptions, HairFeaturesValueType } from '../../../constants';
import { saveIngredientTag, SaveIngredientTagPayload } from '../../../setup/api/ingredientTag';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload } from '../../../setup/hooks/useFetchWithAlert';
import { ProductIngredient } from '../../../setup/models';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { comparColors } from '../../../ui/helpers/colors';
import { parseObjectKeyToReadableString, parseReadableStringToObjectKeyName } from '../../../ui/helpers/stringModifiers';
import { uploading } from '../../../ui/helpers/toasts';
import { TagToIngredientSchema } from '../tag/TagToIngredientForm';
import { AddTagToIngredientFormContext } from './AddHairFeatureToIngredientsPage';

type Props = {
    ingredients: ProductIngredient[];
    resetIngredients: () => void;
    getQueries: (values: SaveIngredientTagPayload) => void;
    useSubmit?: boolean;
}

export const AddTagForm: FC<Props> = ({ ingredients, getQueries, resetIngredients, useSubmit }) => {
    const [tagPayload, setTagPayload] = useState<Partial<SaveIngredientTagPayload>>({});
    const [hairFeatureValues, setHairFeatureValues] = useState<HairFeaturesValueType[]>([]);

    const context = useContext(AddTagToIngredientFormContext);

    const { request, isLoading } = useFetch();

    const addTagToIngredient = (values: Partial<SaveIngredientTagPayload>) => {
        const ingredientsWithValues = ingredients.map((ingredient: {id: string}) => {
            const payload = {
                ingredientId: ingredient.id,
                ...values
            };

            return request(saveIngredientTag, payload).then(() => resetIngredients());
        });

        const alert = alertPayload(`Adding Hair Features to ${ingredients.length} ingredients`);

        Promise.all(ingredientsWithValues).then(() => {
            uploading(alert.message, alert.options);
        })
            .catch(() => {
                uploading(`Error adding Hair Features to ${ingredients.length} ingredients`, alert.options);
            });
    };

    return <>
        <Box style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            width: `100%`,
        }}>
            <Formik
                validationSchema={TagToIngredientSchema[0]}
                initialValues={tagPayload}
                isInitialValid={false}
                enableReinitialize
                onSubmit={addTagToIngredient}>

                {(props: FormikProps<SaveIngredientTagPayload>) => {
                    useEffect(() => {
                        if(props.isValid) {
                            getQueries(props.values);

                            context.setFormValues(props.values);
                        }
                    }, [props]);

                    return <Form style={{
                        width: `100%`,
                    }}>
                        {!useSubmit && <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                            width: `100%`,
                        }} >
                            <Grid xs={5.7}>
                                <SelectFieldFromArray<string>
                                    name='feature'
                                    label='Hair Feature'
                                    sx={{
                                        'fieldset': {
                                            border: `none`
                                        }
                                    }}
                                    keyName='feature'
                                    onChange={(e: {target : { value: HairFeatures}} ) => {
                                        const joinedValue = parseReadableStringToObjectKeyName(e.target.value) as HairFeatures;

                                        setHairFeatureValues(hairFeaturesOptions[joinedValue]);

                                        props.setFieldValue(`feature`, joinedValue);
                                    }}
                                    options={Object.keys(hairFeaturesOptions).map((key: string) => parseObjectKeyToReadableString(key))}
                                />
                            </Grid>
                            <Grid xs={5.7}>
                                <SelectFieldFromArray<string>
                                    name='value'
                                    sx={{
                                        'fieldset': {
                                            border: `none`
                                        }
                                    }}
                                    label='Hair Feature Value'
                                    onChange={(e: {target : { value: HairFeatures}} ) => {
                                        const joinedValue = parseReadableStringToObjectKeyName(e.target.value) as HairFeatures;

                                        console.log(joinedValue);
                                        props.setFieldValue(`value`, joinedValue);
                                    }}
                                    options={hairFeatureValues.map((value: HairFeaturesValueType) => parseObjectKeyToReadableString(value))}
                                />
                            </Grid>
                        </Grid>}
                        <Grid display="flex" alignItems="center" justifyContent="space-between">
                            {!useSubmit && <Grid xs={5.7}>
                                <SelectFieldFromArray<string>
                                    name='tagName'
                                    label='Recommend/Prevent'
                                    keyName='tagName'
                                    sx={{
                                        'fieldset': {
                                            border: `none`
                                        }
                                    }}
                                    onChange={(e: {target : { value: string}} ) => {
                                        const joinedValue = parseReadableStringToObjectKeyName(e.target.value);

                                        props.setFieldValue(`tagName`, joinedValue);
                                    }}
                                    options={[`Recommend`, `Prevent`]}
                                />
                            </Grid>}
                            {useSubmit && <LoadingButton type="button"
                                loading={isLoading}
                                disabled={ingredients.length === 0 || !props.isValid}
                                variant="contained"
                                color="primary"
                                children="Add Ingredients"
                                onClick={props.submitForm}

                                sx={{
                                    height: `3em`,
                                    width: `10em`,
                                    marginLeft: `1em`,

                                }} />}
                        </Grid>
                    </Form>;
                }}
            </Formik>
        </Box>
    </>;
};