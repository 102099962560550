import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';

import { useQueryParams } from '../../../setup/hooks/useQueryParams';
import { getQueryOrEmptyString } from './helpers';

export type TFilterProps = {
  name: string
  options: string[]
  query: string
}

/**
 * A dropdown menu for selecting a filter value.
 *
 * @param {string} props.title - The label for the dropdown menu.
 * @param {string[]} props.values - The list of filter values to display in the dropdown menu.
 * @param {string} props.query - The name of the query parameter to set when a filter value is selected.
 * @returns {JSX.Element} A dropdown menu for selecting a filter value.
 */
export const FilterDropDown: React.FC<TFilterProps> = ({ name, options, query }) => {
    const [queries, setQueries] = useQueryParams();
    const [isOpen, setOpen] = useState<boolean>(false);
    const toggleDropdown = () => {
        setOpen(!isOpen);
    };

    return (<FormControl fullWidth>
        <InputLabel>{name}</InputLabel>
        <Select
            open={isOpen}
            onClose={toggleDropdown}
            onOpen={toggleDropdown}
            value={getQueryOrEmptyString(queries[query])}
            inputProps={{ name: `name` }}
            label={name}
            onChange={(e:SelectChangeEvent<string> ) => setQueries(query, e.target.value)}

        >
            {/* Render a MenuItem for each value in the "values" prop */}
            {options.map((value:string, index:number) => (<MenuItem value={value} key={index}>{value}</MenuItem>))}
        </Select>
    </FormControl>
    );
};