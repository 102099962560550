import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getIngredientTagList, IngredientTag } from '../../../setup/api/ingredientTag';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { TagModal } from './TagModal';

export const IngredientTagPage: FC = () => {
    const [tagListAndCount, setTagListAndCount] = useState({
        tags: [],
        count: 0
    });

    const { id } = useParams<{ id: string }>();

    const tagQueryFromRouter = useLocation().search;

    const { request, isLoading, abortController } = useFetch();

    useEffect(() => {
        request(getIngredientTagList, tagQueryFromRouter).then(results => {
            if(results) {
                // const [tags, count] = results;

                // settagListAndCount({ tags, count });
            }
        });
        return () => {
            abortController.abort();
        };
    }, [tagQueryFromRouter]);

    return <Grid container padding={4} >
        <Grid xs={10} marginTop={3} >
            <FilterComponent
                lightTheme={false}
                count={tagListAndCount.count}
                entity='tag'
                showSearchFilter
                showPagination
            />
        </Grid>
        <Grid xs={2} marginTop={3} justifyContent={`end`} alignItems="baseline" display="flex" >
            <TagModal button />
        </Grid>

        {tagListAndCount && <Datagrid
            data={tagListAndCount.tags}
            headings={tagsHeadings}
            link='tag'
            loading={isLoading}
            createdAt
        />}

    </Grid>;
};

const tagsHeadings: Array<[keyof IngredientTag, string]> = [
    [`name`, `Feature`],
    // [`value`, `Value`],
];