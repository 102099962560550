import { Checkbox, LinearProgress } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import { AbstractModel } from '../../../setup/models';
import { comparColors } from '../../helpers/colors';
import { DatagridView } from './Datagrid.style';

type Props<Item> = {
    data: Array<Item>
    headings: Array<[string, string]>
    renderRow?: any
    renderHeaders?: (headings: [string, string][]) => JSX.Element[]
    selectable?: boolean
    selected?: Record<string, unknown>
    setSelected?: Dispatch<SetStateAction<Record<string, unknown>>>
    setObjectSelected?: Dispatch<SetStateAction<Item[]>>
    objectsSelected?: Item[]
    objectSelection?: boolean
    getIdFromItem?: (item: Record<string, unknown>) => string
    deletable?: boolean
    loading?: boolean
    link?: string
    createdAt?: boolean
    minWidthDefault?: boolean
    handleSort?: (key: any) => void
    sortConfig?: { key: string | null, direction: string }
}

/**
 *
 * @param props.data Array payload with multiple entities
 * @param props.headings tuple of string arrays with the key object name and the column name EXAMPLE: ['customerName', 'Customer Name']
 * @param props.renderRow renders the row structure on the data grid, by mapping the heading
 * @param props.link should include a string with the entity name, this will be used to access the item information on click
 * @returns list with options and personalized columns
 */

export const Datagrid = <P extends AbstractModel>({
    // FIXME: createdAt, updatedAt should be removed
    data = [],
    headings,
    renderRow,
    renderHeaders,
    selectable,
    selected = {},
    setSelected,
    setObjectSelected,
    objectsSelected,
    objectSelection,
    deletable,
    loading,
    link,
    createdAt,
    minWidthDefault,
    handleSort,
    sortConfig
}: Props<P>) => {
    const history = useHistory();

    const renderHeader = (headings: string[][]) => headings.map(([key, header]) => {
        if (handleSort && sortConfig) {
            return (
                <th key={key} style={{ padding: `0px !important`, cursor: `pointer` }}
                    onClick={() => handleSort(key)}>
                    {header}
                    {sortConfig.key === key ? (sortConfig.direction === `ASC` ? ` ↑` : ` ↓`) : null}
                </th>
            );
        }
        return (
            <th key={key} style={{
                padding: `0px !important`,
            }}>
                {header}
            </th>
        );
    });

    const handleChangeSelected = useCallback(
        (id: string | number) => {
            if (setSelected) {
                return setSelected({
                    ...selected,
                    [id]: !selected[id],

                });
            }
        },
        [selected]
    );

    const handleObjectSelected = useCallback(
        (item: P) => {
            if (setObjectSelected && objectsSelected) {
                const isItemInList = objectsSelected.some(selectedItem => selectedItem.id === item.id);
                const filteredList = objectsSelected.filter(selectedItem => selectedItem.id !== item.id);

                if (isItemInList) {
                    return setObjectSelected(filteredList);
                }

                setObjectSelected([...filteredList, item]);
            }
        },
        [objectsSelected, setObjectSelected]
    );

    const filterSelectedObjects = (item: P) => {
        if (objectsSelected) {
            const found = objectsSelected.some(selectedItem => selectedItem.id === item.id);

            return found;
        }
        return false;
    };

    return <DatagridView style={{
        backgroundColor: comparColors.primary.light,
        borderRadius: `20px`,
    }}>
        <div className='grid-wrapper'>
            <table className='grid-table' style={minWidthDefault ? { minWidth: `700px` } : {}}>
                {loading && <LinearProgress color="primary" />}
                <thead >
                    <tr>
                        {selectable && (
                            <th className='main-checkbox column-center'>
                                <Checkbox />
                            </th>
                        )}
                        {renderHeaders ? renderHeaders(headings) : renderHeader(headings)}
                        {createdAt && <th className={`grid-header`}>Created At</th>}
                        {deletable && <th className={`grid-header`}>Delete</th>}
                    </tr>
                </thead>
                {!loading && <tbody style={{ fontSize: `0.8em` }}>
                    {data?.map((item: P) => {
                        if (item?.id) {
                            return (
                                <tr
                                    key={item.id as unknown as string}
                                    className='table-row h-40px'
                                    onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                                        const target = event.target as HTMLInputElement;

                                        if (link && !(target.type === `checkbox`)) {
                                            history.push(`/${link}/${item.id}/`);
                                        }
                                    }}
                                >
                                    {selectable && (
                                        <td className='column-center'>
                                            <Checkbox
                                                checked={Boolean(selected[item.id])}
                                                onChange={() => {
                                                    handleChangeSelected(item.id);
                                                }}
                                                aria-label='checkbox'
                                            />
                                        </td>
                                    )}

                                    {objectSelection &&
                                        <td className='selectable column-center'>
                                            <Checkbox
                                                checked={filterSelectedObjects(item)}
                                                onChange={() => {
                                                    handleObjectSelected(item);
                                                }}
                                                aria-label='checkbox'
                                            />
                                        </td>
                                    }
                                    {renderRow ? renderRow(headings, item) : renderDefaultRows(headings, item)}
                                    {createdAt && (
                                        <td onClick={e => {
                                            e.stopPropagation();
                                        }}>
                                            {
                                                <div className='container-fluid'>
                                                    <Moment
                                                        className='row'
                                                        format='YYYY-MM-DD '
                                                        date={`${item.createdAt}`}
                                                    />
                                                    {/* FIXME: the two extra hours should be deleted when they are fixed on the server */}
                                                    <Moment
                                                        className='row'
                                                        format='HH:mm:ss'

                                                        date={`${item.createdAt}`}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    )}
                                </tr>
                            );
                        }
                    })}
                </tbody>}
            </table>
        </div>
    </DatagridView>;
};

function renderDefaultRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => (
        < td key={key} >
            {item[key]}
        </td >
    ));
}