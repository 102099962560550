import config from 'react-global-configuration';

import { IngredientCategory, IngredientSubCategory } from '../models';

export const getIngredientCategoryList = (): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category`, {
    mode: `cors`,
    credentials: `include`,
});

export const getIngredientCategoryById = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const createIngredientCategory = (payload: Partial<IngredientCategory>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const createIngredientSubCategory = (id: string, payload: Partial<IngredientSubCategory>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/${id}/append-sub-category`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const updateIngredientCategory = (id: string, payload: Partial<IngredientCategory>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deleteIngredientCategory = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const deleteIngredientSubCategory = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/sub-category/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const deleteIngredientCategoryFromIngredient = (productIngredientId: string, ingredientCategoryId: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/category/fromIngredient/${productIngredientId}/${ingredientCategoryId}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const deleteIngredientSubCategoryFromIngredient = (productIngredientId: string, ingredientSubCategoryId: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/sub-category/fromIngredient/${productIngredientId}/${ingredientSubCategoryId}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const appendIngredientCategoryToIngredient = (productIngredientId: string, ingredientCategoryId: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/category/toIngredient/${productIngredientId}/${ingredientCategoryId}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    }
});

export const appendIngredientSubCategoryToIngredient = (productIngredientId: string, ingredientSubCategoryId: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-category/sub-category/toIngredient/${productIngredientId}/${ingredientSubCategoryId}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    }
});