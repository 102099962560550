import { Button, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { HairFeatures, hairFeaturesOptions, HairFeaturesValueType } from '../../../constants';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { ModalCompar } from '../../../ui/components/Modal/ModalCompar';
import { enumToArray } from '../../../ui/helpers/enumToArray';

/*
 * Load images, then rester url with the close and do it with salesmen
 * add put functions
 */
type IProps = {
  button?: boolean
}

const CreateTagSchema = [
    Yup.object({
        name: Yup.string().required()
            .label(`name`),
    }),
];

type TTagPayload = {
  name: string | null
  category: string | null
  entity: string | null
  color: string | undefined
}
const inits: TTagPayload = {
    name: null,
    category: null,
    entity: null,
    color: undefined,
};

export const TagModal: React.FC<IProps> = ({ button }) => {
    const { id } = useParams<{ id: string}>();
    const { request } = useFetch();

    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const [initValues, setInitialData] = useState(inits);

    const [hairFeatureValues, setHairFeatureValues] = useState<HairFeaturesValueType[]>([]);

    const modalToggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (id) {
            //
        }
    }, [id]);

    useEffect(() => {
        console.log(initValues);
    }, [initValues]);

    return (
        <>
            {button && <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    history.push(`/${`tagIngredients`}`);
                    setIsOpen(true);
                }}
                children="Add Tag"/>
            }
            <ModalCompar open={isOpen} onClose={modalToggle} title={`Tag`}>
                <Grid>
                    <Formik
                        validationSchema={CreateTagSchema[0]}
                        initialValues={initValues}
                        enableReinitialize={true}
                        onSubmit={values => {
                            //
                        }}
                    >
                        {(props: FormikProps<TTagPayload>) => (
                            <Form onSubmit={props.handleSubmit} className='form' noValidate>
                                <SelectFieldFromArray<string>
                                    name='category'
                                    label='Category'
                                    onChange={(e: {target : { value: HairFeatures}} ) => {
                                        setHairFeatureValues(hairFeaturesOptions[e.target.value]);

                                        props.setFieldValue(`category`, e.target.value);
                                    }}
                                    options={Object.keys(hairFeaturesOptions)}
                                />

                                {hairFeatureValues.length > 0 && <SelectFieldFromArray<string>
                                    name='value'
                                    label='Value'
                                    options={hairFeatureValues}
                                />}
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </ModalCompar>
        </>
    );
};

const hairFeaturesArray = enumToArray(HairFeatures).map(hairFeature => hairFeature.value);