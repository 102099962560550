import { Auth } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';

/**
 * Customized hook for fetching data, this hook processes different errors by popping an alert and parses the response data to JSON
 * @returns {new () => AbortController} abort controller, instance that can cancel the current request
 * @returns {boolean} isLoading, is if true the request is been processed
 * @returns {number} status, the status of the response
 * @returns {Promise<T>} the fetch request
 */

export const useFetch = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState<undefined | number>(undefined);
    const abortController = new AbortController();

    const request = useCallback(async (apiCall, ...params) => {
        let tryTimes = 0;

        while (tryTimes < 1) {
            try {
                setLoading(true);

                const response = await apiCall(...params);

                setStatus(response.status);

                const jsonData = await response.json();

                return jsonData;
            } catch (err: unknown) {
                if (tryTimes === 0) {
                    await Auth.currentSession()
                        .then(data => {
                            console.log(data);
                        })
                        .catch(err => {
                            console.log(err);
                            // error(`Your session cannot be refreshed. Please log in again!, ${err}`, undefined);
                            history.push(`/logout`);
                        });
                } else if (err) {
                    if (err instanceof ValidationError) {
                        console.log(err);
                        // error(err.message || `Something goes wrong...`, undefined);
                    }
                }
                // FIXME: this should be aborted instead of calling a finally
            } finally {
                tryTimes++;
                setLoading(false);
            }
        }
    }, []);

    return {
        isLoading,
        status,
        request,
        abortController
    };
};
