import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Amplify } from 'aws-amplify';
import { FC, Suspense, useEffect } from 'react';
import config from 'react-global-configuration';
import { BrowserRouter } from 'react-router-dom';

import { I18nProvider } from '../_compar/i18n/i18nProvider';
import { LayoutSplashScreen } from '../_compar/layout/core';
import { Toaster } from '../ui/components/Toaster';
import configuration from './config';
import AuthInit from './pages/auth/AuthInit';
import { Routes } from './routing/Routes';
import { ScrollTopBlocker } from './routing/ScrollTopBlocker';

config.set(configuration);
Amplify.configure(configuration.Auth);

type Props = {
    basename: string
}

const disableSplashScreen = () => {
    const splashScreen = document.getElementById(`splash-screen`);

    if (splashScreen) {
        splashScreen.style.setProperty(`display`, `none`);
    }
};

export const App: FC<Props> = ({ basename }) => {
    useEffect(() => {
        disableSplashScreen();
    }, []);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
                {/* DON'T TOUCH THIS, BLOCKS AUTOMATIC TOP SCROLL FROM REACT ROUTER  */}
                <ScrollTopBlocker />
                <I18nProvider>
                    <AuthInit>
                        <Routes />
                        <Toaster />
                    </AuthInit>
                </I18nProvider>
            </BrowserRouter>
        </Suspense>
    );
};
