import { Grid } from '@mui/material';
import { Children, FC } from 'react';

import { FilterDropDown, TFilterProps } from '../../../ui/components/Filters/FilterDropDown';
import { PaginationFilter } from '../../../ui/components/Filters/PaginationFilter';
import { ResetFiltersButton } from '../../../ui/components/Filters/ResetFiltersButton';
import { SearchFilter } from '../../../ui/components/Filters/SearchFilter';

/**
 * Theme to wrap all dropdown with a fixed value of 100% of the container size
 * for this case on the component {@link Item }
 *
 * This is necessary because MuiTextField & MuiInputBase have two different size percentages by default
 */

type Props = {
    entity: string
    customFilterQueries?: TFilterProps[]
    showDateFilter?: boolean
    count?: number
    sx?: any
}
/**
 * Renders a component with custom filters for the search, which add the queries to the URL
 * which can be called from any other component to send a request to the API by just adding the query string
 * from location.search by using the hook useLocation()
 * @param {string} props.entity name of the entity that will be
 * @param {TFilterProps []} props.customFilterQueries the array should contain all the information needed to load the custom filter dropdowns
 * @param {number} props.totalValues all the values from the response
 * @param {boolean} props.showDateFilter if true, it will render Date Filter from and to
 * @param {boolean} props.showPagination if true, it will render a pagination column
 * @returns {JSX.Element} Customized Grid with all assign filters
 */
export const Filter: FC<Props> = ({
    count,
    customFilterQueries,
}) => {
    const totalAmountOfPages = count ? count / 10 : 0;

    return <>
        <Grid marginBottom={2}>
            <DropDownColumn>
                {/* Column to separate the dropdowns from the Reset Button */}
                {renderSearchFilter()}
                {customFilterQueries && renderCustomizedFilters(customFilterQueries)}
            </DropDownColumn>

        </Grid>
        <Grid>
            <PaginationColumn>
                <PaginationFilter maxPage={totalAmountOfPages} />
            </PaginationColumn>
        </Grid>
    </>;
};

const renderSearchFilter = () => (
    <Item>
        <SearchFilter entity='ingredient' />
    </Item>
);
// Maps and render the custom filters
const renderCustomizedFilters = (queries: TFilterProps[]) =>
    queries.map(props => (
        <Item>
            <FilterDropDown {...props} />
        </Item>
    ));

const PaginationColumn: FC = ({ children }) => (
    <Grid xs={12} container direction='row' justifyContent='flex-end' alignItems='center' marginBottom={1}>
        {children}
    </Grid>
);
// Item Grid
const Item: FC = ({ children }) => (
    <Grid xs={7} marginBottom={0.9} marginRight={1} marginLeft={4}>
        {children}
    </Grid>
);

const DropDownColumn: FC = ({ children }) => {
    const arrayChildren = Children.toArray(children);

    return (
        <Grid container xs={11} justifyContent={`center`}>
            {Children.map(arrayChildren, (child, index) => {
                if (arrayChildren.length === 1) {
                    return (
                        <>
                            {child}
                            <ResetFiltersButton />
                        </>
                    );
                }
                if (index === 2) {
                    return (
                        <>
                            {child}
                            <ResetFiltersButton />
                        </>
                    );
                }
                return <>{child}</>;
            })}
        </Grid>
    );
};