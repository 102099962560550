import Grid from '@mui/material/Grid';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { FC, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthTheme } from './theme';
import { ForgotPassword } from './views/ForgotPassword';
import { Login } from './views/Login';

export const AuthPage: FC = () => {
    useEffect(() => {
        document.body.classList.add(`bg-white`);
        return () => {
            document.body.classList.remove(`bg-white`);
        };
    }, []);

    return <Grid display="flex" flexDirection="column" flex="1 0 auto">
        <ThemeProvider theme={AuthTheme}>
            <Grid display="flex" alignItems="center" justifyContent="center" flexDirection="column" flex="1 0 auto">
                {/* <img alt='Logo' src={toAbsoluteUrl(`/media/logos/logo-1.svg`)} className='h-45px mb-12' /> */}
                <Grid container direction="column" alignItems="center" style={{ width: `40%` }}>
                    <Grid item style={{ width: `100%` }}>
                        <Switch>
                            <Route path='/auth/login' component={Login} />
                            <Route path='/forget-password' component={ForgotPassword} />
                            <Redirect to='/auth/login' />
                        </Switch>
                    </Grid>
                </Grid>
            </Grid>
            {/* {renderFooterLinks()} */}
        </ThemeProvider>
    </Grid>;
};