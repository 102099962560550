import config from 'react-global-configuration';

import { Retailer } from '../models';

export const getRetailerList = (): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/retail`, {
    mode: `cors`,
    credentials: `include`,
});

export const getRetailerById = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/retail/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const createRetailer = (payload: Partial<Retailer>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/retail`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const updateRetailer = (id: string, payload: Partial<Retailer>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/retail/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deleteRetailerById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/retail/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});