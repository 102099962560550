import config from 'react-global-configuration';

export const getProductCategoryById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productCategory/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getProductCategoryByName = (name: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productCategory/name/${name}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getProductCategoriesDistinctOrdered = (abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productCategory/distinct-ordered`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});