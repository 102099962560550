import { toast, ToastOptions } from 'react-toastify';

const COMMON_CLASSNAME = `p-5 rounded text-white`;

/**
 * Notification message that is called by the status response on request
 * @param {string } message message to display on notification
 * @param {ToastOptions} props  components props
 */
export function info(message: string | null | undefined, props: ToastOptions| undefined): React.ReactText {
    return toast.info(message, {
        ...props,
    });
}

export function error(message: string | null | undefined, props: ToastOptions | undefined): React.ReactText {
    return toast.error(message, {
        ...props,
    });
}

export function uploading(message: string | null | undefined, props: ToastOptions | undefined): React.ReactText {
    return toast.success(message, {
        ...props,
    });
}

export function warning(message: string | null | undefined, props: ToastOptions | undefined): React.ReactText {
    return toast.warning(message, {
        ...props,
    });
}

export function dark(message: string | null | undefined, props: ToastOptions | undefined): React.ReactText {
    return toast.warning(message, {
        ...props,
        className: `bg-dark ${COMMON_CLASSNAME}`,
    });
}

export function light(message: string | null | undefined, props: ToastOptions | undefined): React.ReactText {
    return toast.warning(message, {
        ...props,
    });
}
