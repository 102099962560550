import config from 'react-global-configuration';

import { HairFeature } from '../models';

export const getHairFeatureList = (filter: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/hair-feature${filter}`, {
    mode: `cors`,
    credentials: `include`,
});

export const getHairFeatureByValues = (feature: string, value: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/hair-feature/${feature}/${value}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getHairFeature = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/hair-feature/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const saveHairFeature = (hairFeature: Partial<HairFeature>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/hair-feature`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(hairFeature),
});