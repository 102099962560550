import { Alert, AlertTitle } from '@mui/material';
import { Auth } from 'aws-amplify';
import { Formik, FormikProps, FormikValues } from 'formik';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { forgotPasswordSchema, RequestPasswordRecoveryForm } from '../../../components/auth/RequestPasswordRecoveryForm';
import { VerifyNewPasswordForm, verifyNewPasswordSchema } from '../../../components/auth/VerifyNewPasswordForm';
import { getErrorMessage } from '../errorHandler';
import { ForgotPasswordProps } from '../interfaces';

const inits = {
    email: ``,
    newPassword: ``,
    verificationCode: `` };

export const ForgotPassword: FC = () => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [renderVerificationForm, setRenderVerificationForm] = useState(false);
    const [verificationCodeSent, setVerificationCodeSent] = useState(false);
    const history = useHistory();

    const sendVerificationCode = ({ email }: FormikValues) => {
        Auth.forgotPassword(email).then(() => {
            setVerificationCodeSent(true);
            setRenderVerificationForm(true);
        });
    };

    const confirmNewPassword = ({ email, verificationCode, newPassword }: FormikValues) => {
        setVerificationCodeSent(false);
        Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
            .then(() => {
                setError(undefined);
                history.push(`/auth/login`);
            })
            .catch(err => {
                setError(getErrorMessage(err.message));
            });
    };

    const submitForm = (values: FormikValues) => {
        setError(undefined);
        if (renderVerificationForm) {
            return confirmNewPassword(values);
        }
        return sendVerificationCode(values);
    };

    return <Formik
        validationSchema={renderVerificationForm ? verifyNewPasswordSchema : forgotPasswordSchema}
        onSubmit={submitForm}
        validateOnMount
        validateOnChange
        initialValues={inits} >
        {(props:FormikProps<ForgotPasswordProps>) => <div className='authentication-box'>

            {error && (
                <Alert severity="error" className='mb-5' style={{ alignItems: `center` }}>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            )}

            {verificationCodeSent && (
                <Alert severity="success" className='mb-5'>
                    <AlertTitle>Success</AlertTitle>
                            Verification code sent to your email.
                </Alert>
            )}
            {renderVerificationForm ? <VerifyNewPasswordForm {...props} verificationCode/> : <RequestPasswordRecoveryForm {...props} />}
        </div>}
    </Formik>;
};