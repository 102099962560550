const config = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: `eu-north-1`,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: `eu-north-1_D1KCanhff`,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: `5a8imuragf8fv9qhufb2bllnba`,

        mandatorySignIn: false,

        cookieStorage: {
            // REQUIRED - Cookie domain (only required if a cookieStorage is provided)
            domain: `compar.app`,
            /*
             *  OPTIONAL - Cookie path
             * path: '/',
             *  OPTIONAL - Cookie expiration in days
             */
            expires: 1,
            httpOnly: false,

            /*
             * OPTIONAL - Cookie secure flag
             * Either true or false, indicating if the cookie transmission requires a secure protocol (https).
             */
            secure: true,
            // CSRF
            sameSite: `none`,
        },
    },
    ApiUrl: {
        Rest: `https://api-dev.compar.app/auth`,
        PublicRest: `https://api-dev.compar.app`,
        WebSocket: `https://api-dev.compar.app`,
    },
    Branding: {
        LoginBackground: {
            'dev.compar.app': `/media/bg/compar-bg.jpg`,
        },
    },
};

export default config;
