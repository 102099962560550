import { useEffect, useRef } from 'react';

export function MasterInit():JSX.Element {
    const isFirstRun = useRef(true);
    const pluginsInitialization = () => {
        isFirstRun.current = false;
    };

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            pluginsInitialization();
        }
    }, []);

    return <></>;
}
