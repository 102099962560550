import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

import { useQueryParams } from '../../../setup/hooks/useQueryParams';
import { parseObjectKeyToReadableString } from '../../helpers/stringModifiers';
import { convertStringQueryToTextFieldReadableDate } from './helpers';

enum DateFilter {
    FROM = `from`,
    TO = `to`
}

type Props = {
    dateFilterType: DateFilter
}
/**
 * Returns a date n days ago in the format "YYYY-MM-DD"
 * @param {number} n - the number of days to subtract from the current date
 * @returns {string} - the date in the format "YYYY-MM-DD"
 */

export const dateFilterTypes:DateFilter[] = Object.values(DateFilter);

export const DatePickerFilter:FC<Props> = ({ dateFilterType }) => {
    const [urlQueries, setDateQuery] = useQueryParams();
    const convertValueToValidMuiString = () => {
        if(dateFilterType === DateFilter.FROM && urlQueries[`from`] === undefined) {
            return ``;
        }
        // The default value of to is an empty string
        if(dateFilterType === DateFilter.TO && urlQueries[`to`] === undefined) {
            return ``;
        }
        return convertStringQueryToTextFieldReadableDate(urlQueries[dateFilterType]);
    };

    const getDefaultValueIfTargetIsEmpty = (type:DateFilter) => {
        if(dateFilterType === DateFilter.FROM) {
            return setDateQuery(type, ``);
        }

        return setDateQuery(type, ``);
    };

    return <LocalizationProvider dateAdapter={AdapterDateFns}>

        <Stack spacing={0} component='form' className='d-flex'>
            <TextField
                label={parseObjectKeyToReadableString(dateFilterType)}
                type='date'
                value={convertValueToValidMuiString()}
                onChange={ ({ target }) => {
                    if(!target.value) {
                        // Will add default date if the the value is cleared
                        return getDefaultValueIfTargetIsEmpty(dateFilterType);
                    }

                    return setDateQuery(dateFilterType, target.value + defaultHourMinutesAndSeconds);
                }}
                InputLabelProps={{
                    shrink: true,

                }}
            />
        </Stack>
    </LocalizationProvider>;
};

const defaultHourMinutesAndSeconds = ` 00:00:00.0000`;