import { Box, Button, Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useState } from "react";
import * as Yup from 'yup';

import { HairFeatures, hairFeaturesOptions, HairFeaturesValueType } from "../../../constants";
import { getIngredient, recalculateIngredientMatches } from "../../../setup/api/ingredient";
import { removeTag, saveIngredientTag, SaveIngredientTagPayload } from "../../../setup/api/ingredientTag";
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { ProductIngredient, TagIngredient } from "../../../setup/models";
import { SelectFieldFromArray } from "../../../ui/components/FieldSelect/FieldSelect";
import { TagDisplay } from "../../../ui/components/TagManager/TagDisplay";
import { TitleHeading } from "../../../ui/components/Titles/Title";
import { parseObjectKeyToReadableString, parseReadableStringToObjectKeyName } from "../../../ui/helpers/stringModifiers";

export const TagToIngredientSchema = [
    Yup.object({
        tagName: Yup.string().required()
            .label(`tagName`),
        feature: Yup.string().required()
            .label(`feature`),
        value: Yup.string().required()
            .label(`value`),
    }),
];

export const TagIngredientForm: FC<{ ingredientInfo: ProductIngredient }> = ({ ingredientInfo }) => {
    const [tagPayload, setTagPayload] = useState<Partial<SaveIngredientTagPayload>>({});
    const [ingredientTags, setIngredientTags] = useState<TagIngredient[]>(ingredientInfo.tags || []);
    const [hairFeatureValues, setHairFeatureValues] = useState<HairFeaturesValueType[]>([]);

    const [tagsChanged, setTagsChanged] = useState<boolean>(true);
    const [calculationProgress, setCalculationProgress] = useState<string>(``);
    const [calculationError, setCalculationError] = useState<string>(``);

    const { request } = useFetch();

    const addTagToIngredient = (values: Partial<SaveIngredientTagPayload>) => {
        const newTag = createTagFromValues(values);

        const oldTags = ingredientTags;

        setIngredientTags([...ingredientTags, newTag]);

        const payload = { ingredientId: ingredientInfo.id, ...values };

        request(saveIngredientTag, payload)
            .then(result => {
                if (!result.id) {
                    setIngredientTags(oldTags);
                } else {
                    console.log(ingredientInfo.id, ` added`);
                    setTagsChanged(true);
                    setCalculationProgress(``);
                    setCalculationError(``);
                    setIngredientTags([...oldTags, result]);
                }
            })
            .catch(error => {
                refreshTags();
                console.log(error);
            });
    };

    function createTagFromValues(values: any): TagIngredient {
        const returnValue: TagIngredient = {
            id: ``,
            createdAt: new Date(),
            updatedAt: new Date(),
            deletedAt: null,
            tagName: values.tagName,
            hairFeature: {
                feature: values.feature,
                value: values.value,
                id: ``,
                createdAt: new Date(),
                updatedAt: new Date(),
                deletedAt: null
            }
        };

        return returnValue;
    }

    const recalculateMatch = async () => {
        setTagsChanged(false);
        setCalculationError(``);

        request(recalculateIngredientMatches, ingredientInfo.id)
            .then(result => {
                if (result.error) {
                    setCalculationProgress(`${result.message}`);
                    setCalculationError(`Error: ${result.error}`);
                } else {
                    setCalculationProgress(`Match calculation in progress for ${result.product} product(s)... this is estimated to take: ${result.estimatedTime}`);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deleteTag = (tagId: string) => {
        if (tagId) {
            const objWithIdIndex = ingredientTags.findIndex(obj => obj.id === tagId);

            ingredientTags.splice(objWithIdIndex, 1);

            request(removeTag, tagId)
                .then(tagId => {
                    setTagsChanged(true);
                    setCalculationProgress(``);
                    setCalculationError(``);
                    console.log(tagId, ` removed`);
                })
                .catch(error => {
                    refreshTags();
                    console.log(error);
                });
        }
    };

    const refreshTags = () => {
        request(getIngredient, ingredientInfo.id).then(ingredient => {
            setIngredientTags(ingredient.tags);
        });
    };

    return <Grid>
        <TitleHeading children="Mapped Hair Features" style={{
            fontSize: `1.2rem`,
            paddingBottom: `1em`
        }} />
        <TagDisplay tags={ingredientTags} deleteFunction={deleteTag} />
        <Box style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            width: `100%`,
        }}>
            <Formik
                validationSchema={TagToIngredientSchema[0]}
                initialValues={tagPayload}
                isInitialValid={false}
                enableReinitialize
                onSubmit={addTagToIngredient}>
                {(props: FormikProps<SaveIngredientTagPayload>) => (
                    <Form style={{
                        width: `100%`,
                    }}>
                        <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                            width: `100%`,
                        }} >
                            <Grid xs={5.7}>
                                <SelectFieldFromArray<string>
                                    name='feature'
                                    label='Hair Feature'
                                    keyName='feature'
                                    sx={{
                                        marginRight: `1em`,
                                        backgroundColor: `#fff`,
                                        borderRadius: `20px`,
                                        'fieldset': {
                                            border: `none`
                                        }

                                    }}
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}

                                    style={{
                                        backgroundColor: `#fff`,
                                    }}
                                    onChange={(e: { target: { value: HairFeatures } }) => {
                                        const joinedValue = parseReadableStringToObjectKeyName(e.target.value) as HairFeatures;

                                        setHairFeatureValues(hairFeaturesOptions[joinedValue]);

                                        props.setFieldValue(`feature`, joinedValue);
                                    }}
                                    options={Object.keys(hairFeaturesOptions).map((key: string) => parseObjectKeyToReadableString(key))}
                                />
                            </Grid>
                            <Grid xs={5.7}>
                                <SelectFieldFromArray<string>
                                    name='value'
                                    label='Hair Feature Value'
                                    sx={{
                                        marginRight: `1em`,
                                        backgroundColor: `#fff`,
                                        borderRadius: `20px`,
                                        'fieldset': {
                                            border: `none`
                                        }
                                    }}
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#fff`,
                                    }}
                                    options={hairFeatureValues}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <SelectFieldFromArray<string>
                                name='tagName'
                                label='Recommend/Prevent'
                                sx={{
                                    marginRight: `1em`,
                                    backgroundColor: `#fff`,
                                    borderRadius: `20px`,
                                    'fieldset': {
                                        border: `none`
                                    }
                                }}
                                inputLabelStyle={{
                                    backgroundColor: `transparent`,
                                }}
                                style={{
                                    backgroundColor: `#fff`,
                                }}
                                keyName='tagName'
                                onChange={(e: { target: { value: string } }) => {
                                    props.setFieldValue(`tagName`, e.target.value);
                                }}
                                options={[`recommend`, `prevent`]}
                            />
                            <Button type="button"
                                disabled={!props.isValid}
                                variant="contained"
                                color="primary"
                                children="Add Feature"
                                onClick={props.submitForm}
                                sx={{
                                    height: `3em`,
                                    width: `10em`,
                                    marginLeft: `1em`,
                                }} />
                        </Box>
                        <Button type="button"
                            disabled={!tagsChanged}
                            variant="contained"
                            color="primary"
                            children="Rerun match calculation on products with ingredient"
                            onClick={recalculateMatch}
                            sx={{
                                marginTop: `5em`
                            }}
                        />
                        <TitleHeading children={calculationProgress}
                            style={{
                                fontSize: `0.75rem`,
                                color: calculationProgress.includes(`Match calculation in progress for`) ? `green` : `black`,
                                marginTop: `1em`
                            }}
                        />
                        <TitleHeading children={calculationError}
                            style={{
                                fontSize: `0.75rem`,
                                color: `red`,
                                marginTop: `0.5em`
                            }}
                        />
                    </Form>)}
            </Formik>
        </Box>
    </Grid>;
};