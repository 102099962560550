import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { CSSProperties } from 'react';

import { comparColors } from '../../helpers/colors';

interface SelectFieldProps<T> extends FieldAttributes<any> {
    name: string;
    options: T[];
    keyName: string;
}

type NecessaryFields = {
    id?: string;
}

export const SelectFieldFromObjects = <P extends NecessaryFields>({ name, options, keyName, ...rest }: SelectFieldProps<P>) => <Field name={name}>
    {({ field }: FieldProps) => <FormControl fullWidth>
        <Select
            id={name}
            name={name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...rest}
        >
            {options.map(option => <MenuItem key={option.id} value={option.id} children={option[keyName as keyof P]} />)}
        </Select>
    </FormControl>}
</Field>;

interface SelectFieldProps1<T> extends FieldAttributes<any> {
    name: string;
    inputLabelStyle?: CSSProperties;
    options: T[];
    width?: string
}

export const SelectFieldFromArray = <P extends string | number>({ name, options, width, value, inputLabelStyle, ...rest }: SelectFieldProps1<P>) => (
    <Field name={name}
    >
        {({ field }: FieldProps) => (
            <FormControl fullWidth sx={{
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: comparColors.primary.main
                    },
                },
                '& .MuiOutlinedInput-root.Mui-error': {
                    '&.Mui-focused fieldset': {
                        borderColor: comparColors.primary.main,
                    },
                },
                width: width ? width : undefined
            }}>
                <InputLabel htmlFor={name} shrink style={{
                    fontFamily: `ProximaNova-Semibold`,
                    color: `#000`,
                    fontSize: `1.2rem`,
                    lineHeight: `normal`,
                    ...inputLabelStyle
                }}>
                    {rest.label}
                </InputLabel>
                <Select
                    style={{
                        minWidth: `100%`,
                        maxWidth: `100%`,
                        borderRadius: `20px`,
                        backgroundColor: comparColors.primary.light,
                        fontSize: `14px`,
                        fontFamily: `ProximaNova-Light`,
                        ...rest.style
                    }}
                    id={name}
                    name={name}
                    value={value ?? field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    {...rest}
                >
                    <MenuItem value='' style={{ display: `none` }} />
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option} children={option} style={{
                            fontFamily: `ProximaNova-Light`,
                            fontSize: `14px`,
                        }} />
                    ))}
                </Select>
            </FormControl>
        )}
    </Field >
);