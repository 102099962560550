export enum UserRole {
    ADMIN = `admin`,
    USER = `user`,
    EDITOR = `editor`
}

export enum HairFeatures {
    HAIR_TYPE = `hairType`,
    HAIR_POROSITY = `hairPorosity`,
    HAIR_WIDTH = `hairWidth`,
    HAIR_DENSITY = `hairDensity`,
    SCALP_TYPE = `scalpType`,
    SCALP_ISSUE = `scalpIssue`
}

export enum HairType {
    STRAIGHT = `straight`,
    WAVY = `wavy`,
    CURLY = `curly`,
    COILY = `coily`
}

export enum HairPorosity {
    LOW = `low`,
    MEDIUM = `medium`,
    HIGH = `high`
}

export enum HairWidth {
    FINE = `fine`,
    NORMAL = `normal`,
    COARSE = `coarse`
}

export enum HairDensity {
    LOW = `low`,
    MEDIUM = `medium`,
    HIGH = `high`
}

export enum ScalpType {
    DRY = `dry`,
    NORMAL = `normal`,
    OILY = `oily`
}

export enum ScalpIssue {
    DANDRUFF = `dandruff`,
    ITCHY = `itchy`,
    REDNESS = `redness`,
    SEBORRHEIC_DERMATITIS = `seborrheicDermatitis`,
    PSORIASIS = `psoriasis`,
    HAIRLOSS = `hairloss`
}

export enum ArticleCategory {
    HAIR_CARE = `HairCare`,
    HAIR_STYLING = `HairStyling`,
    HAIR_FEATURES = `HairFeatures`,
    DIY = `Diy`,
    INGREDIENTS = `Ingredients`,
}

export type HairFeaturesValueType = HairType | HairPorosity | HairWidth | HairDensity | ScalpType | ScalpIssue;

export const hairFeaturesOptions: {
    [key in HairFeatures]: HairFeaturesValueType[]
} = {
    [HairFeatures.HAIR_DENSITY]: [HairDensity.HIGH, HairDensity.MEDIUM, HairDensity.LOW],
    [HairFeatures.HAIR_POROSITY]: [HairPorosity.HIGH, HairPorosity.MEDIUM, HairPorosity.LOW],
    [HairFeatures.HAIR_TYPE]: [HairType.COILY, HairType.CURLY, HairType.STRAIGHT, HairType.WAVY],
    [HairFeatures.HAIR_WIDTH]: [HairWidth.COARSE, HairWidth.FINE, HairWidth.NORMAL],
    [HairFeatures.SCALP_TYPE]: [ScalpType.DRY, ScalpType.NORMAL, ScalpType.OILY],
    [HairFeatures.SCALP_ISSUE]: [
        ScalpIssue.DANDRUFF,
        ScalpIssue.HAIRLOSS,
        ScalpIssue.ITCHY,
        ScalpIssue.REDNESS,
        ScalpIssue.SEBORRHEIC_DERMATITIS,
        ScalpIssue.PSORIASIS]
};
