import config from 'react-global-configuration';

import { Article } from '../../global';

export const getArticleList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article${queries ? queries : `?page=1`}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getArticleById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const createArticle = (payload: Partial<Article>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const updateArticle = (id: string, payload: Partial<Article>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const uploadArticlePhoto = async (base64Image: string, articleId: string): Promise<Response> => {
    const formData = new FormData();

    const parts = base64Image.split(`;base64,`);
    const imageType = parts[0].split(`:`)[1];
    const base64Data = parts[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const imageBlob = new Blob([byteArray], { type: imageType });

    formData.append(`image`, imageBlob, `photo.jpg`);

    return fetch(`${config.get(`ApiUrl`).Rest}/article/image/${articleId}`, {
        method: `POST`,
        body: formData,
        mode: `cors`,
        credentials: `include`,
    });
};

export const deleteArticleById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});
