import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ChangeEvent, FC, useState } from "react";

import { addProductToEWGScraper } from '../../../setup/api/scrapers';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { TitleHeading } from '../../../ui/components/Titles/Title';

export const ScraperAdminPage: FC = () => {
    const [url, setUrl] = useState<string>();

    const { request, isLoading } = useFetchWithAlert(alertPayload(`Ingredient Added to EWG Scraper`));

    const handleAddIngredientFromEWG = () => {
        request(addProductToEWGScraper, url?.trim()).then(() => {
            setUrl(``);
        })
            .catch(() => {
                setUrl(``);
            });
    };

    return <Grid container xs={10} marginTop={3} sx={{
        display: `flex`,
        justifyContent: `space-evenly`,
        alignItems: `center`,
    }}>
        <Grid item xs={12} display={`flex`} margin={1} >
            <TitleHeading children="Add url of ingredient from ewg.org" style={{
                fontFamily: `ProximaNova-Semibold`,
                fontSize: `18px`,
            }}/>
        </Grid>
        <Grid item xs={8} >
            <TextField
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& legend": {
                            maxWidth: `0px`,
                            padding: `0px`,
                        },
                        "& .MuiInputLabel-root": {
                            fontFamily: `ProximaNova-Regular`,
                            fontSize: `14px`,
                            fontWeight: `normal`,
                            lineHeight: `16px`,
                        },
                        "& fieldset": {
                            borderRadius: `20px`,
                            border: `none`,
                            minWidth: `100%`,
                            maxWidth: `100%`,
                            fontFamily: `ProximaNova-Regular`,
                            boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.10)`, // Add the box shadow
                        },
                    },
                }}
                InputLabelProps={{
                    style: {
                        color: `#000`,
                        fontSize: `14px`,
                        fontFamily: `ProximaNova-Regular`,
                        backgroundColor: `#F7F5F3`,
                    },
                    shrink: false,
                }}
                style={{
                    minWidth: `100%`,
                    backgroundColor: `#F7F5F3`,
                }}
                placeholder="Fx https://www.ewg.org/skindeep/ingredients/700501-argania-spinosa-argan-kernel-oil/"
                value={url}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
            />
        </Grid>
        <Grid item xs={4}
            sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
            }}
        >
            <LoadingButton
                variant='contained'
                color='primary'
                loading={isLoading}
                disabled={startsWithURLPrefix(url)}
                onClick={() => handleAddIngredientFromEWG()}
                children="Add ingredient from EWG"
            />
        </Grid>
    </Grid>;
};

function startsWithURLPrefix(str: string | undefined): boolean {
    if(!str) {
        return true;
    }
    const urlPrefix = `https://www.ewg.org/skindeep/ingredients/`;
    const regex = new RegExp(`^${urlPrefix}`);

    return !regex.test(str);
}
