import { FC, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { LayoutSplashScreen } from '../../../_compar/layout/core';
import { getMe } from '../../../setup/api/auth';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import * as auth from '../../../setup/redux/auth/AuthRedux';
import { RootState } from '../../../setup/redux/RootReducer';

const mapState = (state: RootState) => ({ auth: state.auth });
const connector = connect(mapState, auth.actions);

type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = props => {
    const didRequest = useRef(false);
    const dispatch = useDispatch();
    const { request } = useFetch();
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual);

    // We should request user by authToken before rendering the application
    useEffect(() => {
        const requestUser = () => {
            if (!didRequest.current) {
                request(getMe).then(data => {
                    if (data) {
                        dispatch(props.fulfillUser(data.result));
                    } else {
                        dispatch(props.logout());
                    }
                    setShowSplashScreen(false);
                });
            }

            return () => (didRequest.current = true);
        };

        if (accessToken) {
            requestUser();
        } else {
            dispatch(props.logout());
            setShowSplashScreen(false);
        }
    }, []);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default connector(AuthInit);