import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { getIngredientCategoryList } from '../../../setup/api/ingredientCategory';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { RootState } from '../../../setup/redux/RootReducer';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const IngredientCategoryPage: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const [ingredientCategoryListAndCount, setIngredientCategoryListAndCount] = useState({
        categories: [],
        count: 0
    });

    const history = useHistory();

    const { request, isLoading, abortController } = useFetch();

    useEffect(() => {
        request(getIngredientCategoryList).then(results => {
            if (results) {
                const [categories, count] = results;

                console.log(results);

                setIngredientCategoryListAndCount({ categories, count });
            }
        });
        return () => {
            abortController.abort();
        };
    }, []);

    return <div className='mt-5 me-5' style={{ marginBottom: `2em` }}>
        <Grid container xs={12} marginTop={3}>
            <Grid xs={12} marginTop={2} marginBottom={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                {userRole === `admin` && <RenderCreateButton />}
            </Grid>
        </Grid>
        {
            ingredientCategoryListAndCount && <Datagrid
                data={ingredientCategoryListAndCount.categories}
                headings={ingredientsHeadings}
                link='ingredientCategory'
                loading={isLoading}
                renderRow={renderRows}
            />
        }
        {/* <IngredientModal /> */}
    </div >;
};

const RenderCreateButton: FC = () => <Link to='/ingredientCategory' style={{ textAlign: `center` }}>
    <Button
        color="primary"
        variant="contained"
        children="Create New Ingredient Category" />
</Link>;

export const ingredientsHeadings: Array<[string, string]> = [
    [`name`, `Name`],
    [`subCategories`, `Sub Categories`]
];

function renderRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => {
        if (key === `subCategories`) {
            const subCategories = item[key] as any[];

            return <td key={key}>{(subCategories.length)}</td>;
        }
        return <td key={key}>{item[key]}</td>;
    });
}