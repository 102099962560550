import { Box, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { comparColors } from '../../ui/helpers/colors';
import { MenuInner } from './components/MenuInner';
import { PageDataProvider } from './core/PageData';
import { MasterInit } from './MasterInit';

const mainTheme = createTheme({
    palette: {
        primary: {
            main: comparColors.primary.main,
        },
        secondary: {
            main: `#F7F5F3`,
            dark: `#Fff`
        }
    },
    typography: {
        button: {
            textTransform: `none`,
        },
        fontFamily: `ProximaNova-Regular`
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: `0.5em 0`
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: `20px`,
                    width: `100%`,
                    margin: `0.5em 0`
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: comparColors.primary.main,
                    }
                },
                checked: {
                    color: comparColors.primary.main,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.10)`, // Add the box shadow
                    borderRadius: `20px`,
                    fontFamily: `PlayfairDisplay-SemiBold`,
                    letterSpacing: `0.22px`,
                    fontSize: `13px`,
                    minWidth: `150px`,
                }
            }
        }
    },
});

export const MasterLayout: FC = ({ children }) => <PageDataProvider>
    <ThemeProvider theme={mainTheme}>
        <div className='p-0' style={{
            backgroundColor: comparColors.primary.light,
            height: `100vh`,
            borderRadius: `20px 20px 0px 0px`
        }}>
            <MenuInner />
            <PageWrapper>
                {children}
            </PageWrapper>
        </div>
        <MasterInit />
    </ThemeProvider>
</PageDataProvider>;

const PageWrapper:FC = ({ children }) => <Grid
    justifyContent={`center`}
    display={`flex`}
>
    <Box style={{
        backgroundColor: `#FFF`,
        borderRadius: `20px 20px 20px 20px`,
        padding: `2rem`,
        marginTop: `1rem`,
        marginBottom: `1rem`,
        width: `95%`,
        minHeight: `calc(100vh - 20vh)`
    }}>
        {children}
    </Box>
</Grid>;