import styled from '@emotion/styled';

export const DatagridView = styled.div`
  .grid-wrapper {
    overflow: auto;
    flex-grow: 1;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .main-checkbox {
    padding-bottom: 14px;
    padding-left: 4px;
  }

  .grid-table {
    width: 100%;
    color: #464e5f;
    background-color: transparent;
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
  }

  .grid-table thead th,
  .grid-table tbody td {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px; /* Add padding as needed */
  }

  .grid-table th:nth-child(1), td:nth-child(1) {
    width: 10%;
  }

  .grid-table th:nth-child(2), td:nth-child(2) {
    width: 10%;
  }

  .grid-table th:nth-child(3), td:nth-child(3) {
    width: 10%;
  }
  .grid-table th:nth-child(4), td:nth-child(4) {
    width: 10%;
  }
  .grid-table th:nth-child(5), td:nth-child(5) {
    width: 10%;
  }
  .grid-table th:nth-child(6), td:nth-child(6) {
    width: 10%;
  }
  .grid-table th:nth-child(7), td:nth-child(7) {
    width: 10%;
  }
  .grid-table th:nth-child(8), td:nth-child(8) {
    width: 10%;
  }
  .grid-table th:nth-child(9), td:nth-child(9) {
    width: 10%;
  }
  .grid-table th:nth-child(10), td:nth-child(10) {
    width: 10%;
  }

  .table-row:hover {
    background-color: #f3f6f9;
    cursor: pointer;
  }

  .table-row {
    font-family: "ProximaNova-Light";
  }

  .selectable {
    padding-left: 0.6rem;
    vertical-align: middle;

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }

  .selectable-header {
    padding: 0 0 0.5rem 0.6rem;

    .MuiCheckbox-root {
      padding: 5px 9px 9px;
    }
  }

  .grid-header {
    color: #000;
    font-family: "PlayfairDisplay-SemiBold";
    font-weight: 500;
    font-size: 14px;
    padding: 0 1.25rem 1rem;
    white-space: nowrap;
    text-align: left;
  }

  .grid-header:first-child {
    padding-left: 0;
  }

  
  .column-center {
    text-align: center;
  }

 
`;