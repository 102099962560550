import config from 'react-global-configuration';

import { Product } from '../../global';

export const getProductList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product${queries ? queries : `?page=1`}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getProductById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const updateProductById = (id: string, payload: Partial<Product>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const createProduct = (payload: Partial<Product>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deleteProductById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/product/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});