import { Grid } from '@mui/material';
import { Children, FC } from 'react';

import { parseObjectKeyToReadableString } from '../../helpers/stringModifiers';
import { FilterDropDown, TFilterProps } from '../Filters/FilterDropDown';
import { PaginationFilter } from '../Filters/PaginationFilter';
import { dateFilterTypes, DatePickerFilter } from './DateFilter';
import { ResetFiltersButton } from './ResetFiltersButton';
import { SearchFilter } from './SearchFilter';

/**
 * Theme to wrap all dropdown with a fixed value of 100% of the container size
 * for this case on the component {@link Item }
 *
 * This is necessary because MuiTextField & MuiInputBase have two different size percentages by default
 */

type Props = {
    entity: string
    lightTheme?: boolean
    customFilterQueries?: TFilterProps[]
    showPagination?: boolean
    showDateFilter?: boolean
    showSearchFilter?: boolean
    showCountryFilter?: boolean
    count?: number
    sx?: any
}
/**
 * Renders a component with custom filters for the search, which add the queries to the URL
 * which can be called from any other component to send a request to the API by just adding the query string
 * from location.search by using the hook useLocation()
 * @param {string} props.entity name of the entity that will be
 * @param {TFilterProps []} props.customFilterQueries the array should contain all the information needed to load the custom filter dropdowns
 * @param {number} props.totalValues all the values from the response
 * @param {boolean} props.showDateFilter if true, it will render Date Filter from and to
 * @param {boolean} props.showPagination if true, it will render a pagination column
 * @returns {JSX.Element} Customized Grid with all assign filters
 */
export const FilterComponent: FC<Props> = ({
    entity,
    count,
    lightTheme,
    showDateFilter,
    customFilterQueries,
    showPagination,
    showSearchFilter,
}) => {
    // const totalValuesToBeDisplayedOnList = {
    //     label: `Total ${parseObjectKeyToReadableString(entity)}: ${count} `,
    //     style: { fontWeight: `500`, fontSize: `1.075rem`, marginRight: `.5em` },
    // };
    const totalAmountOfPages = count ? count / 10 : 0;

    return <Grid container >
        <DropDownColumn light={lightTheme}>
            {/* Column to separate the dropdowns from the Reset Button */}
            {showSearchFilter && <Item>
                <SearchFilter light={lightTheme} entity={entity} />
            </Item>}
            {showDateFilter && renderToDateFilter()}
            {showDateFilter && renderFromDateFilter()}
            {customFilterQueries && renderCustomizedFilters(customFilterQueries)}
        </DropDownColumn>
        {showPagination && (
            <PaginationColumn>
                {<PaginationFilter maxPage={totalAmountOfPages} />}
                {/* <Chip {...totalValuesToBeDisplayedOnList} /> */}
            </PaginationColumn>
        )}
    </Grid>;
};

// Maps and render the custom filters
const renderCustomizedFilters = (queries: TFilterProps[]) =>
    queries.map(props => (
        <Item>
            <FilterDropDown {...props} />
        </Item>
    ));
const renderToDateFilter = () => (
    <Item>
        <DatePickerFilter dateFilterType={dateFilterTypes[0]} />
    </Item>
);
const renderFromDateFilter = () => (
    <Item>
        <DatePickerFilter dateFilterType={dateFilterTypes[1]} />
    </Item>
);

type DropDownColumnProps = {
    light?: boolean
}
const DropDownColumn: FC<DropDownColumnProps> = ({ children, light }) => {
    const arrayChildren = Children.toArray(children);

    return (
        <Grid container xs={8} style={{ position: `relative` }}>
            {Children.map(arrayChildren, (child, index) => {
                if (arrayChildren.length === 1) {
                    return (
                        <>
                            {child}
                            <ResetFiltersButton light={light} />
                        </>
                    );
                }
                if (index === 2) {
                    console.log(`light`, light);

                    return (
                        <>
                            {child}
                            <ResetFiltersButton light={light} />
                        </>
                    );
                }
                return <>{child}</>;
            })}
        </Grid>
    );
};
const PaginationColumn: FC = ({ children }) => (
    <Grid xs={4} container direction='row' justifyContent='flex-end' alignItems='center' marginBottom={1}>
        {children}
    </Grid>
);
// Item Grid
const Item: FC = ({ children }) => (
    <Grid xs={6} marginBottom={1} marginRight={1}>
        {children}
    </Grid>
);
