import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CSSProperties } from 'react';

import { comparColors } from '../../helpers/colors';

interface SelectFieldProps<T extends { id: string }> {
    name: string;
    label: string;
    options: T[];
    value: T | null; // Value passed from parent component
    inputLabelStyle?: CSSProperties;
    style?: CSSProperties;
    onChange: (value: T | null) => void; // Allow null values
    getOptionLabel: (option: T) => string; // Function to get the display label for an option
    width: string
}

const SelectField = <T extends { id: string }>({
    name,
    label,
    options,
    value,
    inputLabelStyle,
    style,
    onChange,
    getOptionLabel,
    width

}: SelectFieldProps<T>) => {
    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedOption = options.find(option => option.id === event.target.value);

        onChange(selectedOption || null);
    };

    return (
        <FormControl sx={{
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `none`,
                },
                '&.Mui-focused fieldset': {
                    borderColor: comparColors.primary.main,
                },
            },
            '& .MuiOutlinedInput-root.Mui-error': {
                '&.Mui-focused fieldset': {
                    borderColor: comparColors.primary.main,
                },
            },
            width: width
        }}>
            <InputLabel htmlFor={name} shrink style={{
                fontFamily: `ProximaNova-Semibold`,
                color: `#000`,
                fontSize: `1.2rem`,
                lineHeight: `normal`,
                ...inputLabelStyle
            }}>
                {label}
            </InputLabel>
            <Select
                style={{
                    minWidth: `100%`,
                    maxWidth: `100%`,
                    borderRadius: `20px`,
                    backgroundColor: comparColors.primary.light,
                    fontSize: `14px`,
                    fontFamily: `ProximaNova-Light`,
                    ...style
                }}
                id={name}
                name={name}
                value={value ? value.id : ``}
                onChange={handleChange}
            >
                <MenuItem value='' style={{ display: `none` }} />
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id} style={{
                        fontFamily: `ProximaNova-Light`,
                        fontSize: `14px`,
                    }}>
                        {getOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectField;
