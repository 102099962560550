import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { getIngredientList } from '../../../setup/api/ingredient';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { RootState } from '../../../setup/redux/RootReducer';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { IngredientModal } from './IngredientModal';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const IngredientPage: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const [ingredientListAndCount, setIngredientListAndCount] = useState({
        ingredients: [],
        count: 0
    });

    const history = useHistory();

    const ingredientQueryFromRouter = useLocation().search;

    const { request, isLoading, abortController } = useFetch();

    const [isChecked, setIsChecked] = useState(false);

    const handleShowOnlyTagged = (event: any) => {
        setIsChecked(event.target.checked);

        const queryParams = new URLSearchParams(history.location.search);

        if (event.target.checked === true) {
            // Add the onlyNotTagged parameter
            queryParams.set(`onlyNotTagged`, `true`);
        } else {
            // Remove the onlyNotTagged parameter
            queryParams.delete(`onlyNotTagged`);
        }

        // Update the URL with the new query parameters
        history.push({
            search: `?${queryParams.toString()}`
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(ingredientQueryFromRouter);

        if (queryParams.get(`onlyNotTagged`)) {
            setIsChecked(true);
        }

        request(getIngredientList, ingredientQueryFromRouter).then(results => {
            if (results) {
                const [ingredients, count] = results;

                setIngredientListAndCount({ ingredients, count });
            }
        });
        return () => {
            abortController.abort();
        };
    }, [ingredientQueryFromRouter, isChecked]);

    return <div className='mt-5 me-5' style={{ marginBottom: `2em` }}>
        <Grid container xs={12} marginTop={3}>
            <Grid xs={userRole === `admin` ? 10 : 12} marginTop={3}>
                <FilterComponent
                    count={ingredientListAndCount.count}
                    entity='ingredient'
                    showSearchFilter
                    showPagination
                />
            </Grid>
            <Grid xs={2} marginTop={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                {userRole === `admin` && <RenderCreateButton />}
            </Grid>
        </Grid>
        {
            ingredientListAndCount && <Datagrid
                data={ingredientListAndCount.ingredients}
                headings={ingredientsHeadings}
                link='ingredient'
                loading={isLoading}
                renderRow={renderRows}
            />
        }
        <Grid container justifyContent={`flex-end`} alignItems={`center`} >
            <Checkbox
                checked={isChecked}
                onChange={handleShowOnlyTagged}
                inputProps={{ 'aria-label': `controlled` }}
            />
            <TitleHeading children="Show only ingredients without tags" style={{
                fontSize: `1rem`
            }} />
        </Grid>
        <IngredientModal />
    </div >;
};

const RenderCreateButton: FC = () => <Link to='/addIngredient' style={{ textAlign: `center` }}>
    <Button
        color="primary"
        variant="contained"
        children="Create New Ingredient" />
</Link>;

export const ingredientsHeadings: Array<[string, string]> = [
    [`name`, `Name`],
    [`tags`, `Tags`],
    [`functions`, `Functions`],
    [`score`, `EWG Score`],
    [`naturalHairJourney`, `Natural Hair Journey`]
];

function renderTagsColumn(tags: { hairFeature: { feature: string; value: string }; tagName: string }[]) {
    const groupedTags: { [key: string]: { features: string[] } } = {};

    // Group tags by tagName
    tags.forEach(tag => {
        if (!groupedTags[tag.tagName]) {
            groupedTags[tag.tagName] = { features: [] };
        }
        groupedTags[tag.tagName].features.push(`${tag.hairFeature.feature}: ${tag.hairFeature.value}`);
    });

    return (
        <td>
            {Object.entries(groupedTags).map(([tagName, { features }], index) => (
                <span key={index}>
                    {`${tagName} = ${features.join(`, `)}`}
                    {index < Object.keys(groupedTags).length - 1 && `, `}
                </span>
            ))}
        </td>
    );
}

function renderRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => {
        if (key === `tags`) {
            return renderTagsColumn(item[key] as { hairFeature: { feature: string; value: string }; tagName: string }[]);
        }
        if (key === `naturalHairJourney`) {
            return (
                <Checkbox
                    checked={item[key] as boolean}
                    onChange={() => {
                        console.log(item.id);
                    }}
                    color="primary"
                />
            );
        }
        return <td key={key}>{item[key]}</td>;
    });
}