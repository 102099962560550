import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollTopBlocker(): null {
    const { pathname } = useLocation();

    useEffect(() => {
        const lastScrollPosition = window.pageYOffset;

        window.scrollTo(0, lastScrollPosition);
    }, [pathname]);

    return null;
}