import _ from "lodash";
import { FC, useEffect, useState } from "react";

import { useQueryParams } from "../../../setup/hooks/useQueryParams";
import { ComparTextField } from "../TextField/ComparTextField";
import { getQueryOrEmptyString } from "./helpers";

type Props = {
    light?: boolean;
    entity?: string;
}

export const SearchFilter: FC<Props> = ({
    light,
    entity
}) => {
    const [queries, setSearchQuery] = useQueryParams();
    const { search } = queries;
    const [value, setValue] = useState<string>();
    // Debounced search query callback
    // FIXME: use callback should be use on delayed query, currently not working as expected
    const delayedQuery = _.debounce(textInput => {
        setSearchQuery(`search`, textInput);
    }, 500);

    // Set the input value to the search query on mount
    useEffect(() => {
        setValue(search);
    }, []);

    // ComponentDidUpdate, only refresh when it cleans the query
    useEffect(() => {
        if (!search) {
            setValue(search);
        }
    }, [search]);

    const placeholderText = `Search ` + entity + ` by name`;

    return <ComparTextField
        hiddenLabel
        placeholder={placeholderText}
        InputLabelProps={{
            shrink: false,
        }}

        color={light ? `secondary` : `primary`}
        variant='outlined'
        value={getQueryOrEmptyString(value)}
        onChange={e => {
            setValue(e.target.value);
            delayedQuery(e.target.value);
        }}
    />;
};