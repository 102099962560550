import { Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';

import { Product } from '../../../global';
import { getPendingProductList, updatePendingProductById } from '../../../setup/api/pending-product';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { TitleHeading } from '../../../ui/components/Titles/Title';

type Heading = [string, string];
/**
 *  Check the user role and loads different dashboards depending on it
 */
export const PendingProductPage: FC = () => {
    const [pendingProductList, setPendingProductList] = useState<[]>();

    const [showHandled, setShowHandled] = useState<boolean>(false);

    const update = useFetchWithAlert(alertPayload(`Pending Product Updated`));

    const { request, isLoading, abortController } = useFetch();

    useEffect(() => {
        const query = `?showHandled=${showHandled}`;

        request(getPendingProductList, query, abortController.signal)
            .then(res => {
                if (res) {
                    setPendingProductList(res);
                }
            });
    }, [showHandled]);

    const handledChanged = async (pendingProductId: string, currentStatus: boolean) => {
        const payload = { handled: !currentStatus };

        update.request(updatePendingProductById, pendingProductId, payload, abortController.signal)
            .then(res => {
                console.log(res);
                // Assuming `res` contains the updated article or relevant data
                setPendingProductList((prevPendingProduct: any | undefined) => {
                    if (!prevPendingProduct) {
                        return [];
                    }

                    return prevPendingProduct.map((pendingProduct: { id: any; }) => {
                        if (pendingProduct.id === pendingProductId) {
                            return { ...pendingProduct, handled: !currentStatus };
                        }
                        return pendingProduct;
                    });
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <Grid>
            <Grid container justifyContent={`flex-start`} alignItems={`center`} >
                <Checkbox
                    checked={showHandled}
                    onChange={() => setShowHandled(!showHandled)}
                    inputProps={{ 'aria-label': `controlled` }}
                />
                <TitleHeading children="Show handled pending products" style={{
                    fontSize: `1rem`
                }} />
            </Grid>
            <br />
            {pendingProductList && pendingProductList.length > 0 ?
                (
                    <Datagrid<Product>
                        data={pendingProductList}
                        headings={productHeadings}
                        link='pending-product'
                        loading={isLoading}
                        renderRow={(headings: Heading[], item: Partial<Record<string, unknown>>) => renderRows(headings, item, handledChanged)}
                    />

                )
                :
                (
                    <TitleHeading children="There is no pending products at the moment" style={{ fontSize: `1rem`, paddingBottom: `1em` }} />
                )
            }
        </Grid>
    );
};

const productHeadings: Array<[string, string]> = [
    [`user`, `Submitted by`],
    [`mail`, `User mail`],
    [`imageUrl`, `Image`],
    [`barcode`, `Barcode`],
    [`handled`, `Handled?`],
];

function renderRows(
    headings: Heading[],
    item: Partial<Record<string, unknown>>,
    handledChanged: (pendingProductId: string, currentStatus: boolean) => void
) {
    return headings.map(([key]) => {
        if (key === `user`) {
            const user = item[key] as any;

            return (
                <td key={key}>
                    {user ? `${user?.firstName ?? ``} ${user?.lastName ?? ``}` : `No user`}
                </td>
            );
        }
        if (key === `mail`) {
            const user = item[`user`] as any;

            return (
                <td key={key}>
                    {user?.email ?? `No email`}
                </td>
            );
        }
        if (key === `imageUrl`) {
            return (
                <td key={key}>
                    <img
                        src={item[key] as string}
                        alt="Product"
                        style={{ width: `150px`, height: `auto`, borderRadius: 10 }}
                    />
                </td>
            );
        }
        if (key === `handled`) {
            return (
                <td key={key}>
                    <Checkbox
                        checked={item[key] as boolean}
                        onChange={() => handledChanged(item.id as string, item[key] as boolean)}
                        color="primary"
                    />
                </td>
            );
        }
        return <td key={key}>{item[key]}</td>;
    });
}