/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MasterLayout } from '../../_compar/layout/MasterLayout';
import { getMe } from '../../setup/api/auth';
import { useFetch } from '../../setup/hooks/fetch.hook';
import { actions } from '../../setup/redux/auth/AuthRedux';
import { RootState } from '../../setup/redux/RootReducer';
import { AuthPage } from '../pages/auth/AuthPage';
import { Logout } from '../pages/auth/views/Logout';
import { ErrorsPage } from '../pages/errors/ErrorsPage';
import { PrivateRoutes } from './PrivateRoutes';

const Routes: FC = () => {
    const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);
    const authenticationValid = useSelector((state: RootState) => state.auth.accessToken);

    const dispatch = useDispatch();
    const { request } = useFetch();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const requestUser = () => {
        dispatch(actions.showError(false));
        request(getMe).then(data => {
            if (data) {
                dispatch(actions.fulfillUser(data));
            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        requestUser();
    }, [authenticationValid]);

    if (isLoading) {
        return (
            <div></div>
        );
    }

    return <Switch>
        {!isAuthorized
            ? <Route><AuthPage /></Route>
            : <Redirect from='/auth' to='/' />
        }
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized
            ? <Redirect to='/auth/login' />
            : <MasterLayout>
                <PrivateRoutes />
            </MasterLayout>
        }
    </Switch>;
};

export { Routes };
