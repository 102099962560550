import config from 'react-global-configuration';

export const getIngredientList = (filter: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient${includeAllDataWithoutFilter(filter)}`, {
    mode: `cors`,
    credentials: `include`,
});

export const getIngredient = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const createNewIngredient = (payload: any) => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const createIngredient = (id: string, payload: any) => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const mergeIngredient = (payload: any) => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/merge-ingredients`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

type addSynonymToIngredientPayload = { id: string, synonyms: { name: string }[] }

export const addSynonymToIngredient = (payload: addSynonymToIngredientPayload): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/add-synonyms`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

const includeAllDataWithoutFilter = (filter: string | null) => {
    if (filter) {
        return filter + `&includeAllData=true`;
    }
    return `?includeAllData=true`;
};

export const getIngredientsByHairFeature = (filter: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/getIngredientByHairFeature${filter}`, {
    mode: `cors`,
    credentials: `include`,
});

export const addSynonymToIngredientByIngredientId = (ingredientId: string, synonym: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/add-synonym-to-ingredient/${ingredientId}`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },

    body: JSON.stringify({ synonym }),
});

export const removeSynonymFromIngredientBySynonymId = (synonymId: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/remove-synonym-from-ingredient/${synonymId}`, {
    mode: `cors`,
    credentials: `include`,
    method: `DELETE`
});

export const getIngredientByName = (name: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/getByName/${name}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const checkIngredientByName = (name: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/checkByName`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },

    body: JSON.stringify({ name }),
});

export const recalculateIngredientMatches = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/match-calculation/${id}`, {
    method: `GET`,
    mode: `cors`,
    credentials: `include`,
});

export const hardDeleteIngredientById = (ingredientId: string, queryString: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/hard/${ingredientId}${queryString}`, {
    mode: `cors`,
    credentials: `include`,
    method: `DELETE`
});

export const checkIfIngredientHasProducts = (ingredientId: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/productIngredient/is-in-use/delete-hard/${ingredientId}`, {
    mode: `cors`,
    credentials: `include`,
    method: `GET`
});