import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { useHistory } from "react-router-dom";

import { comparColors } from "../../helpers/colors";

/**
 * Renders a button that replaced all the queries from the url to an empty string
 */
type Props = {
    light?: boolean
}
export const ResetFiltersButton:FC<Props> = ({
    light
}) => {
    const history = useHistory();
    const removeQueriesFromFiltersAndRouter = () => {
        history.push({
            search: ``
        });
    };

    // It is necessary to wrap the button on a div so the position is absolute to the parent but relative to the grid
    return <Grid xs={1} marginBottom={1} marginRight={1} display={`flex`} alignItems={`center`}>
        <Button
            sx={{
                minWidth: `7em`,
                minHeight: `77%`,
                backgroundColor: light ? `#FFF` : comparColors.primary.light
            }}
            color="secondary"
            variant="contained"
            onClick={removeQueriesFromFiltersAndRouter}
            children="Reset"/>
    </Grid>;
};