import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Grid, styled } from '@mui/material';
import { Children, cloneElement, FC, useEffect, useReducer } from 'react';

import { useQueryParams } from '../../../setup/hooks/useQueryParams';
import { comparColors } from '../../helpers/colors';

const PaginationReducers = {
    PREVIOUS_PAGE: `PREVIOUS_PAGE`,
    FIRST_PAGE: `FIRST_PAGE`,
    NEXT_PAGE: `NEXT_PAGE`,
    LAST_PAGE: `LAST_PAGE`
} as const;

type ObjectValues<T> = T[keyof T];

// Inferring types from Const
type PaginationReducers = ObjectValues<typeof PaginationReducers>

type PaginationReducerState = {
    page: number;
    maxPage: number ;
}
type PaginationAction = {
    type :PaginationReducers
    payload :PaginationReducerState
}

const pageReducer = (state:PaginationReducerState, { type, payload }:PaginationAction) => {
    const { maxPage, page } = payload;

    switch (type) {
        case `PREVIOUS_PAGE`:
            return {
                ...state,
                page: page > 1 ? page - 1 : page,
            };

        case `FIRST_PAGE`:
            return { ...state,
                page: 1 };

        case `NEXT_PAGE`:
            return { ...state,
                page: maxPage > page ? page + 1 : page };

        case `LAST_PAGE`:
            return { ...state,
                page: maxPage };

        default:
            return state;
    }
};
const isThereAPageOnTheQueries = (page:string) => {
    if(Number(page) <= 1 ) {
        return true;
    }else if(!page) {
        return true;
    }
    return false;
};

type PaginationFilterProps = {
  maxPage: number
  disableLeftArrows ?: boolean
}
/**
 *  Renders pagination filters and total amount of items
 *
 * @param { number } props.maxPage  The maximum number of pages to display in the pagination.
 * @param {boolean} props.disableLeftArrow A flag indicating whether to disable the left arrows for navigating to the first  or previous page.
 */
export const PaginationFilter: FC<PaginationFilterProps> = ({ maxPage, disableLeftArrows = false }) => {
    const [queries, setQueries] = useQueryParams();
    const initialState = {
        page: queries.page ? Number(queries.page) : 1,
        maxPage: maxPage && Math.ceil(maxPage)
    };
    const [ state, dispatch] = useReducer(pageReducer, initialState);
    const dispatchOnAction = (type:PaginationReducers) => {
        dispatch({ type,
            payload: initialState });
    };

    const numberPageQuery = Number(queries.page);

    const pagesOnButton = [numberPageQuery + 1, numberPageQuery + 2, `.`, `.`, `.`];

    /*
     * Props.maxPage comes from an async function that re-renders the filter when is updated,
     * this ensures that the max page values is there, avoiding having to set the queries again
     */
    useEffect(() => {
        if(maxPage) {
            setQueries(`page`, state.page.toString());
        }
    }, [state.page]);

    return <Grid display={`flex`}>
        {!disableLeftArrows && <RenderPaginationButtonGroup isDisabled={isThereAPageOnTheQueries(queries.page)} >
            <KeyboardDoubleArrowLeftIcon
                color='primary'
                sx={{
                    fontSize: `1.2rem`,
                }}
                onClick={() => {
                    dispatchOnAction(`FIRST_PAGE`);
                }}
            />
            <KeyboardArrowLeftIcon
                color='primary'
                sx={{
                    fontSize: `1.2rem`,
                }}
                onClick={() => {
                    dispatchOnAction(`PREVIOUS_PAGE`);
                }}
            />
        </RenderPaginationButtonGroup>
        }
        <PageLink style={{
            marginRight: `0.3rem`,
        }} >{queries.page ? queries.page : 1}</PageLink>

        {Number(queries.page || 1) < maxPage && pagesOnButton.map((page:number | string, index: number) => {
            if(Number(queries.page) !== maxPage) {
                if(page === `.` || Number(page) < maxPage) {
                    return <PageLink key={index} >{page}</PageLink>;
                }
            }
        })}
        {Number(queries.page || 1) < maxPage && <PageLink

            onClick={() => {
                dispatchOnAction(`LAST_PAGE`);
            }}
            children={Math.ceil(maxPage)}
        />}
        <RenderPaginationButtonGroup >
            <KeyboardArrowRightIcon
                color='primary'
                sx={{
                    fontSize: `1.2rem`,
                }}
                onClick={() => {
                    dispatchOnAction(`NEXT_PAGE`);
                }}
            />
            <KeyboardDoubleArrowRightIcon
                color='primary'
                sx={{
                    fontSize: `1.2rem`,
                }}
                onClick={() => {
                    dispatchOnAction(`LAST_PAGE`);
                }}
            />
        </RenderPaginationButtonGroup>
    </Grid>;
};

type TRenderPaginationButtonGroup = {
    isDisabled?: boolean,
    children: JSX.Element[]
}
/**
 * Renders a group of pagination buttons.
 *
 * @param props.isDisabled - (optional) Disables the pagination button group if set to true.
 * @param props.children - An array of JSX elements to render as the pagination buttons.
 * @returns A JSX element representing the pagination button group.
 */
const RenderPaginationButtonGroup:FC<TRenderPaginationButtonGroup> = ({ isDisabled, children }) => <>
    {Children.map(children, (child:JSX.Element) => cloneElement(<PageLink style={{
        cursor: isDisabled ? `not-allowed` : `pointer`,
    }}>
        {child}
    </PageLink> as JSX.Element),
    )}
</>;

const PageLink = styled(`a`)(() => ({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: comparColors.primary.main,
    textDecoration: `none`,
    marginLeft: `0.2rem`,
    marginRight: `0.2rem`,
    fontSize: `.8rem`,
    "&:hover": {
        color: comparColors.primary.main,
    },
    '& .previous, & .next': {
        display: `block`,
    },
}));