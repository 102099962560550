import { Button, TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

export const verifyNewPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, `Minimum 8 symbols`)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%.,/_+^&*])(?=.{8,})/, `Password must contain at least 8 characters, one uppercase, one number and one special case character`)
        .max(50, `Maximum 50 symbols`)
        .required(`Password is required`),
    verificationCode: Yup.string()
        .min(3, `Minimum 3 symbols`)
        .max(10, `Maximum 10 symbols`)
        .required(`Verification code is required`)
});

interface VerifyPasswordProps extends FormikProps<ForgotPasswordProps> {
    verificationCode?: boolean;
}

export const VerifyNewPasswordForm: FC<VerifyPasswordProps> = ({ getFieldProps, touched, errors, verificationCode }) => <Form className='form' noValidate>
    <span className='authentication-title mb-5' style={{ marginBottom: `1em` }}>Please reset your password</span>
    {verificationCode && <TextField
        type='string'
        placeholder='Verification code'
        {...getFieldProps(`verificationCode`)}
        error={touched.verificationCode && Boolean(errors.verificationCode)}
        helperText={touched.verificationCode && errors.verificationCode}
        className="mb-5" />
    }
    <TextField
        type='password'
        placeholder='New password'
        {...getFieldProps(`newPassword`)}
        error={touched.newPassword && Boolean(errors.newPassword)}
        helperText={touched.newPassword && errors.newPassword}
        className='mb-10' />
    {verificationCode && <Button type='submit' variant="contained" color="primary" children="Verify"/>}
</Form>;