import BottomNavigation from '@mui/material/BottomNavigation';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FC, SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { UserRole } from '../../../constants';
import { RootState } from '../../../setup/redux/RootReducer';
import {
    ManiNavigationButton,
    StyledNavigationButton
} from '../../../ui/components/Buttons/NavigationButton';
import { comparColors } from '../../../ui/helpers/colors';

const editorEntities = [
    {
        value: `addHairFeatures`,
        label: `Map Hair Features`,
    },
    {
        value: `ingredients`,
        label: `Ingredients`,
    },
];

const adminEntities = [
    ...editorEntities,
    {
        value: `products`,
        label: `Products`
    },
    {
        value: `pending-products`,
        label: `Pending Products`
    },
    {
        value: `articles`,
        label: `Articles`
    },
    {
        value: `users`,
        label: `User Statistics`,
    },
    {
        value: `sendNotifications`,
        label: `Send Notifications`,
    },
    {
        value: `retailers`,
        label: `Retailers`,
    },
    {
        value: `ingredientCategories`,
        label: `Ingredient Category`,
    }
];

/**
 * Renders menu by user role
 */
export const MenuInner: FC = () => {
    const [value, setValue] = useState<string>();

    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);
    const history = useHistory();

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Grid
            justifyContent={`center`}
            display={`flex`}
            style={{
                backgroundColor: comparColors.primary.light,
                overflow: `hidden`,
                width: `100%`,
            }}
        >
            <Box
                style={{
                    borderRadius: `0.475rem`,
                    marginTop: `1rem`,
                    marginBottom: `1rem`,
                    width: `95%`,
                    backgroundColor: comparColors.primary.light,
                    overflow: `hidden`,
                }}
            >
                <BottomNavigation
                    style={{
                        backgroundColor: comparColors.primary.light,
                        justifyContent: `start`,
                        flexWrap: `wrap`,
                        height: `auto`,
                    }}
                    showLabels
                    value={value}
                    onChange={handleChange}
                >
                    <ManiNavigationButton
                        label={`compar`}
                        disableRipple
                        onSelect={() => {
                            history.push(`/`);
                        }}
                        style={{
                            minWidth: `250px`,
                        }}
                    />
                    {userRole === UserRole.EDITOR && LoadMenuByUserRole({ entities: editorEntities })}
                    {userRole === UserRole.ADMIN && LoadMenuByUserRole({ entities: adminEntities })}
                </BottomNavigation>
            </Box>
        </Grid>
    );
};

type IProps = {
    entities: {
        value: string;
        label: string;
    }[]
}
const LoadMenuByUserRole: FC<IProps> = ({ entities }) => {
    const location = useLocation();
    const history = useHistory();

    return <>
        {entities.map(({ label, value }) => {
            if (location.pathname === `/` + value) {
                return <StyledNavigationButton
                    label={label}
                    disableRipple
                    value={value}
                    sx={{
                        "& .MuiBottomNavigationAction-label": {
                            fontFamily: `PlayfairDisplay-Bold`,
                            fontSize: `1rem`,
                            "&:hover": {
                                textDecoration: `underline`
                            }
                        }
                    }}
                    style={{
                        minWidth: `250px`,
                        padding: `0px`,
                    }}
                    showLabel
                    onClick={() => history.push(`/${value}`)}
                />;
            }
            return <StyledNavigationButton
                label={label}
                disableRipple
                style={{
                    minWidth: `250px`,
                    padding: `0px`,
                }}
                value={value}
                showLabel
                onClick={() => history.push(`/${value}`)}
            />;
        })}
    </>;
};