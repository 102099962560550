import { Box, Button, Container } from '@mui/material';
import { FC } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

/**
 * Page with all errors from fetches 401 unauthorized, 404 not found and 500 internal server error
 */
export const ErrorsPage: FC = () => <Container sx={{ display: `flex`,
    flexDirection: `column`,
    flex: `1`,
    textAlign: `center`,
    pt: { xs: 10,
        lg: 20 },
    pb: 10 }}>
    {/* <img alt='Logo' src={toAbsoluteUrl(`/media/logos/logo-1.svg`)} style={{ height: `50px`,
        marginBottom: 15 }} /> */}
    <Box sx={{ flex: `1`,
        mb: 10 }}>
        <ErrorRouters />
        <Link to='/auth/me' style={{ textAlign: `center` }}>
            <Button variant='contained' style={{ marginRight: `1em` }} children="Go to homepage" />
        </Link>
    </Box>
    {/* <FooterLinks /> */}
</Container>;

const ErrorRouters: FC = () => <Switch>
    <Route path='/error/404' exact>
        {/* <ErrorComponent title="Page Not Found" message="The page you looked not found!" /> */}
    </Route>
    <Route path='/error/401' exact>
        {/* <ErrorComponent title="Permission Denied" message="You do not have permission to access this page." /> */}
    </Route>
    <Route path='/error/500' exact>
        {/* <ErrorComponent title='System Error' message="Something went wrong! Please try again later." /> */}
    </Route>
    <Redirect to='/error/500' />
</Switch>;