import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Checkbox, Chip, FormControlLabel, Grid, List, ListItem, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { Dispatch, FC, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";

import { UserRole } from '../../../constants';
import { addSynonymToIngredientByIngredientId, checkIfIngredientHasProducts, createIngredient, getIngredient, getIngredientList, hardDeleteIngredientById, mergeIngredient, removeSynonymFromIngredientBySynonymId } from "../../../setup/api/ingredient";
import { appendIngredientCategoryToIngredient, appendIngredientSubCategoryToIngredient, getIngredientCategoryList } from '../../../setup/api/ingredientCategory';
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { IngredientCategory, IngredientSubCategory, ProductIngredient, Synonyms } from "../../../setup/models";
import { RootState } from '../../../setup/redux/RootReducer';
import { Datagrid } from "../../../ui/components/DataGrid/DataGrid";
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { FilterComponent } from "../../../ui/components/Filters/FilterComponent";
import { SelectedIngredientCategoryTable } from '../../../ui/components/IngredientCategoryTable/IngredientCategoryTable';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { StringTableDisplay } from "../../../ui/components/StringTableDisplay/StringTableDisplay";
import { ComparLightChip } from '../../../ui/components/TagChips/ComparLightChip';
import { ComparTextField } from '../../../ui/components/TextField/ComparTextField';
import { TitleHeading } from "../../../ui/components/Titles/Title";
import { TagIngredientForm } from "../tag/TagToIngredientForm";

export const IngredientModal: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const [ingredient, setIngredient] = useState<Partial<ProductIngredient>>({});

    const [functions, setFunctions] = useState<string[]>([]);
    const [functionString, setFunctionString] = useState<string>();

    const [cancer, setCancer] = useState<string>(``);
    const [allergies, setAllergies] = useState<string>();
    const [developmental, setDevelopmental] = useState<string>();
    const [restrictions, setRestrictions] = useState<string>();
    const [selectedIngredientCategories, setSelectedIngredientCategories] = useState<IngredientCategory[]>([]);
    const [selectedIngredientSubCategories, setSelectedIngredientSubCategories] = useState<IngredientSubCategory[]>([]);
    const [ingredientCategories, setIngredientCategories] = useState<IngredientCategory[]>();
    const [ingredientSynonyms, setIngredientSynonyms] = useState<Synonyms[]>();

    const [selectedNewIngredientCategory, setSelectedNewIngredientCategory] = useState<IngredientCategory | null>(null);
    const [selectedNewIngredientSubCategory, setSelectedNewIngredientSubCategory] = useState<IngredientSubCategory | null>(null);

    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState(Boolean(id));

    const updateIngredient = useFetchWithAlert(alertPayload(`Ingredient updated`));
    const update = useFetchWithAlert(alertPayload(`Ingredient category added`));
    const deleted = useFetchWithAlert(alertPayload(`Ingredient Deleted `));

    const { request, abortController } = useFetch();
    const history = useHistory();

    const handleSubmit = (values: any) => {
        let score = null;
        let nhj = false;
        let concernsPost;

        if (values.score !== `` && values.score) {
            score = values.score;
        }

        if (values.naturalHairJourney) {
            nhj = values.naturalHairJourney;
        }

        if (score && cancer !== `` && allergies !== `` && developmental !== `` && restrictions !== ``) {
            concernsPost = [
                { "name": `Cancer`, "value": cancer },
                { "name": `Allergies & Immunotoxicity`, "value": allergies },
                { "name": `Developmental and Reproductive Toxicity`, "value": developmental },
                { "name": `Use Restrictions`, "value": restrictions }
            ];
        }
        let functionsPost;

        if (functions) {
            if (functions.length > 0) {
                functionsPost = functions;
            } else {
                functionsPost = null;
            }
        }

        const payload: any = {
            ...values,
            functions: functionsPost,
            concerns: concernsPost,
            score: score,
            naturalHairJourney: nhj,
        };

        updateIngredient.request(createIngredient, id, payload).then(() => {
            refreshIngredientInfo();
        });
    };

    const refreshIngredientInfo = () => {
        request(getIngredient, id).then(ingredient => {
            setIngredient(ingredient);
            if (ingredient.synonyms) {
                setIngredientSynonyms(ingredient.synonyms);
            }
            if (ingredient.concerns) {
                const cancer = ingredient.concerns.find((obj: { name: string; }) => obj.name === `Cancer`).value;
                const allergies = ingredient.concerns.find((obj: { name: string; }) => obj.name === `Allergies & Immunotoxicity`).value;
                const developmental = ingredient.concerns.find((obj: { name: string; }) => obj.name === `Developmental and Reproductive Toxicity`).value;
                const restrictions = ingredient.concerns.find((obj: { name: string; }) => obj.name === `Use Restrictions`).value;

                setCancer(cancer);
                setAllergies(allergies);
                setDevelopmental(developmental);
                setRestrictions(restrictions);
            }
            if (ingredient.functions) {
                setFunctions(ingredient.functions);
            }

            setSelectedIngredientCategories(ingredient.categories);
            setSelectedIngredientSubCategories(ingredient.subCategories);
        });
    };

    const appendFunction = () => {
        if (functionString) {
            if (functions) {
                const updatedFunctionArray = [...functions, functionString?.toUpperCase() || ``];

                setFunctions(updatedFunctionArray);
                setFunctionString(``);
            } else {
                const updatedFunctionArray = [functionString?.toUpperCase() || ``];

                setFunctions(updatedFunctionArray);
                setFunctionString(``);
            }
        }
    };

    const appendIngredientCategory = () => {
        if (selectedNewIngredientCategory) {
            setSelectedIngredientCategories(prevCategories => {
                if (!prevCategories.some(category => category.id === selectedNewIngredientCategory.id)) {
                    update.request(appendIngredientCategoryToIngredient, id, selectedNewIngredientCategory.id);
                    return [...prevCategories, selectedNewIngredientCategory];
                }
                return prevCategories;
            });
            if (selectedNewIngredientSubCategory) {
                setSelectedIngredientSubCategories(prevCategories => {
                    if (!prevCategories.some(subCategory => subCategory.id === selectedNewIngredientSubCategory.id)) {
                        request(appendIngredientSubCategoryToIngredient, id, selectedNewIngredientSubCategory.id);
                        return [...prevCategories, selectedNewIngredientSubCategory];
                    }
                    return prevCategories;
                });
            }
        }
        setSelectedNewIngredientCategory(null);
        setSelectedNewIngredientSubCategory(null);
    };

    const deleteProduct = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                request(checkIfIngredientHasProducts, id, abortController.signal)
                    .then(res => {
                        if (res.status === 409) {
                            const text = `${res.message} Do you want to preseed and remove the ingredient from the products?`;

                            if (confirm(text) === true) {
                                const queryString = `?deleteWithProducts=True`;

                                deleted.request(hardDeleteIngredientById, id, queryString, abortController.signal)
                                    .then(deleteIngredient => {
                                        if (deleteIngredient.status === 200) {
                                            history.replace(`/ingredients`);
                                        }
                                    });
                            }
                        } else if (res.status === 200) {
                            const queryString = `?`;

                            deleted.request(hardDeleteIngredientById, id, queryString, abortController.signal)
                                .then(deleteIngredient => {
                                    if (deleteIngredient.status === 200) {
                                        history.replace(`/ingredients`);
                                    }
                                });
                        }
                    });
            }
        }
    };

    useEffect(() => {
        request(getIngredientCategoryList).then(results => {
            if (results) {
                const [categories, count] = results;

                setIngredientCategories(categories);
            }
        });
        return () => {
            abortController.abort();
        };
    }, []);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
            refreshIngredientInfo();
        } else {
            setIsOpen(false);
            setIngredient({});
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [isOpen]);

    return <ModalCompar
        open={isOpen}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={ingredient}
            enableReinitialize
            onSubmit={handleSubmit}>
            {(props: FormikProps<Partial<ProductIngredient>>) => (
                <Form>
                    <Grid container justifyContent={`space-between`} >
                        <TagFormGrid item xs={5.9}>
                            <TitleHeading children="Ingredient information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <ComparTextField
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='string'
                                placeholder='Name'
                                label='Name'
                                InputLabelProps={{ shrink: true }}
                                {...props.getFieldProps(`name`)}
                            />
                            <ComparTextField
                                // disabled
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='number'
                                label='EWG Score'
                                InputLabelProps={{ shrink: true }}
                                placeholder='Score'
                                {...props.getFieldProps(`score`)}
                            />
                            {props.values.score && (
                                <ComparTextField
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    type='string'
                                    label='EWG link'
                                    InputLabelProps={{ shrink: true }}
                                    required={props.values.score !== undefined}
                                    placeholder='For example: /skindeep/ingredients/706945-water/'
                                    {...props.getFieldProps(`EWGLink`)}
                                />
                            )}
                            <ComparTextField
                                style={{
                                    backgroundColor: `#FFF`,
                                }}
                                type='string'
                                label='CosIng link'
                                InputLabelProps={{ shrink: true }}
                                placeholder='For example: /92472'
                                {...props.getFieldProps(`cosIngLink`)}
                            />
                            {props.values.score && (
                                <>
                                    <Grid xs={12} display={`flex`}>
                                        <TitleHeading children='EWG concerns' style={{
                                            fontSize: `1.1rem`,
                                        }} />
                                    </Grid><Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                        width: `100%`
                                    }}>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='cancer'
                                                label='Cancer'
                                                keyName='cancer'
                                                value={cancer}
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setCancer(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='allergies'
                                                label='Allergies & Immunotoxicity'
                                                keyName='allergies'
                                                value={allergies}
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setAllergies(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='developmental'
                                                label='Developmental and Reproductive Toxicity'
                                                keyName='developmental'
                                                value={developmental}
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setDevelopmental(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                        <Grid xs={2.7}>
                                            <SelectFieldFromArray<string>
                                                name='restrictions'
                                                label='Use Restrictions'
                                                keyName='restrictions'
                                                value={restrictions}
                                                required={props.values.score !== undefined}
                                                sx={{
                                                    marginRight: `1em`,
                                                    backgroundColor: `#000`,
                                                    borderRadius: `20px`,
                                                    'fieldset': {
                                                        border: `none`
                                                    }
                                                }}
                                                inputLabelStyle={{
                                                    backgroundColor: `transparent`,
                                                }}

                                                style={{
                                                    backgroundColor: `#fff`,
                                                }}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setRestrictions(e.target.value);
                                                }}
                                                options={[`LOW`, `MODERATE`, `HIGH`]} />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid xs={12} display={`flex`}>
                                <TitleHeading children='Functions' style={{
                                    fontSize: `1.1rem`,
                                }} />
                            </Grid>
                            <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                width: `100%`
                            }}>
                                <Grid xs={6} display="flex" alignItems="center" justifyContent="space-between">
                                    <ComparTextField
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                        type='string'
                                        label='Function'
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Function'
                                        value={functionString}
                                        onChange={e => setFunctionString(e.target.value)}
                                    />
                                    <Button type="button"
                                        disabled={!functionString}
                                        variant="contained"
                                        color="primary"
                                        children="Add Function"
                                        onClick={appendFunction}
                                        sx={{
                                            height: `3em`,
                                            width: `10em`,
                                            marginLeft: `1em`,
                                        }} />
                                </Grid>
                            </Grid>
                            {functions && <Grid>
                                <StringTableDisplay
                                    data={functions}
                                    objectsSelected={functions}
                                    setObjectSelected={setFunctions}
                                    titleText='Selected Functions'
                                />
                            </Grid>}
                            <Grid xs={12} display={`flex`} justifyContent={`space-between`} marginTop={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...props.getFieldProps(`naturalHairJourney`)}
                                            checked={props.values.naturalHairJourney || false}
                                            onChange={props.handleChange}
                                            value={props.values.naturalHairJourney || false}
                                            color="primary"
                                        />
                                    }
                                    label="Natural Hair Journey"
                                />
                                <LoadingButton
                                    loading={updateIngredient.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end` }}
                                    children="Update info" />
                            </Grid>
                            <Grid xs={12} display={`flex`} justifyContent={`flex-end`} marginTop={2}>
                                <Button
                                    component="label"
                                    children="Delete ingredient"
                                    style={{ flex: `end`, marginTop: `1.2em` }}
                                    onClick={deleteProduct}
                                />
                            </Grid>
                        </TagFormGrid>
                        <TagFormGrid item xs={5.9}>
                            {ingredient.id && <TagIngredientForm ingredientInfo={ingredient as ProductIngredient} />}
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
        {ingredient.id &&
            <Grid container justifyContent={`space-between`} marginTop={5}>
                <TagFormGrid item xs={12}>
                    <TitleHeading children="Ingredient categories" style={{
                        fontSize: `1.2rem`,
                    }} />
                    <div style={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `flex-start`,
                        marginTop: `1em`,
                        marginBottom: `1.5em`
                    }}>
                        <Grid xs={5}>
                            <SelectedIngredientCategoryTable
                                data={selectedIngredientCategories}
                                ingredientId={ingredient.id}
                                objectsSelected={selectedIngredientCategories}
                                setObjectSelected={setSelectedIngredientCategories}
                                objectsSubSelected={selectedIngredientSubCategories}
                                setObjectSubSelected={setSelectedIngredientSubCategories}
                                titleText='Selected Ingredient Categories'
                                subCategory={false}
                            />
                        </Grid>
                        {selectedIngredientCategories.length > 0 &&
                            <Grid xs={5}>
                                <SelectedIngredientCategoryTable
                                    data={selectedIngredientSubCategories}
                                    ingredientId={ingredient.id}
                                    objectsSelected={selectedIngredientSubCategories}
                                    setObjectSelected={setSelectedIngredientSubCategories}
                                    titleText='Selected Ingredient Sub Categories'
                                    subCategory={true}
                                />
                            </Grid>
                        }
                    </div>
                    <div style={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `flex-start`,
                        marginTop: `1em`,
                        marginBottom: `1.5em`
                    }}>
                        {ingredientCategories &&
                            <Grid xs={5}>
                                <SelectField
                                    name='feature'
                                    label='New category'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#fff`,
                                        border: `none`
                                    }}
                                    value={selectedNewIngredientCategory}
                                    onChange={value => {
                                        setSelectedNewIngredientCategory(value);
                                        setSelectedNewIngredientSubCategory(null);
                                    }}
                                    options={ingredientCategories}
                                    getOptionLabel={option => option.name}
                                    width={`100%`}
                                />
                            </Grid>
                        }
                        {selectedNewIngredientCategory && selectedNewIngredientCategory.subCategories.length > 0 &&
                            <Grid xs={5} sx={{ marginLeft: `1.5em` }}>
                                <SelectField
                                    name='feature'
                                    label='Add sub category'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#fff`,
                                        border: `none`
                                    }}
                                    value={selectedNewIngredientSubCategory}
                                    onChange={value => {
                                        setSelectedNewIngredientSubCategory(value);
                                    }}
                                    options={selectedNewIngredientCategory.subCategories}
                                    getOptionLabel={option => option.name}
                                    width={`100%`}
                                />
                            </Grid>
                        }
                        <Button type="button"
                            disabled={!selectedNewIngredientCategory}
                            variant="contained"
                            color="primary"
                            children={`Add ${selectedNewIngredientCategory ? selectedNewIngredientCategory.name : ``}${selectedNewIngredientSubCategory ? `+ ${selectedNewIngredientSubCategory.name}` : ``}`}
                            onClick={appendIngredientCategory}
                            sx={{
                                height: `3em`,
                                alignSelf: `center`,
                                marginLeft: `1.5em`
                            }} />
                    </div>
                </TagFormGrid>
            </Grid>
        }
        {userRole === UserRole.ADMIN &&
            <Grid container justifyContent={`space-between`} marginTop={5}>

                <TagFormGrid item xs={5.9} >
                    <TitleHeading children="Synonyms"
                        style={{
                            marginLeft: `0.6em`,
                            fontSize: `1.2rem`,
                        }}
                    />
                    {ingredient.synonyms && <SynonymList synonyms={ingredientSynonyms} setSynonyms={setIngredientSynonyms} />}
                </TagFormGrid>
                <TagFormGrid item xs={5.9} >
                    <MergeIngredient parentIngredient={ingredient} />
                </TagFormGrid>
            </Grid>}
    </ModalCompar>;
};

const SynonymList: FC<{ synonyms: Synonyms[] | undefined, setSynonyms: Dispatch<React.SetStateAction<Synonyms[] | undefined>>; }> = ({ synonyms, setSynonyms }) => {
    const { request, isLoading } = useFetch();
    const [newSynonym, setNewSynonym] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const { id } = useParams<{ id: string }>();

    const handleAddSynonymRequest = () => {
        request(addSynonymToIngredientByIngredientId, id, newSynonym)
            .then(res => {
                if (res.message === `Synonym already exists`) {
                    setErrorMessage(`Synonym ${newSynonym} already exists on ${res.existsOn.name}`);
                    return;
                }
                const newSynonymResult = res;
                const updatedBarcodes = synonyms ? [...synonyms, newSynonymResult] : [newSynonymResult];

                setSynonyms(updatedBarcodes);
                setNewSynonym(``);
            });
    };

    const handleRemoveSynonym = (synonymId: string) => {
        request(removeSynonymFromIngredientBySynonymId, synonymId)
            .then(res => {
                if (synonyms && res.affected > 0) {
                    const updatedSynonyms = synonyms.filter(synonym => synonym.id !== synonymId);

                    // Update the state with the filtered list
                    setSynonyms(updatedSynonyms);
                }
            });
    };

    return <List>
        {synonyms && synonyms.map((synonym, index) => (
            <ListItem key={index}>
                <ComparLightChip
                    style={{ fontSize: `13px` }}
                    onDelete={() => handleRemoveSynonym(synonym.id)}
                    deleteIcon={<HighlightOffIcon color="inherit" />}
                    label={synonym.name}
                    variant="filled"
                />
            </ListItem>
        ))}

        <ComparTextField
            type='text'
            placeholder='Synonym'
            label='New Synonym'
            InputLabelProps={{ shrink: true }}
            style={{
                backgroundColor: `#FFF`,
            }}
            value={newSynonym}
            onChange={e => {
                setErrorMessage(``);
                setNewSynonym(e.target.value);
            }}
        />
        <LoadingButton type="button" style={{ marginTop: `0.8em` }}
            children="Add Synonym"
            variant='contained'
            color='primary'
            onClick={handleAddSynonymRequest}
            disabled={!newSynonym}
            loading={isLoading} />
        {errorMessage && <p style={{ color: `red` }}>{errorMessage}</p>}
    </List>;
};

const MergeIngredient: FC<{ parentIngredient: Partial<ProductIngredient> }> = ({ parentIngredient }) => {
    const [selectedIngredient, setSelectedIngredient] = useState<Record<string, unknown>>({});
    const [ingredientListAndCount, setIngredientListAndCount] = useState({
        ingredients: [],
        count: 0
    });

    const { id } = useParams<{ id: string }>();

    const [ingredientDetails, setIngredientDetails] = useState<Record<string, unknown>>({});

    const { request } = useFetch();

    const postFetch = useFetchWithAlert(alertPayload(`Ingredient merged `));
    const queriesFromRouter = useLocation().search;
    const getIngredientById = (id: string) => {
        request(getIngredient, id).then(ingredient => {
            setIngredientDetails(ingredient);
        });
    };

    const getIngredients = () => {
        request(getIngredientList, queriesFromRouter).then(results => {
            if (results) {
                const [ingredients, count] = results;

                const filteredIngredients = ingredients.filter((ingredient: ProductIngredient) => ingredient.id !== parentIngredient.id);

                setIngredientListAndCount({
                    ingredients: filteredIngredients,
                    count
                });
            }
        });
    };

    const mergeIngredientWithAnotherOne = () => {
        postFetch.request(mergeIngredient, {
            id,
            ingredientToMergeWith: ingredientDetails.id
        }).then(() => {
            getIngredients();
            getIngredientById(id);
        });
    };

    useEffect(() => {
        getIngredients();
    }, [queriesFromRouter]);

    useEffect(() => {
        if (Object.keys(selectedIngredient).length > 0) {
            getIngredientById(Object.keys(selectedIngredient)[0]);
        }
    }, [selectedIngredient]);

    useEffect(() => {
        if (ingredientDetails.id) {
            setSelectedIngredient({});
        }
    }, [ingredientDetails]);
    return <>
        <Box style={{
            display: `flex`,
        }}>
            <TitleHeading children="Merge other ingredients into current ingredient" style={{
                fontSize: `1.2rem`
            }} />
            <Chip label={ingredientDetails.name} sx={{
                fontSize: `1rem`,
                fontWeight: `300`,
                height: `2em`,
                // backgroundColor: colors.green[500],
                marginLeft: `1em`,
                marginRight: `1em`
            }} />
        </Box>
        <Grid xs={12} marginTop={3}>
            <FilterComponent showSearchFilter lightTheme showPagination entity={`Ingredients`} count={ingredientListAndCount.count} />
        </Grid>
        <Datagrid
            data={ingredientListAndCount.ingredients}
            selectable={true}
            setSelected={setSelectedIngredient}
            selected={selectedIngredient}
            headings={[[`name`, `Name`]]}
            renderRow={renderTestRowsUsers}
        />
        <Grid display={`flex`} justifyContent={`flex-end`}>
            {ingredientDetails?.id && <Button onClick={() => mergeIngredientWithAnotherOne()}
                variant="contained"
                color="primary"
                children="Merge Selected Ingredient" />}
        </Grid>
    </>;
};

function renderTestRowsUsers(headings: [Array<string>], item: Record<string, string>) {
    return headings.map(([key]) => (
        <td key={key} className='listHover'>
            {item[key]}
        </td>
    ));
}

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));