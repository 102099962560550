import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Auth } from 'aws-amplify';
import { Formik, FormikProps, FormikValues } from 'formik';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getMe } from '../../../../setup/api/auth';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { actions } from '../../../../setup/redux/auth/AuthRedux';
import * as auth from '../../../../setup/redux/auth/AuthRedux';
import { RootState } from '../../../../setup/redux/RootReducer';
import { LoginForm } from '../../../components/auth/LoginForm';
import { VerifyNewPasswordForm } from '../../../components/auth/VerifyNewPasswordForm';
import { getErrorMessage } from '../errorHandler';
import { AWSSignInResponse } from '../interfaces';

export const LoginSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, `Minimum 8 symbols `),
    email: Yup.string()
        .email(`Wrong email format`)
        .min(3, `Minimum 3 symbols`)
        .max(100, `Maximum 100 symbols`)
});

export const Login: FC = () => {
    const dispatch = useDispatch();
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [userDataToResetPassword, setUserDataToResetPassword] = useState<AWSSignInResponse | false>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const authenticationValid = useSelector((state: RootState) => state.auth.accessToken);
    const { request } = useFetch();

    const requestUser = () => {
        dispatch(actions.showError(false));
        setError(undefined);
        request(getMe).then(data => {
            console.log(data);
            if (data) {
                setIsSignedIn(true);
                dispatch(actions.fulfillUser(data));
            } else if (!data) {
                setError(data);
            }
        });
    };
    const handleAuthStateChange = () => {
        Auth.currentUserInfo().then(userInfo => {
            if (userInfo && userInfo.attributes) {
                // Require check as this bounces on logout
                Auth.currentSession().then(data => {
                    const accessToken = data.getAccessToken().getJwtToken();
                    const refreshToken = data.getRefreshToken().getToken();

                    dispatch(auth.actions.login(accessToken, refreshToken));
                });
            }
        });
    };
    const verifyPassword = (user: AWSSignInResponse, values: FormikValues) => {
        Auth.completeNewPassword(
            // The Cognito User Object
            user,
            // The new password
            values.newPassword,
        )
            .then(() => handleAuthStateChange())
            .catch(error => {
                setIsLoading(false);
                setError(getErrorMessage(error.message));
            });
    };

    const handleSignIn = (values: FormikValues) => {
        setIsLoading(true);

        Auth.signIn(values.email.trim(), values.password)
            .then(user => {
                if (user.challengeName === `NEW_PASSWORD_REQUIRED`) {
                    setIsLoading(false);
                    return setUserDataToResetPassword(user);
                }

                // Redirect to dashboard or protected page

                return handleAuthStateChange();
            })
            .catch(error => {
                setIsLoading(false);
                setError(getErrorMessage(error.message));
            });
    };

    useEffect(() => {
        if (authenticationValid && isSignedIn !== true) {
            requestUser();
        }
    }, [authenticationValid]);

    useEffect(() => {
        if (error) {
            dispatch(actions.showError(true));
        }
    }, [error]);

    useEffect(() => {
        if (userDataToResetPassword) {
            dispatch(actions.showError(false));
            setError(undefined);
        }
    }, [userDataToResetPassword]);

    return <div className='authentication-box'>
        {error && (
            <Alert severity="error" className='my-5' style={{ alignItems: `center` }}>
                {error}
            </Alert>
        )}
        <Formik
            validationSchema={LoginSchema}
            onSubmit={values => {
                setIsLoading(true);
                if (userDataToResetPassword) {
                    return verifyPassword(userDataToResetPassword, values);
                }
                handleSignIn(values);
            }}
            validateOnMount
            validateOnChange
            initialValues={{
                autofocus: true,
                email: ``,
                password: ``,
            }}>
            {(props: FormikProps<any>) => <>
                {userDataToResetPassword
                    ? <VerifyNewPasswordForm {...props} />
                    : <LoginForm {...props} />
                }
                <SignInButton
                    isLoading={isLoading}
                    text={userDataToResetPassword ? `Reset password and sign in` : `Sign In`}
                    submitForm={props.handleSubmit} />
            </>}
        </Formik>
    </div>;
};

type SignInButtonProps = { isLoading: boolean, text: string, submitForm: (e?: FormEvent<HTMLFormElement> | undefined) => void }

const SignInButton: FC<SignInButtonProps> = ({ isLoading, text, submitForm, }) => <Grid item xs={12}>
    {isLoading
        ? <LinearProgress color="inherit" />
        : <Button
            variant="contained"
            disabled={false}
            fullWidth
            color="primary"
            sx={{ minWidth: `100%` }}
            style={{
                marginTop: `0.75rem`,
                width: `100%`,
                marginBottom: `0.75rem`,
            }}
            type="submit"
            onClick={() => submitForm()}
            onKeyDown={e => {
                if (e.key === `Enter`) {
                    submitForm();
                }
            }}
        >
            {text}
        </Button>}
</Grid>;

