import { Button, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Product } from '../../../global';
import { getProductList } from '../../../setup/api/product';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { TitleHeading } from '../../../ui/components/Titles/Title';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const ProductPage: FC = () => {
    const history = useHistory();

    const [productCount, setProductCount] = useState(0);
    const [productList, setProductList] = useState();
    const [isChecked, setIsChecked] = useState(false);

    const { request, isLoading, abortController } = useFetch();

    const queriesFromRouter = useLocation().search;

    const handleShowOnlyTagged = (event: any) => {
        setIsChecked(event.target.checked);

        const queryParams = new URLSearchParams(history.location.search);

        if (event.target.checked === true) {
            // Add the onlyNotTagged parameter
            queryParams.set(`onlyNotTagged`, `true`);
        } else {
            // Remove the onlyNotTagged parameter
            queryParams.delete(`onlyNotTagged`);
        }

        // Update the URL with the new query parameters
        history.push({
            search: `?${queryParams.toString()}`
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(queriesFromRouter);

        if (queryParams.get(`onlyNotTagged`)) {
            setIsChecked(true);
        }

        request(getProductList, queriesFromRouter, abortController.signal).then(products => {
            if (products) {
                setProductList(products.results);
                setProductCount(products.totalPages * 10);
            }
        });
    }, [queriesFromRouter]);

    return <Grid>
        <Grid container xs={12} marginTop={3}>
            <Grid xs={10} marginTop={3}>
                <FilterComponent
                    count={productCount}
                    entity='product'
                    showSearchFilter
                    showPagination
                />
            </Grid>
            <Grid xs={2} marginTop={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                <RenderCreateProductButton />
            </Grid>
        </Grid>
        {productList && <Datagrid<Product>
            data={productList}
            headings={productHeadings}
            link='product'
            loading={isLoading}
            renderRow={renderRows}
        />}
        <Grid container justifyContent={`flex-end`} alignItems={`center`} >
            <Checkbox
                checked={isChecked}
                onChange={handleShowOnlyTagged}
                inputProps={{ 'aria-label': `controlled` }}
            />
            <TitleHeading children="Show only products without ingredients" style={{
                fontSize: `1rem`
            }} />
        </Grid>
    </Grid>;
};

const RenderCreateProductButton: FC = () => <Link to='/product' style={{ textAlign: `center` }}>
    <Button
        color="primary"
        variant="contained"
        children="Create New Product" />
</Link>;

const productHeadings: Array<[string, string]> = [
    [`brand`, `Brand`],
    [`name`, `Name`],
    [`amount`, `Amount`],
    [`productProportions`, `Ingredients`],
];

function renderRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => {
        if (key === `productProportions`) {
            const ingredients = item[key] as any[];

            return <td key={key}>{(ingredients.length)}</td>;
        }
        return <td key={key}>{item[key]}</td>;
    });
}