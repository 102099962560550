export const countries = [
    { "value": `No country`, "id": `null`, "currency": `` },
    { "value": `Afghanistan`, "id": `afghanistan`, "currency": `AFN` },
    { "value": `Albania`, "id": `albania`, "currency": `ALL` },
    { "value": `Algeria`, "id": `algeria`, "currency": `DZD` },
    { "value": `Alland Islands`, "id": `alland islands`, "currency": `EUR` },
    { "value": `American Samoa`, "id": `american samoa`, "currency": `USD` },
    { "value": `Andorra`, "id": `andorra`, "currency": `EUR` },
    { "value": `Angola`, "id": `angola`, "currency": `AOA` },
    { "value": `Anguilla`, "id": `anguilla`, "currency": `XCD` },
    { "value": `Antarctica`, "id": `antarctica`, "currency": `XCD` },
    { "value": `Antigua and Barbuda`, "id": `antigua and barbuda`, "currency": `XCD` },
    { "value": `Argentina`, "id": `argentina`, "currency": `ARS` },
    { "value": `Armenia`, "id": `armenia`, "currency": `AMD` },
    { "value": `Aruba`, "id": `aruba`, "currency": `AWG` },
    { "value": `Australia`, "id": `australia`, "currency": `AUD` },
    { "value": `Austria`, "id": `austria`, "currency": `EUR` },
    { "value": `Azerbaijan`, "id": `azerbaijan`, "currency": `AZN` },
    { "value": `Bahamas`, "id": `bahamas`, "currency": `BSD` },
    { "value": `Bahrain`, "id": `bahrain`, "currency": `BHD` },
    { "value": `Bangladesh`, "id": `bangladesh`, "currency": `BDT` },
    { "value": `Barbados`, "id": `barbados`, "currency": `BBD` },
    { "value": `Belarus`, "id": `belarus`, "currency": `BYN` },
    { "value": `Belgium`, "id": `belgium`, "currency": `EUR` },
    { "value": `Belize`, "id": `belize`, "currency": `BZD` },
    { "value": `Benin`, "id": `benin`, "currency": `XOF` },
    { "value": `Bermuda`, "id": `bermuda`, "currency": `BMD` },
    { "value": `Bhutan`, "id": `bhutan`, "currency": `BTN` },
    { "value": `Bolivia`, "id": `bolivia`, "currency": `BOB` },
    { "value": `Bosnia and Herzegovina`, "id": `bosnia and herzegovina`, "currency": `BAM` },
    { "value": `Botswana`, "id": `botswana`, "currency": `BWP` },
    { "value": `Bouvet Island`, "id": `bouvet island`, "currency": `NOK` },
    { "value": `Brazil`, "id": `brazil`, "currency": `BRL` },
    { "value": `British Indian Ocean Territory`, "id": `british indian ocean territory`, "currency": `USD` },
    { "value": `British Virgin Islands`, "id": `british virgin islands`, "currency": `USD` },
    { "value": `Brunei Darussalam`, "id": `brunei darussalam`, "currency": `BND` },
    { "value": `Bulgaria`, "id": `bulgaria`, "currency": `BGN` },
    { "value": `Burkina Faso`, "id": `burkina faso`, "currency": `XOF` },
    { "value": `Burundi`, "id": `burundi`, "currency": `BIF` },
    { "value": `Cambodia`, "id": `cambodia`, "currency": `KHR` },
    { "value": `Cameroon`, "id": `cameroon`, "currency": `XAF` },
    { "value": `Canada`, "id": `canada`, "currency": `CAD` },
    { "value": `Cape Verde`, "id": `cape verde`, "currency": `CVE` },
    { "value": `Cayman Islands`, "id": `cayman islands`, "currency": `KYD` },
    { "value": `Central African Republic`, "id": `central african republic`, "currency": `XAF` },
    { "value": `Chad`, "id": `chad`, "currency": `XAF` },
    { "value": `Chile`, "id": `chile`, "currency": `CLP` },
    { "value": `China`, "id": `china`, "currency": `CNY` },
    { "value": `Christmas Island`, "id": `christmas island`, "currency": `AUD` },
    { "value": `Cocos (Keeling) Islands`, "id": `cocos (keeling) islands`, "currency": `AUD` },
    { "value": `Colombia`, "id": `colombia`, "currency": `COP` },
    { "value": `Comoros`, "id": `comoros`, "currency": `KMF` },
    { "value": `Congo, Democratic Republic of the`, "id": `congo, democratic republic of the`, "currency": `CDF` },
    { "value": `Congo, Republic of the`, "id": `congo, republic of the`, "currency": `XAF` },
    { "value": `Cook Islands`, "id": `cook islands`, "currency": `NZD` },
    { "value": `Costa Rica`, "id": `costa rica`, "currency": `CRC` },
    { "value": `Cote d'Ivoire`, "id": `cote d'ivoire`, "currency": `XOF` },
    { "value": `Croatia`, "id": `croatia`, "currency": `HRK` },
    { "value": `Cuba`, "id": `cuba`, "currency": `CUP` },
    { "value": `Curacao`, "id": `curacao`, "currency": `ANG` },
    { "value": `Cyprus`, "id": `cyprus`, "currency": `EUR` },
    { "value": `Czech Republic`, "id": `czech republic`, "currency": `CZK` },
    { "value": `Denmark`, "id": `denmark`, "currency": `DKK` },
    { "value": `Djibouti`, "id": `djibouti`, "currency": `DJF` },
    { "value": `Dominica`, "id": `dominica`, "currency": `XCD` },
    { "value": `Dominican Republic`, "id": `dominican republic`, "currency": `DOP` },
    { "value": `Ecuador`, "id": `ecuador`, "currency": `USD` },
    { "value": `Egypt`, "id": `egypt`, "currency": `EGP` },
    { "value": `El Salvador`, "id": `el salvador`, "currency": `USD` },
    { "value": `Equatorial Guinea`, "id": `equatorial guinea`, "currency": `XAF` },
    { "value": `Eritrea`, "id": `eritrea`, "currency": `ERN` },
    { "value": `Estonia`, "id": `estonia`, "currency": `EUR` },
    { "value": `Ethiopia`, "id": `ethiopia`, "currency": `ETB` },
    { "value": `Falkland Islands (Malvinas)`, "id": `falkland islands (malvinas)`, "currency": `FKP` },
    { "value": `Faroe Islands`, "id": `faroe islands`, "currency": `DKK` },
    { "value": `Fiji`, "id": `fiji`, "currency": `FJD` },
    { "value": `Finland`, "id": `finland`, "currency": `EUR` },
    { "value": `France`, "id": `france`, "currency": `EUR` },
    { "value": `French Guiana`, "id": `french guiana`, "currency": `EUR` },
    { "value": `French Polynesia`, "id": `french polynesia`, "currency": `XPF` },
    { "value": `French Southern Territories`, "id": `french southern territories`, "currency": `EUR` },
    { "value": `Gabon`, "id": `gabon`, "currency": `XAF` },
    { "value": `Gambia`, "id": `gambia`, "currency": `GMD` },
    { "value": `Georgia`, "id": `georgia`, "currency": `GEL` },
    { "value": `Germany`, "id": `germany`, "currency": `EUR` },
    { "value": `Ghana`, "id": `ghana`, "currency": `GHS` },
    { "value": `Gibraltar`, "id": `gibraltar`, "currency": `GIP` },
    { "value": `Greece`, "id": `greece`, "currency": `EUR` },
    { "value": `Greenland`, "id": `greenland`, "currency": `DKK` },
    { "value": `Grenada`, "id": `grenada`, "currency": `XCD` },
    { "value": `Guadeloupe`, "id": `guadeloupe`, "currency": `EUR` },
    { "value": `Guam`, "id": `guam`, "currency": `USD` },
    { "value": `Guatemala`, "id": `guatemala`, "currency": `GTQ` },
    { "value": `Guernsey`, "id": `guernsey`, "currency": `GBP` },
    { "value": `Guinea`, "id": `guinea`, "currency": `GNF` },
    { "value": `Guinea-Bissau`, "id": `guinea-bissau`, "currency": `XOF` },
    { "value": `Guyana`, "id": `guyana`, "currency": `GYD` },
    { "value": `Haiti`, "id": `haiti`, "currency": `HTG` },
    { "value": `Heard Island and McDonald Islands`, "id": `heard island and mcdonald islands`, "currency": `AUD` },
    { "value": `Holy See (Vatican City State)`, "id": `holy see (vatican city state)`, "currency": `EUR` },
    { "value": `Honduras`, "id": `honduras`, "currency": `HNL` },
    { "value": `Hong Kong`, "id": `hong kong`, "currency": `HKD` },
    { "value": `Hungary`, "id": `hungary`, "currency": `HUF` },
    { "value": `Iceland`, "id": `iceland`, "currency": `ISK` },
    { "value": `India`, "id": `india`, "currency": `INR` },
    { "value": `Indonesia`, "id": `indonesia`, "currency": `IDR` },
    { "value": `Iran, Islamic Republic of`, "id": `iran, islamic republic of`, "currency": `IRR` },
    { "value": `Iraq`, "id": `iraq`, "currency": `IQD` },
    { "value": `Ireland`, "id": `ireland`, "currency": `EUR` },
    { "value": `Isle of Man`, "id": `isle of man`, "currency": `GBP` },
    { "value": `Israel`, "id": `israel`, "currency": `ILS` },
    { "value": `Italy`, "id": `italy`, "currency": `EUR` },
    { "value": `Jamaica`, "id": `jamaica`, "currency": `JMD` },
    { "value": `Japan`, "id": `japan`, "currency": `JPY` },
    { "value": `Jersey`, "id": `jersey`, "currency": `GBP` },
    { "value": `Jordan`, "id": `jordan`, "currency": `JOD` },
    { "value": `Kazakhstan`, "id": `kazakhstan`, "currency": `KZT` },
    { "value": `Kenya`, "id": `kenya`, "currency": `KES` },
    { "value": `Kiribati`, "id": `kiribati`, "currency": `AUD` },
    { "value": `Korea, Democratic People's Republic of`, "id": `korea, democratic people's republic of`, "currency": `KPW` },
    { "value": `Korea, Republic of`, "id": `korea, republic of`, "currency": `KRW` },
    { "value": `Kosovo`, "id": `kosovo`, "currency": `EUR` },
    { "value": `Kuwait`, "id": `kuwait`, "currency": `KWD` },
    { "value": `Kyrgyzstan`, "id": `kyrgyzstan`, "currency": `KGS` },
    { "value": `Lao People's Democratic Republic`, "id": `lao people's democratic republic`, "currency": `LAK` },
    { "value": `Latvia`, "id": `latvia`, "currency": `EUR` },
    { "value": `Lebanon`, "id": `lebanon`, "currency": `LBP` },
    { "value": `Lesotho`, "id": `lesotho`, "currency": `LSL` },
    { "value": `Liberia`, "id": `liberia`, "currency": `LRD` },
    { "value": `Libya`, "id": `libya`, "currency": `LYD` },
    { "value": `Liechtenstein`, "id": `liechtenstein`, "currency": `CHF` },
    { "value": `Lithuania`, "id": `lithuania`, "currency": `EUR` },
    { "value": `Luxembourg`, "id": `luxembourg`, "currency": `EUR` },
    { "value": `Macao`, "id": `macao`, "currency": `MOP` },
    { "value": `Macedonia, the Former Yugoslav Republic of`, "id": `macedonia, the former yugoslav republic of`, "currency": `MKD` },
    { "value": `Madagascar`, "id": `madagascar`, "currency": `MGA` },
    { "value": `Malawi`, "id": `malawi`, "currency": `MWK` },
    { "value": `Malaysia`, "id": `malaysia`, "currency": `MYR` },
    { "value": `Maldives`, "id": `maldives`, "currency": `MVR` },
    { "value": `Mali`, "id": `mali`, "currency": `XOF` },
    { "value": `Malta`, "id": `malta`, "currency": `EUR` },
    { "value": `Marshall Islands`, "id": `marshall islands`, "currency": `USD` },
    { "value": `Martinique`, "id": `martinique`, "currency": `EUR` },
    { "value": `Mauritania`, "id": `mauritania`, "currency": `MRU` },
    { "value": `Mauritius`, "id": `mauritius`, "currency": `MUR` },
    { "value": `Mayotte`, "id": `mayotte`, "currency": `EUR` },
    { "value": `Mexico`, "id": `mexico`, "currency": `MXN` },
    { "value": `Micronesia, Federated States of`, "id": `micronesia, federated states of`, "currency": `USD` },
    { "value": `Moldova, Republic of`, "id": `moldova, republic of`, "currency": `MDL` },
    { "value": `Monaco`, "id": `monaco`, "currency": `EUR` },
    { "value": `Mongolia`, "id": `mongolia`, "currency": `MNT` },
    { "value": `Montenegro`, "id": `montenegro`, "currency": `EUR` },
    { "value": `Montserrat`, "id": `montserrat`, "currency": `XCD` },
    { "value": `Morocco`, "id": `morocco`, "currency": `MAD` },
    { "value": `Mozambique`, "id": `mozambique`, "currency": `MZN` },
    { "value": `Myanmar`, "id": `myanmar`, "currency": `MMK` },
    { "value": `Namibia`, "id": `namibia`, "currency": `NAD` },
    { "value": `Nauru`, "id": `nauru`, "currency": `AUD` },
    { "value": `Nepal`, "id": `nepal`, "currency": `NPR` },
    { "value": `Netherlands`, "id": `netherlands`, "currency": `EUR` },
    { "value": `New Caledonia`, "id": `new caledonia`, "currency": `XPF` },
    { "value": `New Zealand`, "id": `new zealand`, "currency": `NZD` },
    { "value": `Nicaragua`, "id": `nicaragua`, "currency": `NIO` },
    { "value": `Niger`, "id": `niger`, "currency": `XOF` },
    { "value": `Nigeria`, "id": `nigeria`, "currency": `NGN` },
    { "value": `Niue`, "id": `niue`, "currency": `NZD` },
    { "value": `Norfolk Island`, "id": `norfolk island`, "currency": `AUD` },
    { "value": `Northern Mariana Islands`, "id": `northern mariana islands`, "currency": `USD` },
    { "value": `Norway`, "id": `norway`, "currency": `NOK` },
    { "value": `Oman`, "id": `oman`, "currency": `OMR` },
    { "value": `Pakistan`, "id": `pakistan`, "currency": `PKR` },
    { "value": `Palau`, "id": `palau`, "currency": `USD` },
    { "value": `Palestine, State of`, "id": `palestine, state of`, "currency": `ILS` },
    { "value": `Panama`, "id": `panama`, "currency": `PAB` },
    { "value": `Papua New Guinea`, "id": `papua new guinea`, "currency": `PGK` },
    { "value": `Paraguay`, "id": `paraguay`, "currency": `PYG` },
    { "value": `Peru`, "id": `peru`, "currency": `PEN` },
    { "value": `Philippines`, "id": `philippines`, "currency": `PHP` },
    { "value": `Pitcairn`, "id": `pitcairn`, "currency": `NZD` },
    { "value": `Poland`, "id": `poland`, "currency": `PLN` },
    { "value": `Portugal`, "id": `portugal`, "currency": `EUR` },
    { "value": `Puerto Rico`, "id": `puerto rico`, "currency": `USD` },
    { "value": `Qatar`, "id": `qatar`, "currency": `QAR` },
    { "value": `Reunion`, "id": `reunion`, "currency": `EUR` },
    { "value": `Romania`, "id": `romania`, "currency": `RON` },
    { "value": `Russian Federation`, "id": `russian federation`, "currency": `RUB` },
    { "value": `Rwanda`, "id": `rwanda`, "currency": `RWF` },
    { "value": `Saint Barthelemy`, "id": `saint barthelemy`, "currency": `EUR` },
    { "value": `Saint Helena`, "id": `saint helena`, "currency": `SHP` },
    { "value": `Saint Kitts and Nevis`, "id": `saint kitts and nevis`, "currency": `XCD` },
    { "value": `Saint Lucia`, "id": `saint lucia`, "currency": `XCD` },
    { "value": `Saint Martin (French part)`, "id": `saint martin (french part)`, "currency": `EUR` },
    { "value": `Saint Pierre and Miquelon`, "id": `saint pierre and miquelon`, "currency": `EUR` },
    { "value": `Saint Vincent and the Grenadines`, "id": `saint vincent and the grenadines`, "currency": `XCD` },
    { "value": `Samoa`, "id": `samoa`, "currency": `WST` },
    { "value": `San Marino`, "id": `san marino`, "currency": `EUR` },
    { "value": `Sao Tome and Principe`, "id": `sao tome and principe`, "currency": `STN` },
    { "value": `Saudi Arabia`, "id": `saudi arabia`, "currency": `SAR` },
    { "value": `Senegal`, "id": `senegal`, "currency": `XOF` },
    { "value": `Serbia`, "id": `serbia`, "currency": `RSD` },
    { "value": `Seychelles`, "id": `seychelles`, "currency": `SCR` },
    { "value": `Sierra Leone`, "id": `sierra leone`, "currency": `SLL` },
    { "value": `Singapore`, "id": `singapore`, "currency": `SGD` },
    { "value": `Sint Maarten (Dutch part)`, "id": `sint maarten (dutch part)`, "currency": `ANG` },
    { "value": `Slovakia`, "id": `slovakia`, "currency": `EUR` },
    { "value": `Slovenia`, "id": `slovenia`, "currency": `EUR` },
    { "value": `Solomon Islands`, "id": `solomon islands`, "currency": `SBD` },
    { "value": `Somalia`, "id": `somalia`, "currency": `SOS` },
    { "value": `South Africa`, "id": `south africa`, "currency": `ZAR` },
    { "value": `South Georgia and the South Sandwich Islands`, "id": `south georgia and the south sandwich islands`, "currency": `GBP` },
    { "value": `South Sudan`, "id": `south sudan`, "currency": `SSP` },
    { "value": `Spain`, "id": `spain`, "currency": `EUR` },
    { "value": `Sri Lanka`, "id": `sri lanka`, "currency": `LKR` },
    { "value": `Sudan`, "id": `sudan`, "currency": `SDG` },
    { "value": `Suriname`, "id": `suriname`, "currency": `SRD` },
    { "value": `Svalbard and Jan Mayen`, "id": `svalbard and jan mayen`, "currency": `NOK` },
    { "value": `Swaziland`, "id": `swaziland`, "currency": `SZL` },
    { "value": `Sweden`, "id": `sweden`, "currency": `SEK` },
    { "value": `Switzerland`, "id": `switzerland`, "currency": `CHF` },
    { "value": `Syrian Arab Republic`, "id": `syrian arab republic`, "currency": `SYP` },
    { "value": `Taiwan, Republic of China`, "id": `taiwan, republic of china`, "currency": `TWD` },
    { "value": `Tajikistan`, "id": `tajikistan`, "currency": `TJS` },
    { "value": `Thailand`, "id": `thailand`, "currency": `THB` },
    { "value": `Timor-Leste`, "id": `timor-leste`, "currency": `USD` },
    { "value": `Togo`, "id": `togo`, "currency": `XOF` },
    { "value": `Tokelau`, "id": `tokelau`, "currency": `NZD` },
    { "value": `Tonga`, "id": `tonga`, "currency": `TOP` },
    { "value": `Trinidad and Tobago`, "id": `trinidad and tobago`, "currency": `TTD` },
    { "value": `Tunisia`, "id": `tunisia`, "currency": `TND` },
    { "value": `Turkey`, "id": `turkey`, "currency": `TRY` },
    { "value": `Turkmenistan`, "id": `turkmenistan`, "currency": `TMT` },
    { "value": `Turks and Caicos Islands`, "id": `turks and caicos islands`, "currency": `USD` },
    { "value": `Tuvalu`, "id": `tuvalu`, "currency": `AUD` },
    { "value": `Uganda`, "id": `uganda`, "currency": `UGX` },
    { "value": `Ukraine`, "id": `ukraine`, "currency": `UAH` },
    { "value": `United Arab Emirates`, "id": `united arab emirates`, "currency": `AED` },
    { "value": `United Kingdom`, "id": `united kingdom`, "currency": `GBP` },
    { "value": `United Republic of Tanzania`, "id": `united republic of tanzania`, "currency": `TZS` },
    { "value": `United States`, "id": `united states`, "currency": `USD` },
    { "value": `Uruguay`, "id": `uruguay`, "currency": `UYU` },
    { "value": `US Virgin Islands`, "id": `us virgin islands`, "currency": `USD` },
    { "value": `Uzbekistan`, "id": `uzbekistan`, "currency": `UZS` },
    { "value": `Vanuatu`, "id": `vanuatu`, "currency": `VUV` },
    { "value": `Venezuela`, "id": `venezuela`, "currency": `VES` },
    { "value": `Vietnam`, "id": `vietnam`, "currency": `VND` },
    { "value": `Wallis and Futuna`, "id": `wallis and futuna`, "currency": `XPF` },
    { "value": `Western Sahara`, "id": `western sahara`, "currency": `MAD` },
    { "value": `Yemen`, "id": `yemen`, "currency": `YER` },
    { "value": `Zambia`, "id": `zambia`, "currency": `ZMW` },
    { "value": `Zimbabwe`, "id": `zimbabwe`, "currency": `ZWL` }
];