import { Slide, ToastContainer } from 'react-toastify';

/**
 * Notification component
 */
export const Toaster = ():JSX.Element => (
    <ToastContainer
        position='bottom-right'
        autoClose={5001}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
);

