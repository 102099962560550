type EnumType = { [key: string]: string | number };
type EnumAsArray<ValueType> = {
  key: string;
  value: ValueType
}[];

/**
 * This function is very helpful in case is necessary to display the same component on different enum types,
 * for example   IntegrationProvider has many values that will render the same component but a different name
 * in order to avoid redundance is better to convert the array that to have an enum and an array constant with different values
 *
 * @param ENUM simply the enum that will be converted into Array
 * @returns  convert Enums into arrays with keys and values [{key:'string', value:123}]
 */
export const enumToArray = <T extends string>(ENUM: EnumType): EnumAsArray<T> => Object.keys(ENUM)
    .filter(key => Number.isNaN(Number(key)))
    .map((key: string) => ({
        key,
        value: ENUM[key].toString() as T,
    }));