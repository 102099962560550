import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { countries } from '../../../countries';
import { getRetailerList } from '../../../setup/api/retail';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { RootState } from '../../../setup/redux/RootReducer';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const RetailerPage: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const { request, isLoading, abortController } = useFetch();

    const [retailerListAndCount, setRetailerListAndCount] = useState({
        retailers: [],
        count: 0
    });

    useEffect(() => {
        request(getRetailerList).then(results => {
            if (results) {
                const [retailers, count] = results;

                setRetailerListAndCount({ retailers, count });
            }
        });
        return () => {
            abortController.abort();
        };
    }, []);

    return <div className='mt-5 me-5' style={{ marginBottom: `2em` }}>
        <Grid container xs={12} marginTop={3} marginBottom={3}>
            <Grid xs={12} marginTop={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                {userRole === `admin` && <RenderCreateButton />}
            </Grid>
        </Grid>
        {
            retailerListAndCount && <Datagrid
                data={retailerListAndCount.retailers}
                headings={retailersHeadings}
                link='retailer'
                loading={isLoading}
                renderRow={renderRows}
            />
        }
    </div >;
};

const RenderCreateButton: FC = () => <Link to='/retailer' style={{ textAlign: `center` }}>
    <Button
        color="primary"
        variant="contained"
        children="Create New Retailer" />
</Link>;

export const retailersHeadings: Array<[string, string]> = [
    [`name`, `Name`],
    [`country`, `Country`],
    [`website`, `Website`],
    [`affiliateLink`, `Affiliate Link`],
    [`hasPicture`, `Picture`],
];

function renderRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => {
        if (key === `hasPicture`) {
            return (
                <Checkbox
                    disabled={true}
                    checked={item[key] as boolean}
                    onChange={() => {
                        console.log(item.id);
                    }}
                    sx={{
                        color: `grey.400`,
                        '&.Mui-disabled': {
                            color: `grey.400`,
                        }
                    }}
                />
            );
        }
        if (key === `affiliateLink`) {
            return <td key={key}>{item[key] ? `Yes` : `No`}</td>;
        }
        if (key === `country`) {
            const value = item[key];
            const country = countries.find(country => country.id === value);
            const countryValue = country ? { id: country.id, value: country.value, currency: country.currency } : { id: `null`, value: `No country`, currency: `DKK` };

            return <td key={key}>{countryValue.value}</td>;
        }
        return <td key={key}>{item[key]}</td>;
    });
}