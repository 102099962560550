import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/styles';
import { Dispatch, FC, SetStateAction } from 'react';

import { comparColors } from '../../helpers/colors';
import { ComparLightChip } from '../TagChips/ComparLightChip';

type Props = {
    data: Array<string>
    setObjectSelected?: Dispatch<SetStateAction<string[]>>
    objectsSelected?: string[],
    titleText: string
}

export const StringTableDisplay: FC<Props> = ({
    data,
    setObjectSelected,
    objectsSelected,
    titleText
}) => {
    const handleDelete = (value: string) => {
        if (setObjectSelected && objectsSelected) {
            const filteredList = objectsSelected.filter(selectedItem => selectedItem !== value);

            setObjectSelected(filteredList);
        }
    };

    return <Grid container>
        <TableGrid data={data} onDelete={handleDelete} lightColor title={titleText} />
        <Grid item sx={{
            minWidth: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            marginTop: `-1.1em`,
        }}>
        </Grid>
    </Grid>;
};

type TableProps = {
    data: string[];
    onDelete?: (value: string) => void;
    lightColor?: boolean;
    title?: string
};

const TableGrid: FC<TableProps> = ({ data, onDelete, lightColor, title }) => (
    <GridContainer xs={20}
        style={lightColor
            ? gridLightStyle
            : {}}
        container
        spacing={2}
        marginTop={3}
        marginLeft={0}>
        {title && <SelectedTitle children={title} />}
        {data.map(item => (
            <Grid item key={item} sx={{ minWidth: 0 }}>
                <ComparLightChip
                    style={lightColor ? chipLightStyle : {}}
                    label={`${item}`}
                    onDelete={onDelete ? () => onDelete(item) : undefined}
                    deleteIcon={onDelete
                        ? <HighlightOffIcon />
                        : undefined}
                />
            </Grid>
        ))}
    </GridContainer>
);

const chipLightStyle = {
    backgroundColor: comparColors.primary.light,
};

const gridLightStyle = {
    backgroundColor: comparColors.white,
    borderRadius: ` 20px`,
    border: `2px solid #F7F5F3`,
    paddingTop: `0px`
};

const SelectedTitle = styled(`span`)({
    minWidth: 0,
    position: `absolute`,
    marginTop: `-0.8em`,
    fontFamily: `ProximaNova-Semibold`
});

const GridContainer = styled(Grid)({
    backgroundColor: comparColors.primary.light,
    borderRadius: ` 20px 20px 20px 20px`,
    padding: `1em`,
    paddingBottom: `2.5em`,
});