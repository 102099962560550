import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, styled } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { FC, Fragment, useState } from 'react';

import { HairFeatures, hairFeaturesOptions, HairFeaturesValueType } from '../../../constants';
import { countries } from '../../../countries';
import { getArticleById } from '../../../setup/api/articles';
import { getHairFeatureByValues } from '../../../setup/api/hairFeature';
import { getProductById } from '../../../setup/api/product';
import { sendNotificationHandler } from '../../../setup/api/user';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { HairFeature, Users } from '../../../setup/models';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { SelectedHairFeatureTable } from '../../../ui/components/HairFeatureTable/HairFeatureTable';
import { ComparLightChip } from '../../../ui/components/TagChips/ComparLightChip';
import { ComparTextField } from '../../../ui/components/TextField/ComparTextField';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { comparColors } from '../../../ui/helpers/colors';
import { parseObjectKeyToReadableString, parseReadableStringToObjectKeyName } from '../../../ui/helpers/stringModifiers';

type Notification = {
    title: string,
    userGroup: string,
    body: string,
    link: string,
    productId: string,
    articleId: string,
    match: number,
};

export const SendNotificationsPage: FC = () => {
    const { request, isLoading, abortController } = useFetch();

    const notification: Notification = {
        title: ``,
        userGroup: ``,
        body: ``,
        link: ``,
        productId: ``,
        articleId: ``,
        match: 0
    };

    const [receiversShown, setReceiversShown] = useState<boolean>(false);
    const [receivers, setReceivers] = useState<[]>([]);
    const [numberOfReceivers, setNumberOfReceivers] = useState<number>(0);
    const [onlyAdmins, setOnlyAdmins] = useState<boolean>(true);
    const [onlyNHJ, setOnlyNHJ] = useState<boolean>(true);
    const [notificationType, setNotificationType] = useState<string>(`All`);
    const [selectedCountry, setSelectedCountry] = useState<string>(``);
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [userGroup, setUserGroup] = useState<string>(`Everyone`);
    const [linkTo, setLinkTo] = useState<string>(`Open app`);

    const [messageSent, setMessageSent] = useState<string>(``);

    const [selectedHairFeatures, setSelectedHairFeatures] = useState<HairFeature[]>([]);
    const [selectedHairFeatureValues, setSelectedHairFeatureValues] = useState<HairFeaturesValueType[]>([]);
    const [hairFeatureSelected, setHairFeatureSelected] = useState<HairFeatures | ``>(``);
    const [hairFeatureValueSelected, setHairFeatureValueSelected] = useState<HairFeaturesValueType | ``>(``);

    const sendNotification = async (values: any, send: boolean) => {
        setMessageSent(``);
        let link = linkTo;
        let continueSending = true;

        if (values.productId !== `` && (link === `ProductDetail` || userGroup === `Users with match on product`)) {
            await request(getProductById, values.productId, abortController.signal)
                .then(res => {
                    if (!res.id) {
                        console.log(`error`);
                        continueSending = false;
                        alert(`Not valid product id`);
                    } else if (link === `ProductDetail`) {
                        link = `${link}/${values.productId}`;
                    }
                });
        }
        if (values.articleId !== `` && link === `ArticleDetail`) {
            await request(getArticleById, values.articleId, abortController.signal)
                .then(res => {
                    if (!res.id) {
                        console.log(`error`);
                        continueSending = false;
                        alert(`Not valid article id`);
                    } else if (link === `ArticleDetail`) {
                        link = `${link}/${values.articleId}`;
                    }
                });
        }
        if (continueSending) {
            const payload: any = {
                ...values,
                userGroup: userGroup,
                notificationType: notificationType,
                link: link,
                onlyNHJ: onlyNHJ,
                hairFeatures: selectedHairFeatures,
                selectedCountries: selectedCountries
            };

            const queryParams = `?send=${send}&onlyAdmins=${onlyAdmins}`;

            request(sendNotificationHandler, queryParams, abortController.signal, payload)
                .then((result: any) => {
                    if (result) {
                        if (!send) {
                            setReceiversShown(true);
                            setReceivers(result.receivers);
                            setNumberOfReceivers(result.totalReceivers);
                        } else {
                            console.log(result.successes);
                            if (result.successes) {
                                setMessageSent(`Notification received by ${result.successes.length} users`);
                            } else {
                                setMessageSent(`Notification was not received by any users.`);
                            }
                        }
                    }
                })
                .catch((error: any) => {
                    console.error(`Failed to fetch articles:`, error);
                });
        }
    };

    const resetValues = (props: FormikProps<Notification>) => {
        props.resetForm();
        setReceiversShown(false);
        setReceivers([]);
        setNumberOfReceivers(0);
        setOnlyAdmins(true);
        setOnlyNHJ(true);
        setNotificationType(`All`);
        setUserGroup(`Everyone`);
        setLinkTo(`Open app`);
        setSelectedHairFeatures([]);
        setSelectedHairFeatureValues([]);
        setHairFeatureSelected(``);
        setHairFeatureValueSelected(``);
        setSelectedCountries([]);
    };

    const appendSelectedHairFeature = () => {
        const isFeatureAlreadySelected = selectedHairFeatures.some(
            existingHairFeature => existingHairFeature.feature === hairFeatureSelected && existingHairFeature.value === hairFeatureValueSelected
        );

        if (!isFeatureAlreadySelected) {
            request(getHairFeatureByValues, hairFeatureSelected, hairFeatureValueSelected, abortController.signal)
                .then(hairFeature => {
                    if (hairFeature.id) {
                        setSelectedHairFeatures(prevArray => [...prevArray, hairFeature]);
                        setHairFeatureValueSelected(``);
                    } else {
                        console.log(`Hair Feature not found`);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching hairFeature:`, error);
                });
        } else {
            console.log(`Already added to article`);
        }
    };

    return (
        <Fragment>
            <Formik
                enableReinitialize
                onSubmit={values => sendNotification(values, true)}
                initialValues={notification}
            >
                {(props: FormikProps<Notification>) => (
                    <Form
                        onChange={() => {
                            if (receiversShown) {
                                setReceiversShown(false);
                                setMessageSent(``);
                            }
                        }}
                    >
                        <Grid container justifyContent={`space-between`}>
                            <Grid xs={5.5} marginTop={3} >
                                <ComparTextField
                                    required
                                    type='string'
                                    placeholder='Notification Title'
                                    label='Title'
                                    InputLabelProps={{ shrink: true }}
                                    {...props.getFieldProps(`title`)}
                                />
                                <ComparTextField
                                    required
                                    type='string'
                                    placeholder='Notification Body'
                                    label='Body'
                                    InputLabelProps={{ shrink: true }}
                                    {...props.getFieldProps(`body`)}
                                />
                                <TitleHeading children={`Emojis: 🤎✔️💫✨🙌🏻`} style={{
                                    fontSize: `0.75rem`,
                                    color: comparColors.primary.main,
                                    textAlign: `center`
                                }} />
                                <LinkToSelectionFormGrid>
                                    <SelectFieldFromArray<string>
                                        name='link'
                                        label='Link to'
                                        keyName='link'
                                        required
                                        value={linkTo}
                                        sx={{
                                            marginRight: `1em`,
                                            backgroundColor: `#000`,
                                            borderRadius: `20px`,
                                            'fieldset': {
                                                border: `none`
                                            }
                                        }}
                                        inputLabelStyle={{
                                            backgroundColor: `transparent`,
                                        }}

                                        style={{
                                            backgroundColor: `#fff`,
                                        }}
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setLinkTo(e.target.value);
                                            setReceiversShown(false);
                                        }}
                                        options={[`Open app`, `Home`, `Search`, `BeautyBag`, `Feedback`, `UserProfile`, `ProductsForYou`, `BeautyForYou`, `ProductDetail`, `ArticleDetail`]} />
                                </LinkToSelectionFormGrid>
                                {(linkTo === `ProductDetail` || userGroup === `Users with match on product`) &&
                                    <ComparTextField
                                        required={linkTo === `ProductDetail` || userGroup === `Users with match on product`}
                                        type='string'
                                        placeholder='Product id...'
                                        label='Product id'
                                        InputLabelProps={{ shrink: true }}
                                        {...props.getFieldProps(`productId`)}
                                    />
                                }
                                {linkTo === `ArticleDetail` &&
                                    <ComparTextField
                                        required={linkTo === `ArticleDetail`}
                                        type='string'
                                        placeholder='Article id...'
                                        label='Article id'
                                        InputLabelProps={{ shrink: true }}
                                        {...props.getFieldProps(`articleId`)}
                                    />
                                }
                                <div style={{
                                    display: `flex`,
                                    flexDirection: `row`,
                                    justifyContent: `flex-start`,
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={onlyAdmins}
                                                onChange={() => {
                                                    setOnlyAdmins(prev => !prev);
                                                    if (receiversShown) {
                                                        setReceiversShown(false);
                                                    }
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Only admins"
                                    />
                                </div>
                                <Grid xs={5.5} marginTop={3} >
                                    <div style={{
                                        display: `flex`,
                                        flexDirection: `column`,
                                        justifyContent: `space-between`,
                                        marginTop: `1.2em`
                                    }}>
                                        <Button
                                            variant="contained"
                                            disabled={(userGroup === `Users with match on product`) && (!props.values.productId || !props.values.match)}
                                            children="Show receivers"
                                            onClick={() => sendNotification(props.values, false)}
                                        />
                                        <LoadingButton
                                            // loading={update.isLoading}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!receiversShown}
                                            sx={{
                                                marginTop: 3
                                            }}
                                        >
                                            {`Send Notification`}
                                        </LoadingButton>
                                        <TitleHeading children={`When pressing Send Notification, the notification is send now. Scheduling a notification is still under development`} style={{
                                            fontSize: `0.75rem`,
                                            color: comparColors.primary.main,
                                            textAlign: `center`
                                        }} />
                                        <Button
                                            component="label"
                                            children="Reset form"
                                            style={{ flex: `end`, marginTop: `2em` }}
                                            onClick={() => resetValues(props)}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid xs={5.8} marginTop={3} >
                                <UserSelectionFormGrid>
                                    <SelectFieldFromArray<string>
                                        name='userGroup'
                                        label='User Group'
                                        keyName='userGroup'
                                        required
                                        value={userGroup}
                                        sx={{
                                            marginRight: `1em`,
                                            backgroundColor: `#000`,
                                            borderRadius: `20px`,
                                            'fieldset': {
                                                border: `none`
                                            }
                                        }}
                                        inputLabelStyle={{
                                            backgroundColor: `transparent`,
                                        }}

                                        style={{
                                            backgroundColor: `#fff`,
                                        }}
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setUserGroup(e.target.value);
                                            if (e.target.value === `Users with match on product`) {
                                                setLinkTo(`ProductDetail`);
                                            }
                                            if (receiversShown) {
                                                setReceiversShown(false);
                                            }
                                        }}
                                        options={[`Everyone`, `Users with hair profile`, `Users without hair profile`, `Users with specific hair features`, `Users with match on product`]}
                                    />
                                    {userGroup === `Users with match on product` &&
                                        <ComparTextField
                                            required
                                            type='number'
                                            placeholder='Match score...'
                                            label='Match'
                                            InputLabelProps={{ shrink: true }}
                                            {...props.getFieldProps(`match`)}
                                            sx={{
                                                backgroundColor: `white`
                                            }}
                                        />
                                    }
                                    {userGroup === `Users with specific hair features` &&
                                        <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                            width: `100%`
                                        }} >
                                            <Grid xs={5.7}>
                                                <SelectFieldFromArray<string>
                                                    name='feature'
                                                    label='Hair Feature'
                                                    keyName='feature'
                                                    required={false}
                                                    sx={{
                                                        marginRight: `1em`,
                                                        backgroundColor: `#000`,
                                                        borderRadius: `20px`,
                                                        'fieldset': {
                                                            border: `none`
                                                        }

                                                    }}
                                                    inputLabelStyle={{
                                                        backgroundColor: `transparent`,
                                                    }}

                                                    style={{
                                                        backgroundColor: `#fff`,
                                                    }}
                                                    onChange={(e: { target: { value: HairFeatures } }) => {
                                                        const joinedValue = parseReadableStringToObjectKeyName(e.target.value) as HairFeatures;

                                                        setSelectedHairFeatureValues(hairFeaturesOptions[joinedValue]);

                                                        setHairFeatureSelected(joinedValue);

                                                        setHairFeatureValueSelected(``);
                                                    }}
                                                    options={Object.keys(hairFeaturesOptions).map((key: string) => parseObjectKeyToReadableString(key))}
                                                />
                                            </Grid>
                                            <Grid xs={5.7}>
                                                <SelectFieldFromArray<string>
                                                    name='value'
                                                    label='Hair Feature Value'
                                                    required={false}
                                                    value={hairFeatureValueSelected}
                                                    sx={{
                                                        marginRight: `1em`,
                                                        backgroundColor: `#fff`,
                                                        borderRadius: `20px`,
                                                        'fieldset': {
                                                            border: `none`
                                                        }
                                                    }}
                                                    inputLabelStyle={{
                                                        backgroundColor: `transparent`,
                                                    }}
                                                    style={{
                                                        backgroundColor: `#fff`,
                                                    }}
                                                    options={selectedHairFeatureValues}
                                                    onChange={(e: { target: { value: string } }) => {
                                                        const value = e.target.value as HairFeaturesValueType;

                                                        setHairFeatureValueSelected(value);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    {userGroup === `Users with specific hair features` &&
                                        <Grid>
                                            <Button type="button"
                                                disabled={!hairFeatureValueSelected || !hairFeatureSelected}
                                                variant="contained"
                                                color="primary"
                                                children="Add Feature"
                                                onClick={appendSelectedHairFeature}
                                                sx={{
                                                    height: `3em`,
                                                    width: `10em`,
                                                    marginLeft: `1em`,
                                                }} />
                                            <SelectedHairFeatureTable
                                                data={selectedHairFeatures}
                                                objectsSelected={selectedHairFeatures}
                                                setObjectSelected={setSelectedHairFeatures}
                                                titleText='Selected Hair Features'
                                            />
                                        </Grid>
                                    }
                                    <SelectFieldFromArray<string>
                                        name='notificationType'
                                        label='Notification Type'
                                        keyName='notificationType'
                                        required
                                        value={notificationType}
                                        sx={{
                                            backgroundColor: `#000`,
                                            borderRadius: `20px`,
                                            'fieldset': {
                                                border: `none`
                                            }
                                        }}
                                        inputLabelStyle={{
                                            backgroundColor: `transparent`,
                                        }}

                                        style={{
                                            backgroundColor: `#fff`,
                                        }}
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setNotificationType(e.target.value);
                                            if (receiversShown) {
                                                setReceiversShown(false);
                                            }
                                        }}
                                        options={[`All`, `Keeping up with beauty`, `Save money`, `Be a winner`]} />
                                    <SelectFieldFromArray<string>
                                        name='selectedCountry'
                                        label='Send to specific countries'
                                        keyName='selectedCountry'
                                        value={selectedCountry}
                                        sx={{
                                            backgroundColor: `#000`,
                                            borderRadius: `20px`,
                                            'fieldset': {
                                                border: `none`
                                            }
                                        }}
                                        inputLabelStyle={{
                                            backgroundColor: `transparent`,
                                        }}

                                        style={{
                                            backgroundColor: `#fff`,
                                        }}
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setSelectedCountries(prevCountries => {
                                                const countryPressed = e.target.value;

                                                if (!prevCountries.includes(countryPressed)) {
                                                    return [...prevCountries, countryPressed];
                                                }
                                                return prevCountries;
                                            });
                                            setSelectedCountry(``);
                                        }}
                                        options={countries
                                            .filter(country => country.value !== `No country`)
                                            .map(country => (country.value))}
                                    />
                                    <Grid container spacing={1} style={{ display: `flex`, flexWrap: `wrap` }}>
                                        {
                                            selectedCountries.length > 0
                                                ? (
                                                    selectedCountries.map((country, index) => (
                                                        <Grid item xs={3} key={index} style={{ marginRight: `8px`, marginBottom: `8px` }}>
                                                            <ComparLightChip
                                                                style={{ fontSize: `13px` }}
                                                                onDelete={() => {
                                                                    console.log(index);
                                                                    setSelectedCountries(prevCountries =>
                                                                        prevCountries.filter((_, i) => i !== index)
                                                                    );
                                                                }}
                                                                deleteIcon={<HighlightOffIcon color="inherit" />}
                                                                label={country}
                                                                variant="filled"
                                                            />
                                                        </Grid>
                                                    )))
                                                :
                                                (
                                                    <Grid style={{ marginTop: `8px`, marginLeft: `8px` }}>
                                                        <TitleHeading children={`Sending to all countries, as no specific is selected`} style={{
                                                            fontSize: `0.75rem`,
                                                            color: comparColors.primary.main,
                                                            textAlign: `center`
                                                        }} />
                                                    </Grid>
                                                )
                                        }
                                    </Grid>
                                    <div style={{
                                        display: `flex`,
                                        flexDirection: `row`,
                                        justifyContent: `flex-start`,
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={onlyNHJ}
                                                    onChange={() => {
                                                        setOnlyNHJ(prev => !prev);
                                                        if (receiversShown) {
                                                            setReceiversShown(false);
                                                        }
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Only users with NHJ"
                                        />
                                    </div>
                                </UserSelectionFormGrid>
                            </Grid>
                        </Grid>
                        {receiversShown && (
                            <Grid xs={12} marginTop={3} >
                                {receivers && numberOfReceivers !== 0 ?
                                    <Grid>
                                        <TitleHeading children={`${numberOfReceivers} relevant users`}
                                            style={{
                                                fontSize: `1rem`,
                                                textAlign: `center`
                                            }}
                                        />
                                        <TitleHeading children={`NB: showing maximum 50`}
                                            style={{
                                                fontSize: `0.75rem`,
                                                color: `green`,
                                                textAlign: `center`
                                            }}
                                        />
                                        <Datagrid<Users>
                                            data={receivers}
                                            headings={receiversHeadings}
                                            renderRow={renderRows}
                                        />
                                    </Grid>
                                    :
                                    <TitleHeading children={`No notification receivers for this selection`}
                                        style={{
                                            fontSize: `0.75rem`,
                                            color: `red`,
                                            textAlign: `center`
                                        }}
                                    />
                                }
                            </Grid>
                        )}
                        {messageSent !== `` &&
                            <TitleHeading children={messageSent}
                                style={{
                                    fontSize: `0.75rem`,
                                    color: messageSent === `Notification was not received by any users.` ? `red` : `green`,
                                    textAlign: `center`
                                }}
                            />
                        }
                    </Form>
                )}
            </Formik>
        </Fragment >
    );
};

export const receiversHeadings: Array<[string, string]> = [
    [`email`, `Email`],
    [`userRole`, `User Role`],
    [`name`, `Name`],
    [`country`, `Country`],
    [`naturalHairJourney`, `NHJ`],
    [`notificationBeautyUpdate`, `Keeping up with beauty notifications`],
    [`notificationSaveMoney`, `Save money notifications`],
    [`notificationPrizes`, `Be a winner notifications`],
    [`pushTokens`, `Gets notifications`],
];

function renderRows(headings: Array<Array<string>>, item: Partial<Record<string, unknown>>) {
    return headings.map(([key]) => {
        const pushTokens = item[`pushTokens`] as Array<{ deviceToken: string, platform: string }> || [];

        if (key === `name`) {
            return (
                <td key={key}>
                    {`${item[`firstName`] as string} ${item[`lastName`] as string}`}
                </td>
            );
        }
        if (key === `pushTokens`) {
            return (
                <td key={key}>
                    {pushTokens ? pushTokens.length > 0 ? `Yes` : `No` : `No`}
                </td>
            );
        }
        if (key === `naturalHairJourney`) {
            return (
                <td key={key}
                    style={{ padding: 0 }}
                >
                    <Checkbox
                        disabled
                        checked={item[key] as boolean}
                        color="primary"
                        sx={{
                            '&.Mui-disabled': {
                                color: `grey.400`,
                            }
                        }}
                    />
                </td>
            );
        }
        if (key === `notificationBeautyUpdate`) {
            return (
                <td key={key}
                    style={{ padding: 0 }}
                >
                    <Checkbox
                        disabled
                        checked={item[key] as boolean}
                        color="primary"
                        sx={{
                            '&.Mui-disabled': {
                                color: `grey.400`,
                            }
                        }}
                    />
                </td>
            );
        }
        if (key === `notificationSaveMoney`) {
            return (
                <td key={key}
                    style={{ padding: 0 }}
                >
                    <Checkbox
                        disabled
                        checked={item[key] as boolean}
                        color="primary"
                        sx={{
                            '&.Mui-disabled': {
                                color: `grey.400`,
                            }
                        }}
                    />
                </td>
            );
        }
        if (key === `notificationPrizes`) {
            return (
                <td key={key}
                    style={{ padding: 0 }}
                >
                    <Checkbox
                        disabled
                        checked={item[key] as boolean}
                        color="primary"
                        sx={{
                            '&.Mui-disabled': {
                                color: `grey.400`,
                            }
                        }}
                    />
                </td>
            );
        }
        return <td key={key}>{item[key]}</td>;
    });
}

const UserSelectionFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));

const LinkToSelectionFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `1em`,
    paddingRight: `1em`,
    paddingTop: `1em`,
    paddingBottom: `1em`,
    borderRadius: `20px`,
    marginBottom: `1em`,
}));