import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import { FC } from 'react';
import { IntlProvider } from 'react-intl';

import dkMessages from './messages/dk.json';
import enMessages from './messages/en.json';
import { useLang } from './Metronici18n';

const allMessages = {
    en: enMessages,
    dk: dkMessages,
};
const I18nProvider: FC = ({ children }) => {
    const locale = useLang();
    const messages = allMessages[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
};

export { I18nProvider };
