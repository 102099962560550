import { Button, Checkbox, styled } from "@mui/material";
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { deletePendingProductById, getPendingProductById, updatePendingProductById } from "../../../setup/api/pending-product";
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { ComparTextField } from "../../../ui/components/TextField/ComparTextField";
import { TitleHeading } from "../../../ui/components/Titles/Title";
import { comparColors } from "../../../ui/helpers/colors";

export const PendingProductModal: FC = () => {
    const [pendingProduct, setPendingProduct] = useState<any>({});
    const [productId, setProductId] = useState<string | null>(null);

    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState(Boolean(id));

    const { request, abortController } = useFetch();
    const history = useHistory();

    const update = useFetchWithAlert(alertPayload(`Pending Product Updated`));
    const deleted = useFetchWithAlert(alertPayload(`Pending Product Deleted `));

    const refreshPendingProductInfo = () => {
        request(getPendingProductById, id)
            .then(pendingProductResult => {
                setPendingProduct(pendingProductResult);
                if (pendingProductResult.product) {
                    setProductId(pendingProductResult.product.id);
                }
            });
    };

    useEffect(() => {
        if (id) {
            setIsOpen(true);
            refreshPendingProductInfo();
        } else {
            setIsOpen(false);
            setPendingProduct({});
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [isOpen]);

    const handledChanged = async (pendingProductId: string, currentStatus: boolean) => {
        const payload = { handled: !currentStatus, product: productId ? productId : null };

        update.request(updatePendingProductById, pendingProductId, payload, abortController.signal)
            .then(res => {
                setPendingProduct((prevPendingProduct: any | undefined) => {
                    if (!prevPendingProduct) {
                        return;
                    }

                    return { ...prevPendingProduct, handled: !currentStatus };
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deletePendingProduct = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deletePendingProductById, id, abortController.signal)
                    .then(deleteProduct => {
                        if (deleteProduct.message === `Deleted successfully`) {
                            history.push(`/pending-products`);
                        }
                    });
            }
        }
    };

    return <ModalCompar
        open={isOpen}
        onClose={() => {
            history.goBack();
        }}
        title={`Pending Product`}>
        <Grid container>
            {pendingProduct &&
                <TagFormGrid xs={12}>
                    <TitleHeading children="Pending Product" style={{ fontSize: `1.25em` }} />
                    <ComparTextField
                        type="number"
                        label="Barcode"
                        style={{
                            backgroundColor: `#FFF`,
                            marginRight: `8px`,
                        }}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Barcode"
                        value={pendingProduct.barcode}
                        disabled
                    />
                    <Grid container direction="column" alignItems="center">
                        <img
                            src={pendingProduct.imageUrl}
                            alt="image"
                            style={{ width: `25vh`, height: `auto`, borderRadius: 10 }}
                        />
                        <Grid container justifyContent={`flex-start`} alignItems={`center`} >
                            <Checkbox
                                checked={Boolean(pendingProduct.handled)}
                                onChange={() => handledChanged(pendingProduct.id as string, pendingProduct.handled as boolean)}
                                color="primary"
                            />
                            <TitleHeading children="Is pending product handled?" style={{
                                fontSize: `1rem`
                            }} />
                        </Grid>
                    </Grid>
                    <ComparTextField
                        type="text"
                        label="Product ID"
                        style={{
                            backgroundColor: `#FFF`,
                            marginRight: `8px`,
                        }}
                        InputLabelProps={{ shrink: true }}
                        placeholder="Product ID"
                        value={productId}
                        onChange={(e: any) => setProductId(e.target.value)}
                    />
                    <TitleHeading children="If there is a product ID and the handled checkbox is changed to true we will send a notification to the user that we added the product (we do not send the notification twice)" style={{ fontSize: `0.75rem`, paddingBottom: `1em` }} />
                    <Button
                        component="label"
                        children="Delete pending product"
                        style={{ flex: `end`, marginTop: `1.2em` }}
                        onClick={deletePendingProduct}
                    />
                    <TitleHeading children="The difference between deleting and handling is that handling we will save the handled product and picture. When deleting we remove everything!" style={{
                        fontSize: `0.75rem`,
                        color: comparColors.primary.main,
                        marginTop: `1.2em`
                    }} />
                </TagFormGrid>
            }
        </Grid>
    </ModalCompar >;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));