import 'quill/dist/quill.snow.css';

import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';

const CustomLink = Quill.import(`formats/link`);

class MyCustomLink extends CustomLink {
    static sanitize(url: string) {
        return url;
    }
}

Quill.register(MyCustomLink);

type Props = {
    data: string;
    setContentProp?: Dispatch<SetStateAction<string>>;
};

export const TextEditor: FC<Props> = ({ data, setContentProp }) => {
    const [content, setContent] = useState<string>(data);

    useEffect(() => {
        setContent(data);
    }, [data]);

    const modules = {
        toolbar: [
            [`bold`, `italic`, `underline`, `strike`],
            [{ header: 1 }, { header: 2 }],
            [{ list: `ordered` }, { list: `bullet` }],
            [{ header: [1, 2, 3, false] }],
            [{ color: [] }],
            [`link`],
            [`clean`],
        ],
    };

    const formats = [
        `header`, `font`, `size`,
        `bold`, `italic`, `underline`, `strike`, `blockquote`,
        `list`, `bullet`, `indent`,
        `link`, `image`, `color`,
    ];

    const handleContentChange = (newContent: string) => {
        setContent(newContent);
        if (setContentProp) {
            setContentProp(newContent);
        }
    };

    return (
        <ReactQuill
            modules={modules}
            formats={formats}
            placeholder="Write article here..."
            value={content}
            onChange={handleContentChange}
        />
    );
};