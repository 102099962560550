import Grid from '@mui/material/Grid';
import { createContext, FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getIngredientList } from '../../../setup/api/ingredient';
import { SaveIngredientTagPayload } from '../../../setup/api/ingredientTag';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ProductIngredient } from '../../../setup/models';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { SelectedTagTable, TagTable } from '../../../ui/components/TagTable/TagTable';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { AddTagForm } from './AddHairFeatureForm';
import { Filter } from './Filter';

type FormContextType = {
    formValues: SaveIngredientTagPayload | undefined;
    setFormValues: React.Dispatch<React.SetStateAction<SaveIngredientTagPayload | undefined>>;
};
export const AddTagToIngredientFormContext = createContext<FormContextType>({
    formValues: undefined,
    setFormValues: () => {
        //
    }
});

export const AddHairFeatureToIngredientPage: FC = () => {
    const [ingredientListAndCount, setIngredientListAndCount] = useState<{ingredients: ProductIngredient[], count: number}>({
        ingredients: [],
        count: 0
    });
    const [ingredientsWithCurrentHairFeature, setIngredientsWithCurrentHairFeature] = useState<ProductIngredient[]>();
    const [shouldRefreshIngredients, setShouldRefreshIngredients] = useState(false);
    const [formValues, setFormValues] = useState<SaveIngredientTagPayload>();
    // const [addTagFunction, setAddTagFunctionState] = useState<{func: (values: any) => void, values: any} | undefined>();
    const history = useHistory();
    const [selectedIngredients, setSelectedIngredients] = useState<ProductIngredient[]>([]);
    const [hairFeatureQueries, setHairFeatureQueries] = useState<SaveIngredientTagPayload>();
    const ingredientQueryFromRouter = useLocation().search;

    const { request, isLoading, abortController } = useFetch();

    const resetSelectedIngredientAfterAddingHairFeature = () => {
        setSelectedIngredients([]);

        getIngredientListAndCount();
        setShouldRefreshIngredients(!shouldRefreshIngredients);
        history.push({
            search: ``
        });
    };

    const getIngredientListAndCount = () => {
        request(getIngredientList, ingredientQueryFromRouter).then((results: [ProductIngredient[], number]) => {
            if(results) {
                const [ingredients, count] = results;

                if(ingredientsWithCurrentHairFeature && ingredientsWithCurrentHairFeature?.length > 0) {
                    return filterIngredientsIfTheyAreAlreadyInHairFeature(results);
                }
                setIngredientListAndCount({ ingredients, count });
            }
        });
    };

    const filterIngredientsIfTheyAreAlreadyInHairFeature = (results: [ProductIngredient[], number]) => {
        // /remove ingredients that are in the current ingredients list
        const [ingredients, count] = results;

        const filteredIngredients = ingredients.filter(ingredient => !ingredientsWithCurrentHairFeature?.some(currentIngredient => currentIngredient.id === ingredient.id));

        return setIngredientListAndCount({ ingredients: filteredIngredients, count: count });
    };

    useEffect(() => {
        getIngredientListAndCount();
        return () => {
            abortController.abort();
        };
    }, [ingredientQueryFromRouter, hairFeatureQueries, ingredientsWithCurrentHairFeature]);

    return <AddTagToIngredientFormContext.Provider value={{
        formValues,
        setFormValues
    }}>
        <Grid container className='me-5' style={{ marginBottom: `2em` }}>
            <Grid xs={5} marginTop={10} marginRight={10}>
                <AddTagForm ingredients={selectedIngredients}
                    resetIngredients={resetSelectedIngredientAfterAddingHairFeature}
                    getQueries={(values: SaveIngredientTagPayload) => {
                        setHairFeatureQueries(values);
                    }}
                />
                <TagTable
                    shouldRefresh={shouldRefreshIngredients}
                    currentIngredients={ingredientsWithCurrentHairFeature}
                    setCurrentIngredients={setIngredientsWithCurrentHairFeature}
                    queries={hairFeatureQueries}
                />
            </Grid>
            <Grid xs={6} marginTop={3} >
                <Grid xs={12} display={`flex`} justifyContent={`center`}>
                    <TitleHeading children='Search Ingredients' style={{
                        fontSize: `1.5em`,
                    }}/>
                </Grid>
                {ingredientListAndCount && <SelectedTagTable
                    data={selectedIngredients}
                    objectsSelected={selectedIngredients}
                    setObjectSelected={setSelectedIngredients}
                    resetIngredients={resetSelectedIngredientAfterAddingHairFeature}
                />}

                <Grid xs={12} marginTop={4}>
                    <Filter
                        count={ingredientListAndCount.count}

                        entity='ingredient'
                        sx={{
                            display: `flex`,
                            justifyContent: `center`,
                        }}
                    />
                    {ingredientListAndCount && <Datagrid<ProductIngredient>
                        objectsSelected={selectedIngredients}
                        setObjectSelected={setSelectedIngredients}
                        renderHeaders={(headings: string[][]) => headings.map(([key, header]) => (
                            <th key={key} >
                                {header !== `Name` && header}
                            </th>
                        ))}
                        objectSelection
                        data={ingredientListAndCount.ingredients}
                        headings={headings}
                        loading={isLoading}
                    />}
                </Grid>
            </Grid>
        </Grid>
    </AddTagToIngredientFormContext.Provider>;
};

const headings = [[`name`, `Name`]] as Array<[string, string]>;