import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid, List } from '@mui/material';
import React from 'react';

import { TagIngredient } from '../../../setup/models';
import { ComparLightChip } from '../TagChips/ComparLightChip';
import { TitleHeading } from '../Titles/Title';
interface Props {
    tags: TagIngredient[]
    deleteFunction: (tagId: string) => void
}
export const TagDisplay: React.FC<Props> = ({ tags, deleteFunction }) => {
    const sortTagsByTagName = () => {
        const recommendedTags: TagIngredient[] = [];
        const preventedTags: TagIngredient[] = [];

        tags.forEach((tag: TagIngredient) => {
            if (isGoodTagName(tag.tagName)) {
                recommendedTags.push(tag);
            } else {
                preventedTags.push(tag);
            }
        });

        return { recommendedTags, preventedTags };
    };

    const { recommendedTags, preventedTags } = sortTagsByTagName();

    return <Grid container marginBottom={2}>
        <TitleHeading children="Recommend" style={{
            fontSize: `1rem`,
            marginBottom: `1em`,
        }} />
        <Grid container marginBottom={2}>
            {recommendedTags.map((tag: TagIngredient) => (
                <Grid item xs={4} marginRight={1} marginBottom={2}>
                    <ComparLightChip
                        style={{
                            fontSize: `13px`,
                        }}
                        key={tag.id}
                        onDelete={() => {
                            deleteFunction(tag.id);
                        }}
                        deleteIcon={
                            <HighlightOffIcon color="inherit" />
                        }
                        label={createTagLabel(tag)}
                        variant="filled"
                    />
                </Grid>
            ))}
        </Grid>
        <TitleHeading children="Prevent" style={{
            fontSize: `1rem`,
            marginBottom: `1em`,
        }} />
        <Grid container >
            {preventedTags.map((tag: TagIngredient) => (
                <Grid item xs={4} marginRight={1} marginBottom={2}>
                    <ComparLightChip
                        key={tag.id}
                        onDelete={() => {
                            deleteFunction(tag.id);
                        }}
                        style={{
                            fontSize: `13px`,
                        }}

                        deleteIcon={
                            <HighlightOffIcon color="inherit" />
                        }
                        label={createTagLabel(tag)}
                        variant="filled"
                    />
                </Grid>
            ))}
        </Grid>
    </Grid>;
};

const isGoodTagName = (tagName: `recommend` | `prevent`): boolean =>
    // Add your logic to determine if the tagName is "good" or not
    // For example, let's assume that a "good" tagName starts with the letter "G"
    tagName.charAt(0) === `r`
    ;

function createTagLabel(tag: TagIngredient) {
    if (tag.hairFeature) {
        return convertStringFormat(`${tag.hairFeature.feature} ${tag.hairFeature.value}`);
    }
}

function pickTextColorBasedOnBgColorAdvanced(bgColor: string) {
    const color = (bgColor.charAt(0) === `#`) ? bgColor.substring(1, 7) : bgColor;
    // HexToR
    const r = parseInt(color.substring(0, 2), 16);
    // HexToG
    const g = parseInt(color.substring(2, 4), 16);
    // HexToB
    const b = parseInt(color.substring(4, 6), 16);
    const uiColors = [r / 255, g / 255, b / 255];
    const c = uiColors.map(col => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);

    return (L > 0.179) ? `#212121` : `#FFF`;
}

const convertStringFormat = (inputString: string): string => {
    const words = inputString.split(` `);

    const convertedWords = words.map(word => {
        const splitWord = word.split(/(?=[A-Z])/);
        const capitalizedWords = splitWord.map(w => w.toLowerCase().replace(/^\w/, c => c.toUpperCase()));

        return capitalizedWords.join(` `);
    });

    return convertedWords.join(` `);
};