import config from 'react-global-configuration';

export const addProductToEWGScraper = (url: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/scraper/add-ingredient-from-ewg`, {
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    method: `POST`,
    body: JSON.stringify({ url }),
});