import { Button, Grid, TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email(`Wrong email format`)
        .min(3, `Minimum 3 symbols`)
        .max(100, `Maximum 100 symbols`)
        .required(`Email is required`),
});

export const RequestPasswordRecoveryForm: FC<FormikProps<ForgotPasswordProps>> = ({ getFieldProps, touched, errors, isValid }) => <Form className='form' noValidate>
    <span className='authentication-title'>Forgot Password ?</span>
    <div className='text-gray-400 fw-bold fs-5 mb-10'>Enter your email to reset your password.</div>
    <Grid item xs={12} style={{ width: `100%` }}>
        <TextField
            type='email'
            placeholder='Email'
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            {...getFieldProps(`email`)}
        />
    </Grid>
    <Grid item xs={12} style={{ width: `100%` }}>
        <Button
            type='submit'
            className='mb-5'
            disabled={!isValid}
            children="Send Code"
        />
        <Link to='/auth/login' style={{ color: `inherit`,
            textDecoration: `none`,
            width: `inherit` }} >
            <Button type='button' color='info' children="Back to Sign In"/>
        </Link>
    </Grid>
</Form>;