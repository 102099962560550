import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';

export const ComparLightChip = styled(Chip)({
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    minWidth: 0,
    backgroundColor: `#fff`,
    fontFamily: `ProximaNova-Light`
});
