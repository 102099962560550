import { styled } from "@mui/material";
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { getFeedbackById } from "../../../setup/api/feedback";
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { RootState } from '../../../setup/redux/RootReducer';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const FeedbackModal: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const [feedback, setFeedback] = useState<any>({});

    const { id } = useParams<{ id: string }>();
    const [isOpen, setIsOpen] = useState(Boolean(id));

    const { request } = useFetch();
    const history = useHistory();

    const refreshIngredientInfo = () => {
        request(getFeedbackById, id).then(feedbackResult => {
            setFeedback(feedbackResult);
        });
    };

    useEffect(() => {
        if (id) {
            setIsOpen(true);
            refreshIngredientInfo();
        } else {
            setIsOpen(false);
            setFeedback({});
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setIsOpen(true);
        }
    }, [isOpen]);

    return <ModalCompar
        open={isOpen}
        onClose={() => {
            history.goBack();
        }}
        title={`Feedback`}>
        <Grid container justifyContent={`space-between`} >
            <TagFormGrid xs={12}>
                <TitleHeading children="User feedback" style={{ fontSize: `1.25em` }} />
                <div className="scrollable-box" style={DisplayBox}>
                    {feedback?.feedback && (
                        <div className="scrollable-box">
                            <p style={{ whiteSpace: `pre-wrap` }}>{feedback.feedback}</p>
                        </div>
                    )}
                </div>
            </TagFormGrid>
        </Grid>
    </ModalCompar >;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));

const DisplayBox = {
    backgroundColor: `#FFF`,
    padding: `3em`,
    borderRadius: `20px`,
};