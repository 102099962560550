import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from '@mui/material/styles';

import { comparColors } from "../../helpers/colors";

export const ManiNavigationButton = styled(BottomNavigationAction)(() => ({
    "&.Mui-selected": {
        color: comparColors.primary.main,
        fontFamily: `PlayFairDisplay-Bold`,
        backgroundColor: `transparent`,
        fontSize: `2rem`,
    },
    "& .MuiBottomNavigationAction-root": {
        backgroundColor: `transparent`,

    },
    "& .MuiBottomNavigationAction-label": {
        color: comparColors.primary.main,
        fontSize: `2rem`,
        fontFamily: `PlayFairDisplay-Bold`,
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
        fontSize: `2rem`,
        color: comparColors.primary.main,

    },
}
));

export const StyledNavigationButton = styled(BottomNavigationAction)(() => ({
    "&.Mui-selected": {
        color: comparColors.primary.main,
        backgroundColor: `transparent`,
        textDecoration: `underline`,
    },
    "& .MuiBottomNavigationAction-root": {
        backgroundColor: `transparent`,

        "&:hover": {
            textDecoration: `underline`
        },
    },
    "& .MuiBottomNavigationAction-label": {
        fontFamily: `PlayFairDisplay-Regular`,
        color: comparColors.primary.main,
        paddingTop: `1.1rem`,
        fontSize: `1rem`, // Update the font size for unselected buttons
        "&:hover": {
            textDecoration: `underline`
        }
    }
}
));
