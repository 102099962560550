import config from 'react-global-configuration';

export const getFeedbackList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/feedback${queries}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getFeedbackById = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/feedback/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export const updateFeedbackById = (id: string, payload: any): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/feedback/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});