import { Grid, TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

export const LoginForm: FC<FormikProps<ForgotPasswordProps>> = ({
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    values,
}) => (
    <Form
        onSubmit={handleSubmit}
        onKeyDown={e => {
            if(!values.email || !values.password) {
                return;
            }
            if (e.key === `Enter` && values.email.length > 0 && values.password.length > 0) {
                handleSubmit(e);
            }
        }}>
        <span className='authentication-title' >Sign in to your account</span>
        <Grid item xs={12} style={{ width: `100%`, marginTop: `1em` }}>
            <TextField
                type='string'
                placeholder='Email'
                {...getFieldProps(`email`)}
                // error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
            />
        </Grid>
        <Grid item xs={12} style={{ width: `100%` }}>
            <TextField
                type='password'
                placeholder='Password'
                {...getFieldProps(`password`)}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                className='mb-10' />
        </Grid>
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                width: `100%`,
                padding: `1.25rem`,
                color: `rgb(25, 118, 210)`,
            }}
        >
            <Grid item xs={12} textAlign="center" textTransform="uppercase">
                            Forgot your password?
                             &nbsp;
                <Link to={`/forget-password`}>Reset password</Link>
            </Grid>
        </Grid>
    </Form>);