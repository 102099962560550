export class StringModifiers {
    static capitalizeFirstLetter(str: string): string {
        if (str.length === 0) {
            return str;
        }

        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

/**
* Takes the key of an object and return a string with first letter as capital and spacing between th words
* @param {string} keyName  key from objects as string
* @returns {string} transforms "objectKey" into "Object Key"
*/
export const parseObjectKeyToReadableString = (keyName: string): string => {
    const splitByCapitalLetter = keyName.split(/(?=[A-Z])/);
    const joinedArrayWithSpaces = splitByCapitalLetter.join(` `);
    const addUpperCaseToFirstLetter = joinedArrayWithSpaces.charAt(0).toUpperCase();
    const completedReadableString = addUpperCaseToFirstLetter + joinedArrayWithSpaces.substring(1);

    return completedReadableString;
};

export function parseReadableStringToObjectKeyName(input: string): string {
    // Remove spaces
    const stringWithoutSpaces = input.replace(/\s/g, ``);

    // Lowercase the first letter
    const lowercasedFirstLetter = stringWithoutSpaces.charAt(0).toLowerCase();

    // Return the modified string
    return lowercasedFirstLetter + stringWithoutSpaces.slice(1);
}

export function parseCapitalStringToReadable(input: string): string {
    let processedString = input.replace(/_/g, ` `);

    processedString = processedString.toLowerCase();

    const words = processedString.split(` `);

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    processedString = words.join(` `);

    return processedString;
}

export function parseToNoSpacesValue(input: string): string {
    let processedString = input.replace(/ /g, `_`);

    processedString = processedString.toUpperCase();

    return processedString;
}

export function parseProductCategoryBack(input: string): string {
    let string = input.toUpperCase();

    string = string.replace(/ /g, `_`);

    string = string.replace(`AND`, `&`);

    return string;
}
