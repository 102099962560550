import config from 'react-global-configuration';

export type IngredientTag = {
    id: string,
    name: string,
}

export const getIngredientTagList = (filter: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-tag${filter}`, {
    mode: `cors`,
    credentials: `include`,
});

export const removeTag = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-tag/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
});

type RemoveTagByIngredientPaylod = {
    tagName: string,
    feature: string,
    value: string,
}

export const removeTagByIngredientId = (id: string, queries: RemoveTagByIngredientPaylod): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-tag/from-ingredient/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(queries),
});

export const getIngredientTag = (id: string): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-tag/${id}`, {
    mode: `cors`,
    credentials: `include`,
});

export type SaveIngredientTagPayload = {
    ingredientId: string,
    tagName: string,
    feature: string,
    value: string
}

export const saveIngredientTag = (IngredientTag: Partial<SaveIngredientTagPayload>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/ingredient-tag`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(IngredientTag),
});

export const addTagToMultipleIngredients = (ingredientsWithTag: Partial<SaveIngredientTagPayload>[]): Promise<Response[]> => Promise.all(ingredientsWithTag.map(ingredientWithTag => saveIngredientTag(ingredientWithTag)));